import React from 'react'

import {
  Container,
  ArrorBackIcon,
  CardLink,
  CardImage,
  CardTitle,
  CardCta,
  CardLinkIconArrow,
} from './style'
import { NavWrapper, NavLinkArrow, IconArrow } from '../style'
import { navDisplay } from '../../../../utils/constants'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'
import img from '../../../../assets/images/collagene2.png'

const PopupAboutNavMobileContent = ({ setNavBarContent }) => (
  <Container>
    <ArrorBackIcon onClick={() => setNavBarContent(navDisplay.MAINNAV)}>
      <IoIosArrowBack />
    </ArrorBackIcon>

    <NavWrapper>
      <NavLinkArrow href='https://about.compliment.me/pages/notre-histoire'>
        Notre histoire
        <IconArrow>
          <IoIosArrowForward />
        </IconArrow>
      </NavLinkArrow>
      <NavLinkArrow href='https://about.compliment.me/pages/select'>
        Notre engagement
        <IconArrow>
          <IoIosArrowForward />
        </IconArrow>
      </NavLinkArrow>
      <NavLinkArrow href='https://about.compliment.me/blogs/health-guide'>
        Blog
        <IconArrow>
          <IoIosArrowForward />
        </IconArrow>
      </NavLinkArrow>
    </NavWrapper>

    <CardLink href='https://compliment.me/products/collagene'>
      <CardImage src={img} />
      <CardTitle>Nouveau! La poudre de collagène</CardTitle>
      <CardCta>
        Découvrir
        <CardLinkIconArrow>
          <IoIosArrowForward />
        </CardLinkIconArrow>
      </CardCta>
    </CardLink>
  </Container>
)

export default PopupAboutNavMobileContent
