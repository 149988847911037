import styled from 'styled-components/macro'
import { Color } from '../../../../utils/constants'

export const Container = styled.div`
  padding: 80px 30px 0px 30px;
`
export const ArrorBackIcon = styled.span`
  position: absolute;
  top: 20px;
  left: 15px;
  font-size: 40px;
  opacity: 0.5;
  cursor: pointer;
`

export const CardLink = styled.a`
  padding: 30px 0;
  margin-top: 20px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  /* background-color: ${Color.MONTE_CARLO}; */
  background: linear-gradient(
    180deg,
    rgba(101, 202, 172, 1) 0%,
    rgba(126, 211, 186, 1) 50%,
    rgba(151, 220, 200, 1) 100%
  );
  color: white;
  border-radius: 15px;
  text-align: center;
  &:hover span {
    margin-left: 10px;
  }
`

export const CardTitle = styled.h4`
  font-weight: 300;
  text-transform: uppercase;
  font-size: 16px;
  opacity: 0.7;
`

export const CardCta = styled.button`
  margin: 0 auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 20px;
  border: none;
  background-color: white;
  padding: 5px 40px;
  border-radius: 30px;
  font-size: 16px;
  color: ${Color.ORANGE};
`
export const CardLinkIconArrow = styled.span`
  margin-top: 5px;
  margin-left: 5px;
  transition: all 0.5s;
`
