import React, { useState, useEffect } from 'react'
import { Dialog, Spinner, Button } from '../../ui'
import {
  Title,
  Hr,
  ProductList,
  Close,
  SpinnerWrapper,
  Input,
  ButtonWrapper,
} from './style'
import { IoCloseOutline } from 'react-icons/io5'
import ProductCartReco from '../../product/product-cart-reco'
import { useProducts, useAddProductToSubscription } from '../../../hooks'
import moment from 'moment'
import { useLazyQuery } from '@apollo/client'
import { PRODUCTS } from '../../../service/queries'

const ProgramUpdateDialog = ({
  setIsDialogOpen,
  isDialogOpen,
  subscription,
  subscriptionTitle,
}) => {
  const [
    fetchProducts,
    { data: productsData, loading: productsLoading, error: productsError },
  ] = useLazyQuery(PRODUCTS)

  const [products, setProducts] = useState([])
  const [search, setSearch] = useState('')

  const [firstCursor, setFirstCursor] = useState('')

  const [size, setSize] = useState(32)

  const lastProductCursor =
    productsData?.products?.edges[productsData?.products?.edges?.length - 1]
      ?.cursor

  function processProductsData() {
    const edges = products

    // Check if `edges` is an array and has a length greater than 0
    if (Array.isArray(edges) && edges.length > 0) {
      const lastProductCursor = edges[edges.length - 40]?.cursor

      return lastProductCursor
    }
  }

  function getQuery() {
    if (search) return `title:'${search}' AND tag:'marketplace'`
    else return `tag:'marketplace'`
  }

  const lastProductCursor40 = processProductsData()

  const [
    nav,
    //  setNav
  ] = useState(1)

  const { data, loading } = useProducts()
  const { add, isLoading, isSuccess } = useAddProductToSubscription()

  useEffect(() => {
    fetchProducts({
      variables: {
        perPage: size,
        query: getQuery(),
      },
    })

    // Cleanup function
    return () => {
      // Perform any cleanup if needed
    }
  }, [search, size])

  useEffect(() => {
    !!productsData?.products?.edges?.length &&
      setProducts(productsData?.products?.edges)
  }, [productsData])

  useEffect(() => {
    isLoading === false && setIsDialogOpen(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess])

  // useEffect(() => {
  //   isLoading === false && isSuccess === true && (toast.success('useAddProductToSubscription'))
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isSuccess])

  const selectInterval = () => {
    if (subscriptionTitle === '1month_subscription') {
      return '30'
    } else if (subscriptionTitle === '3month_subscription') {
      return '90'
    } else {
      return '30'
    }
  }

  return (
    <>
      {isLoading && (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      )}
      <Dialog
        isOpen={isDialogOpen}
        type='large'
        onClose={() => setIsDialogOpen(false)}
      >
        <Close onClick={() => setIsDialogOpen(false)}>
          <IoCloseOutline />
        </Close>

        <Title>Mettre à jour mon programme </Title>
        <Input
          placeholder='Rechercher un produit'
          value={search}
          onChange={e => setSearch(e.target.value)}
        />
        <Hr></Hr>
        <ProductList
          style={{
            cursor: isLoading ? 'wait' : '',
          }}
        >
          {nav === 1 &&
            (loading ? (
              <Spinner />
            ) : (
              <>
                {products?.map(p => {
                  const productpicture =
                    p?.node?.metafields?.edges?.filter(
                      i => i?.node?.key === 'productpicture'
                    )[0]?.node?.value &&
                    JSON.parse(
                      p?.node?.metafields?.edges?.filter(
                        i => i?.node?.key === 'productpicture'
                      )[0]?.node?.value
                    )[0]?.original_src

                  const collectionhighlight =
                    p?.node?.metafields?.edges?.filter(
                      i => i?.node?.key === 'collectionhighlight'
                    )[0]?.node?.value &&
                    JSON.parse(
                      p?.node?.metafields?.edges?.filter(
                        i => i?.node?.key === 'collectionhighlight'
                      )[0]?.node?.value
                    )
                  const collectionallegation =
                    p?.node?.metafields?.edges?.filter(
                      i => i?.node?.key === 'collectionallegation'
                    )[0]?.node?.value &&
                    JSON.parse(
                      p?.node?.metafields?.edges?.filter(
                        i => i?.node?.key === 'collectionallegation'
                      )[0]?.node?.value
                    )
                  const title = p?.node?.title

                  const sTitle = subscriptionTitle
                    ? subscriptionTitle
                    : '1month_subscription'
                  const variant = p?.node?.variants?.edges?.filter(
                    v => v?.node?.title === sTitle
                  )[0]?.node

                  const mainImage = p?.node?.images?.edges[0]?.node?.src

                  const sellingPlans =
                    p?.node?.sellingPlanGroups?.edges?.[0]?.node?.sellingPlans
                      ?.edges

                  return (
                    <ProductCartReco
                      mainImage={mainImage}
                      isLoading={isLoading}
                      sellingPlans={sellingPlans}
                      img={
                        productpicture ??
                        'https://us.123rf.com/450wm/mathier/mathier1905/mathier190500002/134557216-no-thumbnail-image-placeholder-for-forums-blogs-and-websites.jpg?ver=6'
                      }
                      title={title ?? ''}
                      collectionhighlight={collectionhighlight ?? []}
                      collectionallegation={collectionallegation ?? []}
                      variant={variant}
                      variants={p?.node?.variants?.edges}
                      onAddClick={(id, interval) => {
                        // const variantId0 = atob(id)
                        // const splittedId = variantId0.split('/')
                        // const variantId = splittedId[splittedId.length - 1]

                        const variantId = btoa(id)

                        const s = {
                          ...subscription,
                          products: subscription.products.map(p => {
                            return {
                              ...p,
                              nextChargedScheduledAt:
                                subscription?.products[0]
                                  ?.next_charge_scheduled_at ?? moment(),
                            }
                          }),
                          nextChargedScheduledAt:
                            subscription?.products[0]
                              ?.next_charge_scheduled_at ?? moment(),
                        }

                        add(
                          {
                            subscription: s,
                            quantity: 1,
                            variantId: id,
                            id: p?.node?.id,
                          },
                          interval ?? selectInterval()
                        )
                      }}
                    />
                  )
                })}
              </>
            ))}
        </ProductList>
        <ButtonWrapper>
          {/* <Button
            appearance='primary'
            style={{ whiteSpace: 'nowrap', marginRight: '20px' }}
            onClick={() => {
              fetchProducts({
                variables: {
                  perPage: 32,
                  query: getQuery(),
                  cursor: firstCursor,
                },
              })
              setFirstCursor(products[0]?.cursor)
            }}
          >
            Précédent
          </Button> */}

          <Button
            appearance='primary'
            style={{ whiteSpace: 'nowrap' }}
            onClick={() => {
              setSize(size + 32)
              // fetchProducts({
              //   variables: {
              //     perPage: 32,
              //     query: getQuery(),
              //     cursor: lastProductCursor,
              //   },
              // })
              // setFirstCursor(products[0]?.cursor)
            }}
          >
            Voir plus
          </Button>
        </ButtonWrapper>
      </Dialog>
    </>
  )
}

export default ProgramUpdateDialog
