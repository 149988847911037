import React, { useState } from 'react'
import { Container, Header, Nav, Title, NavLink } from '../../style'
import MyPersonalInformation from '../my-personal-information'
import PaymentMethod from '../payment-method'
import BillingInformation from '../billing-information'
import ReferralInformation from '../referral-information'
import { useAuth } from '../../../hooks'

const ProfileSection = () => {
  const [nav, setNav] = useState(1)
  const { authUser } = useAuth()

  return (
    <Container>
      <Header>
        <Title>Les informations de {authUser?.firstName}</Title>
        <Nav>
          <NavLink
            style={{ whiteSpace: 'nowrap' }}
            onClick={() => setNav(1)}
            active={nav === 1}
          >
            Mes informations personnelles
            {nav === 1 && <span></span>}
          </NavLink>
          <NavLink
            style={{ whiteSpace: 'nowrap' }}
            onClick={() => setNav(2)}
            active={nav === 2}
          >
            Mes moyens de paiements
            {nav === 2 && <span></span>}
          </NavLink>
          {/* <NavLink
            style={{ whiteSpace: 'nowrap' }}
            onClick={() => setNav(3)}
            active={nav === 3}
          >
            Mes informations de facturation
            {nav === 3 && <span></span>}
          </NavLink> */}
        </Nav>
      </Header>

      {nav === 1 && <MyPersonalInformation />}
      {nav === 2 && <PaymentMethod />}
      {nav === 3 && <BillingInformation />}
      {nav === 4 && <ReferralInformation />}
    </Container>
  )
}

export default ProfileSection
