import React from 'react'
import { AiOutlineLoading } from 'react-icons/ai'
import { LoadingIcon } from './style'

const IconSpinner = () => (
  <LoadingIcon>
    <span>
      <AiOutlineLoading />
    </span>
  </LoadingIcon>
)

export default IconSpinner
