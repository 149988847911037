import styled from 'styled-components/macro'
import { Color } from '../../../utils/constants'

export const Close = styled.div`
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 30px;
`

export const Title = styled.h3`
  font-size: 25px;
  color: ${Color.DEEP_SEA};
  text-align: center;
  margin-bottom: 30px;
  font-weight: 300;
`
export const OrderList = styled.div`
  margin: 0 40px 30px 40px;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`
