import { useDispatch as useDispatchRedux } from 'react-redux'
import { toast } from 'react-toastify'

import { getErrorMessage } from '../utils/helpers'

export * from './authHooks'
export * from './diagnosticHooks'
export * from './ordersHooks'
export * from './paymentMethodsHooks'
export * from './productsHooks'
export * from './routeCheck'
export * from './subscriptionsHooks'
export * from './callHooks'

export const useDispatch = () => {
  const dispatch = useDispatchRedux()

  return callback => {
    try {
      dispatch(callback)
    } catch (e) {
      // TODO: send error to Sentry
      console.warn(e.message)
      toast.error(getErrorMessage(e.message))
    }
  }
}
