import styled from 'styled-components/macro'

import { BaseText } from '../../../style'
import { Color } from '../../../utils/constants'

export const Card = styled.div`
  border-bottom: 1px solid ${Color.MYSTIC};
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  justify-content: flex-start;
  align-items: flex-start;
`

export const Img = styled.img`
  width: 40px;
  height: 30px;
  object-fit: contain;
`

export const CardInfo = styled.div`
  flex: 1;
  padding: 0 20px;
`

export const CardNumber = styled(BaseText)``

export const CardExpiry = styled(BaseText)``

export const Action = styled(BaseText)`
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 20px auto;
  > button {
    margin-top: 20px;
  }
`

export const DialogHeader = styled.div`
  display: flex;
  justify-content: center;
`
export const DialogTitle = styled.h2`
  font-size: 25px;
  color: ${Color.DEEP_SEA};
  text-align: center;
  font-weight: 300;
`
export const FlexInput = styled.div`
  display: flex;
  justify-content: space-between;
  > div {
    width: 48%;
  }
`

export const SuccessMessage = styled.p`
  margin-top: 15px;
  text-align: center;
  color: green;
`
export const ErrorMessage = styled.p`
  margin-top: 15px;
  text-align: center;
  color: red;
`
