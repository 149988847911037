import styled from 'styled-components'

import { Color } from '../../../utils/constants'

export const Wrapper = styled.div`
  height: auto;
  padding: 10px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
`
export const Name = styled.h4`
  line-height: 20px;
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 15px;
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 100%;
  margin-bottom: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const ImageWrapper = styled.div`
  width: 100%;
  min-height: 200px;
  max-height: 200px;
  border-radius: 10px;

  position: relative;
  margin-bottom: 15px;
`

export const Tags = styled.div`
  overflow-x: scroll;
  margin: 10px 0 10px 0;
  span {
    margin-right: 5px;
    font-size: 10px;
    padding: 4px 5px;
    border-radius: 10px;
    background-color: ${Color.COLOR_SECONDARY};
    color: ${Color.DEEP_SEA};
  }
`

export const DescriptionWrapper = styled.ul`
  list-style: none;
  margin-bottom: 30px;
  padding-left: 20px;
  & > *:not(:last-child) {
    margin-bottom: 8px;
  }
`
export const Description = styled.li`
  font-weight: 300;
  opacity: 0.5;
  font-size: 15px;
  display: flex;
  align-items: center;
  &::before {
    content: '•';
    color: ${Color.DEEP_SEA};
    display: inline-block;
    width: 15px;
    margin-left: -1em;
  }
`
export const DescriptionIcon = styled.span`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 5px;
  font-size: 15px;
`

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
`
export const Price = styled.div`
  font-weight: 300;
  font-size: 15px;
`

export const QuantityChoiceWrapper = styled.div`
  flex-wrap: wrap;
  margin-bottom: 20px;
`

export const QuantityChoice = styled.span`
  white-space: nowrap;
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 3px;
  padding: 6px 10px;
  background-color: ${props => (props.selected ? '#39835f' : '#fff')};
  color: ${props => (props.selected ? '#fff' : '#8f95b2')};
  border: 1px solid #e7e9f4;
  border-radius: 100px;
  font-size: 10px;
`

export const Select = styled.select`
  background: white;
  border-radius: 15px;
  padding: 5px;
  border: 1px solid #eee;
  font-size: 10px;
  margin: 0 8px;
  outline: none;
  margin-bottom: 20px;
  margin-left: -2px;
`
