import React from 'react'
import {
  Header,
  Table,
  Title,
  Hr,
  EmptyData,
  EmptyDataTitle,
  Wrapper,
  Goals,
  // Goal,
} from './style'
import moment from 'moment'
import { Button, Spinner } from '../../ui'
import { Color } from '../../../utils/constants'
import { useDiagnostics } from '../../../hooks'
import { ReactSVG } from 'react-svg'
import { focusGoals } from '../../../utils/constants'

const DiagnosisHistory = ({ setIsDialogOpen }) => {
  const { data, loading } = useDiagnostics()

  // function getMostFrequent(arr) {
  //   const hashmap = arr.reduce((acc, val) => {
  //     acc[val] = (acc[val] || 0) + 1
  //     return acc
  //   }, {})
  //   return Object.keys(hashmap).reduce((a, b) =>
  //     hashmap[a] > hashmap[b] ? a : b
  //   )
  // }

  return (
    <Wrapper>
      <Header>
        <Title>L'historique des diagnostics</Title>
        <Button appearance='primary'>
          <a
            href={`https://about.compliment.me/pages/questionnaire-cure-personnalisee-vitamines-complements-alimentaires/?name=&email=`}
          >
            Refaire mon diagnostic
          </a>
        </Button>
      </Header>

      <Hr></Hr>
      {false ? (
        <EmptyData>
          <EmptyDataTitle>
            Vous n'avez pas d'historique de diagnostics
          </EmptyDataTitle>
          <Button onClick={() => setIsDialogOpen(true)} appearance='primary'>
            Faites votre diagnostic
          </Button>
        </EmptyData>
      ) : (
        <Table>
          <tr>
            <th style={{ width: '15%' }}>
              <span>Date</span>
            </th>
            <th style={{ width: '20%' }}>
              <span>Type de diagnostic</span>
            </th>
            <th style={{ width: '25%' }}>
              <span>Mes objectifs</span>
            </th>
            {/* <th style={{ width: '15%' }}>
              <span>Mon objectif principal</span>
            </th> */}
            <th style={{ width: '25%' }}></th>
          </tr>

          {loading ? (
            <Spinner />
          ) : (
            <>
              {data?.diagnostic.map(d => {
                let params = new URLSearchParams(d?.diagnostic_url)
                const focus = params.get('focus')
                var focusaArray = focus.split(',')

                // const handles = d?.diagnostic_take?.diagnostic_items?.map(
                //   i => i.shopify_product.shopify_product.handle
                // )
                const icons = []
                // goalIcons?.map(i =>
                //   handles.forEach(h => {
                //     if (i.handles.includes(h)) icons.push(i.icon)
                //   })
                // )
                // const uniqIcons = [...new Set(icons)]

                focusGoals?.map(i =>
                  focusaArray.forEach(h => {
                    if (i.name.includes(h)) icons.push(i.icon)
                  })
                )
                const uniqIcons = [...new Set(icons)]

                return (
                  <tr>
                    <td style={{ width: '15%' }}>
                      {moment(d.created_at).format('DD/MM/YYYY')}
                    </td>
                    <td style={{ width: '20%' }}>Diagnostic en ligne</td>
                    <Goals style={{ width: '25%' }}>
                      {uniqIcons.map(url => {
                        return <ReactSVG src={url} />
                      })}
                    </Goals>
                    {/* <Goal style={{ width: '15%' }}>
                      <ReactSVG src={getMostFrequent(icons)} />
                    </Goal> */}
                    <td style={{ width: '25%', textAlign: 'center' }}>
                      <Button
                        style={{ padding: '0' }}
                        color={Color.DEEP_SEA}
                        iconRight='arrow-right'
                        appearance='underline'
                      >
                        <a href={d.diagnostic_url}>Revoir les résultats</a>
                      </Button>
                    </td>
                  </tr>
                )
              })}
            </>
          )}
        </Table>
      )}
    </Wrapper>
  )
}

export default DiagnosisHistory
