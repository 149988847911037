import styled from 'styled-components/macro'

import { Color, MediaQuery } from '../../../utils/constants'

export const Header = styled.header`
  display: flex;
  width: 100%;
  margin: 0 auto;
  height: 75px;
  min-height: 75px;
  border-bottom: 1px solid ${Color.MONTE_CARLO};
  position: sticky;
  top: 0;
  padding: 0 10px;
  background-color: white;
  z-index: 10;
  ${MediaQuery.S_AND_DOWN} {
    padding: 0 0px;
  }
  ${MediaQuery.L_AND_UP} {
    display: none;
  }
`

export const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  width: 100%;
  height: 100%;
`

export const UlLeft = styled.ul`
  display: flex;
  justify-content: flex-start;
  height: 100%;
  width: 40%;
  list-style: none;
  margin: 0;
  padding: 0 20px;
  box-sizing: border-box;
  /* background-color: red; */
`
export const UlRight = styled.ul`
  display: flex;
  justify-content: flex-end;
  height: 100%;
  width: 40%;
  list-style: none;
  margin: 0;
  padding: 0 20px;
  box-sizing: border-box;
  /* background-color: blue; */
`

export const Li = styled.li`
  display: flex;
  align-items: center;
  height: 100%;
  box-sizing: border-box;
  padding: 10px 0;

  > a {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    height: 100%;
    color: black;
    text-decoration: none;
    padding: 0 20px;
    border-radius: 5px;
    font-size: 14px;
    line-height: 18px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
`

export const Button = styled.button`
  background-color: #fd5b25;
  border: none;
  padding: 12px 35px;
  border-radius: 30px;
  > a {
    color: white;
    text-decoration: none;
  }
`
export const HamburgerIcon = styled.div`
  z-index: 101;
`
export const HamburgerIconLabel = styled.label`
  z-index: 102;
  display: flex;
  flex-direction: column;
  width: 32px;
  cursor: pointer;
  span {
    background-color: #000;
    opacity: 0.5;
    border-radius: 10px;
    height: 2.7px;
    margin: 2px 0;
    transition: 0.2s cubic-bezier(0.68, -0.6, 0.32, 1.6);
  }
`
export const HamburgerIconlInput = styled.input`
  &[type='checkbox'] {
    display: none;
  }
  &[type='checkbox']:checked ~ span:nth-of-type(1) {
    transform-origin: bottom;
    transform: rotatez(45deg) translate(2px, 0px);
  }
  &[type='checkbox']:checked ~ span:nth-of-type(2) {
    transform-origin: top;
    transform: rotatez(-45deg);
  }
  &[type='checkbox']:checked ~ span:nth-of-type(3) {
    transform-origin: bottom;
    width: 50%;
    transform: translate(13px, -1px) rotatez(45deg);
  }
`
export const HamburgerIconSpan = styled.span`
  &:nth-of-type(1) {
    width: 50%;
  }
  &:nth-of-type(2) {
    width: 100%;
  }
  &:nth-of-type(3) {
    width: 75%;
  }
`
export const Dialog = styled.div`
  display: ${props => (props.isOpen ? 'block' : 'none')};
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;
  z-index: 100;
`

export const CartIconWrapper = styled.div`
  font-size: 20px;
  position: relative;
`
export const CartIconNotif = styled.span`
  position: absolute;
  top: -9px;
  right: -12px;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background-color: ${Color.ORANGE};
  color: white;
  font-size: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
`

// reused

export const NavWrapper = styled.nav`
  border-bottom: ${props => (props.borderBottom ? '2px solid #ddd' : '')};
  padding: 25px 0;
  a:not(:last-child) {
    margin-bottom: 20px;
  }
`
export const NavLinkArrow = styled.a`
  text-decoration: ${props => (props.underline ? 'underline' : '')};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  opacity: ${props => (props.color ? 1 : 0.7)};
  font-size: 17px;
  letter-spacing: 1.3px;
  color: ${props => (props.color === 'green' ? Color.MONTE_CARLO : '')};
`
export const IconArrow = styled.span`
  margin-left: 5px;
  margin-top: 5px;
  font-size: 25px;
  visibility: ${props => (props.visibility === 'hidden' ? 'hidden' : '')};
`
