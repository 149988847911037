import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLazyQuery, useMutation } from '@apollo/client'
import { ActionType } from '../utils/constants'
import { toast } from 'react-toastify'

import {
  getAuthUser,
  setAuthUser,
  setAuthTokens,
  getCustomerStatus,
  fetchCustomerLoading,
  fetchCustomerFailed,
  fetchCustomerSuccess,
  updateCustomerStatus,
  updateCustomerLoading,
  updateCustomerSuccess,
  updateCustomerFailed,
  getShopifyToken,
  getCustomerRecoverStatus,
  customerRecoverLoading,
  customerRecoverSuccess,
  customerRecoverFailed,
} from '../store/auth-user'
import {
  GET_CUSTOMER,
  UPDATE_CUSTOMER,
  CUSTOMER_ROCOVER,
} from '../service/queries'

export const useAuth = () => {
  const authUser = useSelector(getAuthUser)

  return { authUser }
}

export const useFetchAuthUser = () => {
  const dispatch = useDispatch()

  return {
    setUser: data => dispatch(setAuthUser(data)),
    setTokens: tokens => dispatch(setAuthTokens(tokens)),
  }
}

export const useCustomer = () => {
  const dispatch = useDispatch()
  const shopifyToken = useSelector(getShopifyToken)
  const customerStatus = useSelector(getCustomerStatus)
  //   const authToken = useSelector(getUserToken)
  //   const context = authToken
  //     ? { headers: { Authorization: `Bearer ${authToken}` } }
  //     : undefined
  const [
    fetchCustomer,
    { data: customerData, loading: customerLoading, error: customerError },
  ] = useLazyQuery(
    GET_CUSTOMER
    //     {
    //      headers: { Authorization: `Bearer URwR7SqiHkwscqDaBlYugZS2uRSUQZNXlI52KilXsrhdRuRa1rpXYIfPOQltNgqJ` },
    //    }
  )

  useEffect(() => {
    customerStatus !== ActionType.LOADING &&
      fetchCustomer({ variables: { customerAccessToken: shopifyToken } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * Sync fetchAuthPrescriber with store
   */
  useEffect(() => {
    customerLoading &&
      customerStatus !== ActionType.LOADING &&
      dispatch(fetchCustomerLoading())
  }, [customerLoading, dispatch, customerStatus])
  useEffect(() => {
    customerData &&
      customerStatus !== ActionType.SUCCESS &&
      dispatch(fetchCustomerSuccess(customerData))
  }, [customerData, dispatch, customerStatus])
  useEffect(() => {
    if (customerError && customerStatus !== ActionType.FAILED) {
      dispatch(fetchCustomerFailed(customerError))
    }
  }, [customerError, dispatch, customerStatus])
  return {
    fetchCustomer: fetchCustomer,
    data: customerData,
    failed: customerStatus === ActionType.FAILED,
    loading: customerStatus === ActionType.LOADING,
    success: customerStatus === ActionType.SUCCESS,
  }
}

export const useEditCutomer = () => {
  const dispatch = useDispatch()
  // const authToken = useSelector(getUserToken)
  const customerStatus = useSelector(getCustomerRecoverStatus)
  const [
    updateCustomer,
    { data: customerData, loading: customerLoading, error: customerError },
  ] = useMutation(UPDATE_CUSTOMER, {
    // context: { headers: { Authorization: `Bearer ${authToken}` } },
  })

  /**
   * Sync fetchAuthPrescriber with store
   */
  useEffect(() => {
    customerLoading && dispatch(updateCustomerLoading())
  }, [customerLoading, dispatch])
  useEffect(() => {
    customerData && dispatch(updateCustomerSuccess(customerData))
    customerData && toast.success('Le profil a bien été ajouté mis à jour.')
  }, [customerData, dispatch])
  useEffect(() => {
    customerError && dispatch(updateCustomerFailed(customerError))
    customerError &&
      toast.error(
        "Le profil n'a pas été ajouté mis à jour. Réessayer plus tard"
      )
  }, [customerError, dispatch])

  return {
    data: customerData,
    updateCustomer,
    isFailed: customerStatus === ActionType.FAILED,
    isLoading: customerStatus === ActionType.LOADING,
    isSuccess: customerStatus === ActionType.SUCCESS,
  }
}

export const useCustomerRecover = () => {
  const dispatch = useDispatch()
  // const authToken = useSelector(getUserToken)
  const customerStatus = useSelector(updateCustomerStatus)
  const [
    recover,
    { data: customerData, loading: customerLoading, error: customerError },
  ] = useMutation(CUSTOMER_ROCOVER, {
    // context: { headers: { Authorization: `Bearer ${authToken}` } },
  })

  /**
   * Sync fetchAuthPrescriber with store
   */
  useEffect(() => {
    customerLoading && dispatch(customerRecoverLoading())
  }, [customerLoading, dispatch])
  useEffect(() => {
    customerData && dispatch(customerRecoverSuccess(customerData))
    customerData &&
      toast.success("l'email de réinitialisation a bien été envoyé")
  }, [customerData, dispatch])
  useEffect(() => {
    customerError && dispatch(customerRecoverFailed(customerError))
    customerError && toast.error('Err, Réessayer plus tard')
  }, [customerError, dispatch])

  return {
    data: customerData,
    recover,
    isFailed: customerStatus === ActionType.FAILED,
    isLoading: customerStatus === ActionType.LOADING,
    isSuccess: customerStatus === ActionType.SUCCESS,
  }
}
