class AuthUser {
  data = {}

  constructor(newData) {
    this.data = newData
  }

  get id() {
    return this.data.id
  }

  get email() {
    return this.data.email
  }

  get phone() {
    return this.data.phone_number || ''
  }

  get firstName() {
    return this.data.firstName || ''
  }

  get lastName() {
    return this.data.lastName || ''
  }

  get fullName() {
    let value = ''
    if (this.firstName) {
      value += this.firstName
    }
    if (this.lastName) {
      let space = ''
      if (this.firstName) {
        space = ' '
      }
      value += space + this.lastName
    }

    return value
  }

  get discountCode() {
    return this.data.discount?.code || null
  }

  get discountPercentage() {
    return this.data.discount?.percentage || 0
  }
}

export default AuthUser
