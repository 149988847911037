import styled from 'styled-components/macro'
// import { Color } from '../../../../utils/constants'

export const Container = styled.div`
  padding: 20px 30px;
  height: auto;
  display: flex;
  flex-direction: column;
  & > :not(:last-child) {
    margin-bottom: 10px;
  }
`

export const NavLink = styled.a`
  cursor: pointer;
  white-space: nowrap;
  color: black;
  text-decoration: none;
  font-size: 14px;
  font-weight: ${({ $isActive }) => ($isActive ? 'bold' : 'normal')};
  opacity: 0.5;
  text-decoration: ${props => (props.underline ? 'underline' : 'none')};
`
