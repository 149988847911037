import React, { useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Dialog, Field, Button } from '../../ui'
import { Title, Form, FlexInput, ButtonWrapper } from './style'
import { useEditCutomer } from '../../../hooks'

const validationSchema = Yup.object().shape({
  // firstName: Yup.string().required('Champs obligatoire'),
  // lastName: Yup.string().required('Champs obligatoire'),
})

const EditPersonalInfoModal = ({
  isOpen,
  onClose,
  firstName,
  lastName,
  email,
}) => {
  const { updateCustomer, isLoading, isSuccess } = useEditCutomer()

  useEffect(() => {
    isLoading === false && onClose()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess])

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    // setFieldValue,
  } = useFormik({
    initialValues: {
      firstName: firstName || '',
      lastName: lastName || '',
      email: email || '',
      password: '',
    },
    validationSchema,
    onSubmit: values => {
      updateCustomer({
        variables: {
          customerAccessToken: '60b00e8a2dcade586780a540cb0e877e',
          customer: {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            // password: values.password
          },
          metafields: [
            {
              namespace: 'global',
              key: 'key1',
              value: 'value1',
              valueType: 'STRING',
            },
          ],
        },
      })
      //   change({
      //     firstName: values.firstName,
      //     lastName: values.lastName,
      //   })
    },
  })

  return (
    <Dialog isOpen={isOpen} onClose={onClose}>
      <Title>Modifiez mes informations personnelles</Title>
      <Form onSubmit={handleSubmit}>
        <Field
          label='E-mail'
          name='email'
          id='email'
          type='text'
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email}
          error={errors.email}
          touched={touched.email}
        />
        {/* <Field
            label='Mot de passe'
            name='password'
            id='password'
            type='text'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            error={errors.password}
            touched={touched.password}
          /> */}
        <FlexInput>
          <Field
            label='Prénom'
            name='firstName'
            id='firstName'
            type='text'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.firstName}
            error={errors.firstName}
            touched={touched.firstName}
          />
          <Field
            label='Nom de famille'
            name='lastName'
            id='lastName'
            type='text'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.lastName}
            error={errors.lastName}
            touched={touched.lastName}
          />
        </FlexInput>
        <ButtonWrapper>
          <Button isLoading={isLoading} appearance='primary' type='submit'>
            Valider
          </Button>
        </ButtonWrapper>
      </Form>
    </Dialog>
  )
}

export default EditPersonalInfoModal
