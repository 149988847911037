import styled from 'styled-components/macro'
import { Color } from '../../../../utils/constants'

export const Container = styled.div`
  padding: 80px 30px 0px 30px;
`
export const ArrorBackIcon = styled.span`
  position: absolute;
  top: 20px;
  left: 15px;
  font-size: 40px;
  opacity: 0.5;
  cursor: pointer;
`

export const CardLink = styled.a`
  margin-top: 20px;
  cursor: pointer;
  display: block;
  width: 100%;
  height: 210px;
  background-color: #f5f5f5;
  border-radius: 15px;
  text-align: center;
  &:hover span {
    margin-left: 10px;
  }
`
export const CardImage = styled.img`
  width: 100%;
  height: 60%;
  object-fit: cover;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
`

export const CardTitle = styled.h4`
  margin-top: 16px;
  font-weight: 300;
  text-transform: uppercase;
  font-size: 14px;
  opacity: 0.7;
`

export const CardCta = styled.button`
  margin: 0 auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 6px;
  border: none;
  background-color: transparent;
  font-size: 16px;
  color: ${Color.ORANGE};
`
export const CardLinkIconArrow = styled.span`
  margin-top: 5px;
  margin-left: 5px;
  transition: all 0.5s;
`
