import styled from 'styled-components'
import { Color } from '../../../utils/constants'

export const Title = styled.h3`
  font-size: 25px;
  color: ${Color.DEEP_SEA};
  text-align: center;
  font-weight: 300;
  margin-bottom: 30px;
`

export const Form = styled.form``

export const FlexInput = styled.div`
  display: flex;
  justify-content: space-between;

  > div {
    width: 48%;
  }
`

export const ButtonWrapper = styled.div`
  margin: 30px auto 0 auto;
  text-align: center;
`
