import styled from 'styled-components/macro'

export const Container = styled.div`
  background-color: #f7f7f7;
  padding: 20px;
  margin-bottom: 40px;
  border-radius: 5px;
  font-size: 13px;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08),
    0px 0.5px 2px rgba(96, 97, 112, 0.16);
  & > * :not(:last-child) {
    margin-bottom: 5px;
  }
  & > * :nth-child(2) {
    opacity: 0.7;
  }
  & > * :last-child {
    color: #39835f;
  }

  a {
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  span {
    margin-top: 5px;
    margin-left: 10px;
  }
`
