import { Route, Redirect } from 'react-router-dom'
import { Container, Content } from './style'
import Layout from '../../components/layout/default'
import DiagnosisSection from '../../components/diagnosis/diagnosis-section'
import ProgramSection from '../../components/program/program-section'
import ProfileSection from '../../components/profile/profile-section'
import NutritionistSection from '../../components/nutritionist/nutritionist-section'

const SettingsPage = ({ location, match }) => {
  /**
   * We redirect to profile section if no exact route gave in the url
   */
  if (location.pathname === '/settings' || location.pathname === '/settings/') {
    return <Redirect to='/settings/subscriptions' />
  }

  return (
    <Layout>
      <Container>
        <Content>
          <Route
            path={`${match.path}/diagnosis`}
            component={DiagnosisSection}
          />
          <Route
            path={`${match.path}/subscriptions`}
            component={ProgramSection}
          />
          <Route path={`${match.path}/profile`} component={ProfileSection} />
          <Route
            path={`${match.path}/nutritionist`}
            component={NutritionistSection}
          />
        </Content>
      </Container>
    </Layout>
  )
}

export default SettingsPage
