const Constants = {
  FETCH_CALLS_FAILED: `FETCH_CALLS_FAILED`,
  FETCH_CALLS_LOADING: `FETCH_CALLS_LOADING`,
  FETCH_CALLS_SUCCESS: `FETCH_CALLS_SUCCESS`,
  FETCH_LAST_CALL_FAILED: `FETCH_LAST_CALL_FAILED`,
  FETCH_LAST_CALL_LOADING: `FETCH_LAST_CALL_LOADING`,
  FETCH_LAST_CALL_SUCCESS: `FETCH_LAST_CALL_SUCCESS`,
}

export default Constants
