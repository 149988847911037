import styled from 'styled-components/macro'

export const Content = styled.div`
  width: 100%;

  .img-product-1 {
    object-fit: cover;
    height: 90vh !important;
  }
`
