import { useSelector } from 'react-redux'

import { useDispatch } from './index'
import { ActionType } from '../utils/constants'
import {
  getPaymentMethods,
  getFetchPaymentMethodsStatus,
  getFetchPaymentMethodsError,
  fetchAll,
  updatePaymentMethod,
  getUpdatePaymentMethodsStatus,
  getUpdatePaymentMethodsError,
} from '../store/payment-methods'
import { getRechargeToken, getTempToken, getAuthUser } from '../store/auth-user'

export const usePaymentMethods = () => {
  const list = useSelector(getPaymentMethods)

  return { list }
}

export const useFetchPaymentMethods = () => {
  const dispatch = useDispatch()
  const fetchStatus = useSelector(getFetchPaymentMethodsStatus)
  const fetchError = useSelector(getFetchPaymentMethodsError)
  const token = useSelector(getRechargeToken)
  const tempToken = useSelector(getTempToken)
  const authUser = useSelector(getAuthUser)

  return {
    error: fetchError,
    fetch: () => dispatch(fetchAll(authUser.data.session)),
    isError: fetchStatus === ActionType.FAILED,
    isLoading: fetchStatus === ActionType.LOADING,
    isSuccess: fetchStatus === ActionType.SUCCESS,
  }
}

export const useUpdatePaymentMethods = () => {
  const dispatch = useDispatch()
  const updateStatus = useSelector(getUpdatePaymentMethodsStatus)
  const updateError = useSelector(getUpdatePaymentMethodsError)
  const tempToken = useSelector(getTempToken)

  return {
    error: updateError,
    update: updatedData =>
      dispatch(updatePaymentMethod(updatedData, tempToken)),
    isError: updateStatus === ActionType.FAILED,
    isLoading: updateStatus === ActionType.LOADING,
    isSuccess: updateStatus === ActionType.SUCCESS,
  }
}
