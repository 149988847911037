import React from 'react'

import { Container, IconProfile, Name } from './style'
import { NavWrapper, NavLinkArrow, IconArrow } from '../style'
import { IoIosArrowForward } from 'react-icons/io'
import { CgProfile } from 'react-icons/cg'

import { useDispatch } from 'react-redux'
import { logout } from '../../../../store/auth-user'

const PopupNavMobileContent = ({ firstName, setNavBarContent }) => {
  const dispatch = useDispatch()
  return (
    <Container>
      {firstName ? (
        <Name>
          <IconProfile>
            <CgProfile />
          </IconProfile>
          {firstName}
        </Name>
      ) : (
        ''
      )}

      <NavWrapper>
        {!firstName && (
          <>
            <NavLinkArrow>
              <a href='https://www.compliment-pro.com/patient'>
                Espace patient
              </a>
            </NavLinkArrow>
            <NavLinkArrow>
              <a href='https://app.compliment.me/patient-catalog'>Commander</a>
            </NavLinkArrow>
          </>
        )}
        <NavLinkArrow onClick={() => dispatch(logout())} underline>
          Me déconnecter
          <IconArrow>
            <IoIosArrowForward />
          </IconArrow>
        </NavLinkArrow>
      </NavWrapper>
    </Container>
  )
}
export default PopupNavMobileContent
