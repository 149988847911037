import styled from 'styled-components/macro'
import { Color, MediaQuery } from '../../../utils/constants'

export const Container = styled.div`
  margin-top: 60px;
  margin-bottom: 60px;
  width: 100%;
`

export const Title = styled.h3`
  text-align: center;
  margin-bottom: 20px;
  font-size: 30px;
  font-weight: 300;
  color: ${Color.MONTE_CARLO};
`

export const Wrapper = styled.div`
  width: 600px;
  ${MediaQuery.M_AND_DOWN} {
    width: 95%;
    margin: 0 auto;
  }
  margin: 0 auto;
  box-shadow: 0 5px 10px #00000012;
  padding: 30px;

  .Collapsible {
    margin-bottom: 13px;
  }
  .Collapsible__contentInner {
    padding: 10px 0;
    font-weight: 100;
    opacity: 0.5;
    p {
      margin-bottom: 10px !important;
    }
  }
  .Collapsible__trigger {
    div {
      display: flex;
      justify-content: space-between;
      span {
        font-size: 28px;
        color: ${Color.ORANGE};
        transition: all 0.5s;
      }
    }

    cursor: pointer;
    font-size: 18px;
    font-weight: 300;
  }

  .Collapsible__trigger.is-open div span {
    transform: rotate(180deg);
  }
`
