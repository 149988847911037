import React from 'react'
import {
  Flex,
  // ChatWrapper,
  NutritionistWrapper,
  Title,
  Hr,
  NutritionistIntro,
  NutritionistImg,
  NutritionistName,
  NutritionistProfession,
  NutritionistMessage,
} from './style'
import img from '../../../assets/images/solenn.png'
import { Button } from '../../ui'
// import { Color } from '../../../utils/constants'

const DiscussNutritionist = () => {
  return (
    <Flex>
      <NutritionistWrapper>
        <Title>Ma nutritionniste</Title>
        <Hr></Hr>
        <NutritionistIntro>
          <NutritionistImg src={img} />
          <div>
            <NutritionistName>Solenn Leahy</NutritionistName>
            <NutritionistProfession>Nutritionniste</NutritionistProfession>
          </div>
        </NutritionistIntro>
        <NutritionistMessage>
          <p>
            Solenn, Nutritionniste indépendante, est votre nutritionniste
            attitrée. Prenez gratuitement RDV avec elle pour des conseils 100%
            adaptés à vos besoins:
          </p>
          <ul>
            <li>
              Approfondissez la connaissance de votre profil (traitements,
              objectifs,..)
            </li>
            <li>
              Explication détaillée de chaque produit dans votre programme.
            </li>
            <li>Conseils sur mesure pour atteindre vos objectifs santé.</li>
          </ul>
        </NutritionistMessage>
        <div style={{ textAlign: 'center' }}>
          <Button appearance='primary'>
            <a href='https://calendly.com/compliment-me/evaluation-cure-compliment'>
              Prendre rendez-vous
            </a>
          </Button>
        </div>
      </NutritionistWrapper>
      {/* <ChatWrapper>
        <Title>Discuter avec ma nutritionniste</Title>
        <Hr></Hr>
        <Button
          style={{
            backgroundColor: Color.DEEP_SEA,
            color: 'white',
          }}
        >
          Discuter avec ma nutritionniste
        </Button>
      </ChatWrapper> */}
    </Flex>
  )
}

export default DiscussNutritionist
