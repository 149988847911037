import { useState, useEffect } from 'react'
// import { useSelector, useDispatch } from 'react-redux'
// import moment from 'moment'
import Router from './router'
import NakedLayout from './components/layout/naked'
import { Spinner } from './components/ui'
import {
  getDataFromLocalStorage,
  initAmplitude,
  initFacebookPixel,
} from './utils/helpers'
import { useFetchAuthUser } from './hooks'
// import axios from 'axios'
import 'react-toastify/dist/ReactToastify.css'

// import { getTempTokenExpiresAt, logout } from './store/auth-user'

function App() {
  // const tempTokenExpiresAt = useSelector(getTempTokenExpiresAt)
  // const dispatch = useDispatch()
  const [isAutoLoginTried, setIsAutoLoginTried] = useState(false)
  const { setUser, setTokens } = useFetchAuthUser()

  // lougout if temp_token expired
  // useEffect(() => {
  // if (moment(moment()).isBefore(tempTokenExpiresAt)) {
  //   dispatch(logout())
  // }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [tempTokenExpiresAt])

  useEffect(() => {
    if (isAutoLoginTried) return

    const { tokens, authUser } = getDataFromLocalStorage()

    if (authUser && tokens) {
      // TODO: check expirey token first (if not good, delete data from local storage)
      setUser(authUser)
      setTokens(tokens)
      setIsAutoLoginTried(true)
    } else {
      setIsAutoLoginTried(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAutoLoginTried])

  useEffect(() => {
    initAmplitude()
    initFacebookPixel()
    const script = document.createElement('script')
    script.src =
      'https://config.gorgias.chat/gorgias-chat-bundle-loader.js?applicationId=28420'
    script.id = 'gorgias-chat-widget-install-v2'

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  if (!isAutoLoginTried)
    return (
      <NakedLayout>
        <Spinner />
      </NakedLayout>
    )

  return (
    <>
      <Router />
    </>
  )
}

export default App
