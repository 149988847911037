import React from 'react'
import { Container } from './style'
import { BsArrowRight } from 'react-icons/bs'

const RedirectionPro = () => {
  return (
    <Container>
      <h3>Vous êtes professionnel de la santé</h3>
      <p>Connectez vous à votre compte Simplycure</p>
      <a
        rel='noreferrer'
        href='https://app.simplycure.com/login'
        target='_blank'
      >
        <p>Se connecter sur Simplycure</p>
        <span>
          <BsArrowRight />
        </span>
      </a>
    </Container>
  )
}

export default RedirectionPro
