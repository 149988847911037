import React from 'react'
import {
  Title,
  Flex,
  Content,
  ContentTitle,
  ContentText,
  TitleContainer,
  ContentSubTitle,
  Img,
  ButtonWrapper,
  Close,
  Recommended,
} from './style'
import { Dialog, Button } from '../../ui'
import { IoCloseOutline } from 'react-icons/io5'
import { AiOutlineFieldTime } from 'react-icons/ai'
import { Color } from '../../../utils/constants'
import img from '../../../assets/images/collagene.png'

const DialogDignostic = ({ isDialogOpen, setIsDialogOpen }) => {
  return (
    <Dialog isOpen={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
      <Close onClick={() => setIsDialogOpen(false)}>
        <IoCloseOutline />
      </Close>
      <Title>Faites votre diagnostic...</Title>
      <Flex>
        <Content>
          <TitleContainer>
            <Img src={img} />
            <ContentTitle>En ligne</ContentTitle>
            <ContentSubTitle>
              <span>
                <AiOutlineFieldTime />
              </span>{' '}
              en 8 min
            </ContentSubTitle>
          </TitleContainer>
          <ContentText>
            Etablissez votre profil nutritionnel en répondant à un questionnaire
            établi par nos nutritionnistes et découvrez immédiatement votre
            recommandation de programme personnalisé.
          </ContentText>
          <ButtonWrapper>
            <Button
              style={{ padding: '0' }}
              iconRight='arrow-right'
              appearance='underline'
            >
              Faire mon diagnostic en ligne
            </Button>
          </ButtonWrapper>
        </Content>
        <Content
          bg={Color.COLOR_SECONDARY}
          border={`1.5px solid ${Color.DEEP_SEA}`}
        >
          <Recommended>Recommandé</Recommended>
          <TitleContainer>
            <Img src={img} />
            <ContentTitle color={Color.DEEP_SEA}>En ligne</ContentTitle>
            <ContentSubTitle color={Color.DEEP_SEA}>
              <span>
                <AiOutlineFieldTime />
              </span>{' '}
              en 15 min
            </ContentSubTitle>
          </TitleContainer>
          <ContentText color={Color.DEEP_SEA}>
            Profitez des conseils experts de nos nutritionnistes qui definiront
            votre profil nutritionnel, votre programme personnalisé et vous
            donneront des conseils nutrition associés.
          </ContentText>
          <ButtonWrapper>
            <Button
              style={{ padding: '0' }}
              color={Color.DEEP_SEA}
              iconRight='arrow-right'
              appearance='underline'
            >
              Faire mon diagnostic en ligne
            </Button>
            <span>Service offert par Compliment</span>
          </ButtonWrapper>
        </Content>
      </Flex>
    </Dialog>
  )
}

export default DialogDignostic
