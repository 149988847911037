import Constants from './constants'
import axios from 'axios'
import { toast } from 'react-toastify'
import { removeDataFromLocalStorage } from '../../utils/helpers'
import { trackFacebookEvent } from '../../utils/helpers'
import moment from 'moment'

const fetchLoading = () => ({ type: Constants.FETCH_SUBSCRIPTIONS_LOADING })
const fetchSuccess = payload => ({
  type: Constants.FETCH_SUBSCRIPTIONS_SUCCESS,
  payload,
})
const fetchFailed = error => ({
  type: Constants.FETCH_SUBSCRIPTIONS_FAILED,
  error,
})

export const fetchAll = (token, tempToken, customerId) => async dispatch => {
  dispatch(fetchLoading())
  const data = await axios
    .post('https://www.compliment.me/api/getAddressesAndSubscriptions', {
      customerId,
    })
    .catch(() => {
      removeDataFromLocalStorage()
    })
  dispatch(fetchSuccess(data.data))
}

const cancelLoading = () => ({ type: Constants.CANCEL_SUBSCRIPTION_LOADING })
const cancelSuccess = payload => ({
  type: Constants.CANCEL_SUBSCRIPTION_SUCCESS,
  payload,
})
const cancelFailed = error => ({
  type: Constants.CANCEL_SUBSCRIPTION_FAILED,
  error,
})

/**
 * The important thing to now here is that the subscription represent a subscription
 * for a user point of view. In real, a subscription is for one unique product.
 * It mean that if you want to cancel a subscription, you need to cancel all
 * subscription having the same address (an address is a subscription for recharge)
 *
 * @param {string} token Recharge token
 * @param {array} subscription The subscription object (group of subscriptions)
 * @return {void}
 */
export const cancel =
  (session, subscription, cancellationReason = '') =>
  dispatch => {
    dispatch(cancelLoading())

    const subscriptionsToSend = subscription.products.map(product => ({
      cancellation_reason: cancellationReason,
      cancellation_reason_comments: '',
      id: product.id,
      status: 'CANCELLED',
    }))

    const promises = subscriptionsToSend
      .map(subscription => {
        return axios.post('https://www.compliment.me/api/cancelSubscription', {
          session,
          subscriptionId: subscription.id,
          cancellation_reason: subscription.cancellationReason,
        })
      })
      .then(res => {
        trackFacebookEvent('churn')
        window.location.reload()
        // dispatch(cancelSuccess(res.data))
      })
      .catch(e => {
        window.location.reload()
        removeDataFromLocalStorage()
      })

    Promise.all(promises).then(() => {
      window.location.reload()
    })

    // axios
    //   .post(
    //     'https://europe-west1-compliment-backoffice.cloudfunctions.net/rechargeSubscription',
    //     {
    //       token,
    //       customerToken: tempToken,
    //       task: 'cancel-subscription',
    //       subscriptionId: subscription.id,
    //       subscriptions: subscriptionsToSend,
    //     }
    //   )
    // .then(res => {
    //   trackFacebookEvent('churn')
    //   dispatch(cancelSuccess(res.data))
    // })
    // .catch(e => {
    //   dispatch(cancelFailed(e.message))
    //   removeDataFromLocalStorage()
    // })
  }

export const addProductToSubscription =
  (session, data, interval = '30') =>
  dispatch => {
    // dispatch({ type: Constants.ADD_PRODUCT_TO_SUBSCRIPTION_LOADING })

    if (!data.subscription.nextChargedScheduledAt)
      throw Error('Impossible dajouter un produit à un abonnement désactivé')

    axios
      .post('https://www.compliment.me/api/createSubscription', {
        session,
        address_id: data.subscription.id,
        charge_interval_frequency: interval,
        next_charge_scheduled_at: data.subscription.nextChargedScheduledAt,
        order_interval_frequency: interval,
        order_interval_unit: 'days',
        quantity: data.quantity,
        external_variant_id: `${data?.variantId?.split('/')?.pop()}`,
        external_product_id: `${data?.id?.split('/')?.pop()}`,
      })
      .then(res => {
        window.location.reload(false)
      })
      .catch(error => {
        toast.error(
          "Le produit n'a pas été ajouté à votre commande. Réessayer plus tard"
        )
        removeDataFromLocalStorage()
      })

    // axios
    //   .post(
    //     'https://europe-west1-compliment-backoffice.cloudfunctions.net/rechargeSubscription',
    //     {
    //       token,
    //       customerToken: tempToken,
    //       task: 'add-product-to-subscription',
    //       quantity: data.quantity,
    //       addressId: data.subscription.id,
    //       shopifyVariantId: data.variantId,
    //       orderIntervalFrequency: interval,
    //       orderIntervalUnit: 'days',
    //       chargeIntervalFrequency: interval,
    //       nextChargeScheduledAt: data.subscription.nextChargedScheduledAt,
    //     }
    //   )
    //   .then(res => {})
    //   .then(() => {
    //     dispatch(fetchLoading())
    //     axios
    //       .post(
    //         'https://europe-west1-compliment-backoffice.cloudfunctions.net/rechargeQuery',
    //         {
    //           token,
    //           customerToken: tempToken,
    //           schema: `
    //         {
    //           "subscriptions": {
    //             "product": {
    //               "variant": {}
    //             },
    //             "variant": {}
    //           },
    //           "addresses": {
    //             "discount": {
    //               "id": "parent.discount_id"
    //             },
    //             "charges": {
    //               "status": "",
    //               "address_id": "parent.id"
    //             }
    //           }
    //         }`,
    //         }
    //       )
    //       .then(res => {
    //         toast.success(
    //           'Le produit a bien été ajouté à votre prochaine commande'
    //         )
    //         window.location.reload(false)
    //         dispatch({
    //           type: Constants.ADD_PRODUCT_TO_SUBSCRIPTION_SUCCESS,
    //           payload: res.data,
    //         })
    //       })
    //       .catch(e => {
    //         dispatch(fetchFailed(e.message))
    //       })
    //   })
    //   .catch(error => {
    //     toast.error(
    //       "Le produit n'a pas été ajouté à votre commande. Réessayer plus tard"
    //     )
    //     dispatch({
    //       type: Constants.ADD_PRODUCT_TO_SUBSCRIPTION_FAILED,
    //       error: error.message,
    //     })
    //     removeDataFromLocalStorage()
    //   })
  }

export const removeProductFromSubscription =
  (token, tempToken, rechargeSubscriptionId) => async dispatch => {
    dispatch({ type: Constants.REMOVE_PRODUCT_FROM_SUBSCRIPTION_LOADING })

    try {
      await axios.post('https://www.compliment.me/api/deleteASubscription', {
        subscriptionId: rechargeSubscriptionId,
      })
      toast.success('Le produit a bien été retiré à votre prochaine commande')
      window.location.reload(false)
    } catch (err) {
      toast.error("Le produit n'a pas été retiré à votre commande")
      removeDataFromLocalStorage()
      dispatch({
        type: Constants.REMOVE_PRODUCT_FROM_SUBSCRIPTION_FAILED,
        error: "Le produit n'a pas été retiré à vote commande",
      })
    }

    // axios
    //   .post(
    //     'https://europe-west1-compliment-backoffice.cloudfunctions.net/rechargeSubscription',
    //     {
    //       token,
    //       customerToken: tempToken,
    //       task: 'remove-product-from-subscription',
    //       subscriptionId: rechargeSubscriptionId,
    //     }
    //   )
    //   .then(res => {
    //     toast.success('Le produit a bien été retiré à votre prochaine commande')
    //     dispatch({
    //       type: Constants.REMOVE_PRODUCT_FROM_SUBSCRIPTION_SUCCESS,
    //       payload: res.data,
    //     })
    //   })
    //   .catch(error => {
    //     toast.error("Le produit n'a pas été retiré à votre commande")
    //     dispatch({
    //       type: Constants.REMOVE_PRODUCT_FROM_SUBSCRIPTION_FAILED,
    //       error: error.message,
    //     })
    //     removeDataFromLocalStorage()
    //   })
  }

export const changeAddressSubscription = (session, data) => dispatch => {
  dispatch({ type: Constants.CHANGE_ADDRESS_SUBSCRIPTION_LOADING, data })

  axios
    .post('https://www.compliment.me/api/updateAddress', {
      session: session,
      subscriptionId: data.rechargeSubscriptionId,
      address1: data.address1,
      city: data.city,
      country: data.country,
      country_code: data.country,
      first_name: data.firstName,
      last_name: data.lastName,
      phone: data.phone,
      province: data.province,
      zip: data.zip,
    })
    .then(res => {
      window.location.reload()
      // dispatch({
      //   type: Constants.CHANGE_ADDRESS_SUBSCRIPTION_SUCCESS,
      //   payload: res?.data?.address,
      // })
    })
    .catch(error => {
      dispatch({
        type: Constants.CHANGE_ADDRESS_SUBSCRIPTION_FAILED,
        error: error.message,
      })
      removeDataFromLocalStorage()
    })

  // axios
  //   .post(
  //     'https://europe-west1-compliment-backoffice.cloudfunctions.net/rechargeSubscription',
  //     {
  //       token,
  //       customerToken: tempToken,
  //       task: 'change-address-subscription',
  //       subscriptionId: data.rechargeSubscriptionId,
  //       address1: data.address1,
  //       city: data.city,
  //       country: data.country,
  //       firstName: data.firstName,
  //       lastName: data.lastName,
  //       phone: data.phone,
  //       province: data.province,
  //       zip: data.zip,
  //     }
  //   )
  //   .then(res => {
  //     dispatch({
  //       type: Constants.CHANGE_ADDRESS_SUBSCRIPTION_SUCCESS,
  //       payload: res?.data?.address,
  //     })
  //   })
  //   .catch(error => {
  //     dispatch({
  //       type: Constants.CHANGE_ADDRESS_SUBSCRIPTION_FAILED,
  //       error: error.message,
  //     })
  //     removeDataFromLocalStorage()
  //   })
}

function formatDate(inputStr) {
  const date = new Date(inputStr)
  const year = date.getFullYear()
  const month = (date.getMonth() + 1).toString().padStart(2, '0') // +1 because months are 0-indexed
  const day = date.getDate().toString().padStart(2, '0')

  return `${year}-${month}-${day}`
}

async function updateSubscriptionsSequentially(subscriptions, session) {
  const results = []
  for (const subscription of subscriptions) {
    const result = await axios.post(
      'https://www.compliment.me/api/updateSubscriptionChargeDate',
      {
        session,
        subscriptionId: subscription.id,
        // date: '2023-12-12',
        date: formatDate(subscription.next_charge_scheduled_at),
      }
    )
    results.push(result)
  }
  return results
}

export const changeDeliveryDateSubscription =
  (session, subscriptionId, subscriptions) => dispatch => {
    dispatch({ type: Constants.CHANGE_DELIVERY_DATE_SUBSCRIPTION_LOADING })

    updateSubscriptionsSequentially(subscriptions, session)
      .then(results => {
        window.location.reload(false)
      })
      .catch(error => {
        removeDataFromLocalStorage()
        // handle error
      })

    // const promises = subscriptions.map(subscription => {
    //   return axios.post(
    //     'http://localhost:3000/api/updateSubscriptionChargeDate',
    //     {
    //       session,
    //       subscriptionId: subscription.id,
    //       // date: subscription.next_charge_scheduled_at,
    //       date: '2023-11-11',
    //     }
    //   )
    // })
    // Promise.all(promises)
    // .then(res => {
    //   toast.success('La date  de renouvellement a bien été modifié.')
    //   dispatch({
    //     type: Constants.CHANGE_DELIVERY_DATE_SUBSCRIPTION_SUCCESS,
    //     payload: res.data,
    //   })
    // })
    // .catch(error => {
    //   toast.error("La date de renouvellement n'a pas été modifié.")
    //   dispatch({
    //     type: Constants.CHANGE_DELIVERY_DATE_SUBSCRIPTION_FAILED,
    //     error: error.message,
    //   })
    //   // removeDataFromLocalStorage()
    // })
    // axios
    //   .post(
    //     'https://europe-west1-compliment-backoffice.cloudfunctions.net/rechargeSubscription',
    //     {
    //       token,
    //       customerToken: tempToken,
    //       task: 'change-delivery-date',
    //       subscriptionId,
    //       subscriptions,
    //     }
    //   )
    // .then(res => {
    //   toast.success('La date  de renouvellement a bien été modifié.')
    //   dispatch({
    //     type: Constants.CHANGE_DELIVERY_DATE_SUBSCRIPTION_SUCCESS,
    //     payload: res.data,
    //   })
    // })
    // .catch(error => {
    //   toast.error("La date de renouvellement n'a pas été modifié.")
    //   dispatch({
    //     type: Constants.CHANGE_DELIVERY_DATE_SUBSCRIPTION_FAILED,
    //     error: error.message,
    //   })
    //   removeDataFromLocalStorage()
    // })
  }

export const addDiscountToSubscription =
  (token, tempToken, discountCode, subscriptionId) => dispatch => {
    dispatch({ type: Constants.ADD_DISCOUNT_TO_SUBSCRIPTION_LOADING })
    axios
      .post(
        'https://europe-west1-compliment-backoffice.cloudfunctions.net/rechargeSubscription',
        {
          token,
          customerToken: tempToken,
          task: 'add-discount-to-subscription',
          discountCode,
          subscriptionId,
        }
      )

      .then(response => {
        dispatch(fetchLoading())
        axios
          .post(
            'https://europe-west1-compliment-backoffice.cloudfunctions.net/rechargeQuery',
            {
              token,
              customerToken: tempToken,
              schema: `
        {
          "subscriptions": {
            "product": {
              "variant": {}
            },
            "variant": {}
          },
          "addresses": {
            "discount": {
              "id": "parent.discount_id"
            },
            "charges": {
              "status": "",
              "address_id": "parent.id"
            }
          }
        }`,
            }
          )
          .then(res => {
            toast.success('Le code de promo est valide.')
            window.location.reload(false)
            dispatch({
              type: Constants.ADD_DISCOUNT_TO_SUBSCRIPTION_SUCCESS,
              payload: response.data,
              discountCode,
            })
          })
          .catch(e => {
            dispatch(fetchFailed(e.message))
            dispatch({
              type: Constants.ADD_DISCOUNT_TO_SUBSCRIPTION_FAILED,
              error: e.message,
            })
            removeDataFromLocalStorage()
          })
      })
      .catch(error => {
        toast.error('Le code de promo est non valide')
        dispatch({
          type: Constants.ADD_DISCOUNT_TO_SUBSCRIPTION_FAILED,
          error: error.message,
        })
        // removeDataFromLocalStorage()
      })
  }

export const removeDiscountToSubscription =
  (token, tempToken, subscriptionId) => dispatch => {
    dispatch({ type: Constants.REMOVE_DISCOUNT_TO_SUBSCRIPTION_LOADING })
    axios
      .post(
        'https://europe-west1-compliment-backoffice.cloudfunctions.net/rechargeSubscription',
        {
          token,
          customerToken: tempToken,
          task: 'remove-discount-to-subscription',
          subscriptionId,
        }
      )
      .then(res => {
        dispatch(fetchLoading())
        axios
          .post(
            'https://europe-west1-compliment-backoffice.cloudfunctions.net/rechargeQuery',
            {
              token,
              customerToken: tempToken,
              schema: `
              {
                "subscriptions": {
                  "product": {
                    "variant": {}
                  },
                  "variant": {}
                },
                "addresses": {
                  "discount": {
                    "id": "parent.discount_id"
                  },
                  "charges": {
                    "status": "",
                    "address_id": "parent.id"
                  }
                }
              }`,
            }
          )
          .then(res => {
            dispatch(fetchSuccess(res.data))
            dispatch({
              type: Constants.REMOVE_DISCOUNT_TO_SUBSCRIPTION_SUCCESS,
              payload: res.data,
            })
          })
          .catch(e => {
            dispatch(fetchFailed(e.message))
            dispatch({
              type: Constants.REMOVE_DISCOUNT_TO_SUBSCRIPTION_FAILED,
              error: e.message,
            })
          })
      })
      .catch(error => {
        dispatch({
          type: Constants.REMOVE_DISCOUNT_TO_SUBSCRIPTION_FAILED,
          error: error.message,
        })
      })
  }

export const switchOrderIntervalSubscription =
  (session, subscription, createSubscriptionsToSend, interval) => dispatch => {
    dispatch({ type: Constants.SWITCH_ORDER_INTERVAL_SUBSCRIPTION_LOADING })

    // dispatch(cancelLoading())

    const cancelSubscriptionsToSend = subscription.products.map(product => ({
      cancellation_reason: '',
      cancellation_reason_comments: '',
      id: product.id,
      status: 'CANCELLED',
    }))

    const promises = cancelSubscriptionsToSend.map(subscription => {
      return axios.post('https://www.compliment.me/api/cancelSubscription', {
        session,
        subscriptionId: subscription.id,
        cancellation_reason: '',
      })
    })

    Promise.all(promises)

    // axios
    //   .post(
    //     'https://europe-west1-compliment-backoffice.cloudfunctions.net/rechargeSubscription',
    //     {
    //       token,
    //       customerToken: tempToken,
    //       task: 'cancel-subscription',
    //       subscriptionId: subscription.id,
    //       subscriptions: cancelSubscriptionsToSend,
    //     }
    //   )
    //   .then(res => {
    //     axios
    //       .post(
    //         'https://europe-west1-compliment-backoffice.cloudfunctions.net/rechargeSubscription',
    //         {
    //           token,
    //           customerToken: tempToken,
    //           task: 'create-subscriptions',
    //           subscriptionId: subscription.id,
    //           subscriptions: createSubscriptionsToSend,
    //         }
    //       )
    //       .then(res => {
    //         dispatch(fetchLoading())
    //         axios
    //           .post(
    //             'https://europe-west1-compliment-backoffice.cloudfunctions.net/rechargeQuery',
    //             {
    //               token,
    //               customerToken: tempToken,
    //               schema: `
    //             {
    //               "subscriptions": {
    //                 "product": {
    //                   "variant": {}
    //                 },
    //                 "variant": {}
    //               },
    //               "addresses": {
    //                 "discount": {
    //                   "id": "parent.discount_id"
    //                 },
    //                 "charges": {
    //                   "status": "",
    //                   "address_id": "parent.id"
    //                 }
    //               }
    //             }`,
    //             }
    //           )
    //           .then(res => {
    //             toast.success(
    //               `Votre abonnement est bien passé à ${
    //                 interval === '30' ? '1 mois' : '3 mois'
    //               } d'intervalle`
    //             )
    //             dispatch(fetchSuccess(res.data))
    //             dispatch({
    //               type: Constants.SWITCH_ORDER_INTERVAL_SUBSCRIPTION_SUCCESS,
    //               payload: res.data,
    //             })
    //           })
    //           .catch(e => {
    //             dispatch({
    //               type: Constants.SWITCH_ORDER_INTERVAL_SUBSCRIPTION_FAILED,
    //             })
    //             dispatch(fetchFailed(e.message))
    //             removeDataFromLocalStorage()
    //           })
    //       })
    //       .catch(e => {
    //         dispatch({
    //           type: Constants.SWITCH_ORDER_INTERVAL_SUBSCRIPTION_FAILED,
    //         })
    //         removeDataFromLocalStorage()
    //       })
    //   })
    //   .catch(e => {
    //     toast.error(`Une erreur s'est produite, réessayez plus tard`)
    //     dispatch({ type: Constants.SWITCH_ORDER_INTERVAL_SUBSCRIPTION_FAILED })
    //     dispatch(cancelFailed(e.message))
    //     removeDataFromLocalStorage()
    //   })

    // dispatch({ type: Constants.SWITCH_ORDER_INTERVAL_SUBSCRIPTION_LOADING })

    // const subscriptionsToSend = subscription.products.map(product => ({
    //   id: product.id,
    //   order_interval_unit: 'day',
    //   order_interval_frequency: interval,
    //   charge_interval_frequency: interval,
    //   quantity: interval === '30' ? product.quantity / 3 : product.quantity * 3,
    // }))

    // axios
    //   .post(
    //     'https://europe-west1-compliment-backoffice.cloudfunctions.net/rechargeSubscription',
    //     {
    //       token,
    //       task: 'switch-order-interval-subscription',
    //       subscriptionId: subscription.id,
    //       subscriptions: subscriptionsToSend,
    //     }
    //   )
    //   .then(res => {
    //     dispatch({
    //       type: Constants.SWITCH_ORDER_INTERVAL_SUBSCRIPTION_SUCCESS,
    //       payload: res.data,
    //       interval,
    //     })
    //   })
    //   .catch(e => {
    //     dispatch({ type: Constants.SWITCH_ORDER_INTERVAL_SUBSCRIPTION_FAILED })
    //   })
  }

export const changeQuantitySubscription =
  (session, id, quantity) => dispatch => {
    dispatch({ type: Constants.CHANGE_QUANTITY_SUBSCRIPTION_LOADING })

    axios
      .post('https://www.compliment.me/api/updateSubscription', {
        session: session,
        subscriptionId: id,
        quantity: quantity,
      })
      .then(res => {
        dispatch({
          type: Constants.CHANGE_QUANTITY_SUBSCRIPTION_SUCCESS,
          payload: res.data,
        })
      })
      .catch(e => {
        dispatch({ type: Constants.CHANGE_QUANTITY_SUBSCRIPTION_FAILED })
        removeDataFromLocalStorage()
      })

    // axios
    //   .post(
    //     'https://europe-west1-compliment-backoffice.cloudfunctions.net/rechargeSubscription',
    //     {
    //       token,
    //       customerToken: tempToken,
    //       task: 'change-quantity-subscription',
    //       subscriptionId: subscription.id,
    //       subscriptions: [
    //         {
    //           id,
    //           quantity,
    //         },
    //       ],
    //     }
    //   )
    //   .then(res => {
    //     dispatch({
    //       type: Constants.CHANGE_QUANTITY_SUBSCRIPTION_SUCCESS,
    //       payload: res.data,
    //     })
    //   })
    //   .catch(e => {
    //     dispatch({ type: Constants.CHANGE_QUANTITY_SUBSCRIPTION_FAILED })
    //     removeDataFromLocalStorage()
    //   })
  }

export const createSubscription =
  (session, subscription, createSubscriptionsToSend) => dispatch => {
    dispatch({ type: Constants.CREATE_SUBSCRIPTION_LOADING })

    console.log(session, subscription, createSubscriptionsToSend)

    axios
      .post('http://localhost:3000/api/createSubscriptions', {
        session,
        subscriptions: createSubscriptionsToSend,
      })
      .then(res => {
        // window.location.reload(false)
      })
      .catch(error => {
        // removeDataFromLocalStorage()
      })

    // updateSubscriptionsSequentially(createSubscriptionsToSend, session)
    //   .then(results => {
    //     // window.location.reload(false)
    //   })
    //   .catch(error => {
    //     // removeDataFromLocalStorage()
    //     // handle error
    //   })

    // axios
    //   .post(
    //     'https://europe-west1-compliment-backoffice.cloudfunctions.net/rechargeSubscription',
    //     {
    //       token,
    //       customerToken: tempToken,
    //       task: 'create-subscriptions',
    //       subscriptionId: subscription.id,
    //       subscriptions: createSubscriptionsToSend,
    //     }
    //   )
    //   .then(res => {
    //     axios
    //       .post(
    //         'https://europe-west1-compliment-backoffice.cloudfunctions.net/rechargeQuery',
    //         {
    //           token,
    //           customerToken: tempToken,
    //           schema: `
    //     {
    //       "subscriptions": {
    //         "product": {
    //           "variant": {}
    //         },
    //         "variant": {}
    //       },
    //       "addresses": {
    //         "discount": {
    //           "id": "parent.discount_id"
    //         },
    //         "charges": {
    //           "status": "",
    //           "address_id": "parent.id"
    //         }
    //       }
    //     }`,
    //         }
    //       )
    //       .then(res => {
    //         toast.success(`Votre dernière cure à bien été réinitialisé`)
    //         dispatch(fetchSuccess(res.data))
    //         dispatch({
    //           type: Constants.CREATE_SUBSCRIPTION_SUCCESS,
    //           payload: res.data,
    //         })
    //       })
    //       .catch(e => {
    //         dispatch({ type: Constants.CREATE_SUBSCRIPTION_FAILED })
    //         removeDataFromLocalStorage()
    //       })
    //   })
    //   .catch(() => {
    //     toast.error(`Une erreur s'est produite, réessayez plus tard`)
    //     dispatch({ type: Constants.CREATE_SUBSCRIPTION_FAILED })
    //     removeDataFromLocalStorage()
    //   })
  }
