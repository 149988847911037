import cloneDeep from 'lodash.clonedeep'

import Constant from './constants'
import initialState from './state'
import { ActionType } from '../../utils/constants'

/**
 * This function will return the address based on the given address id
 *
 * @param {array} addresses List of addresses
 * @param {number} addressId The concerned address id
 * @return {object} The founded address
 */
const getAddress = (addresses, addressId) =>
  addresses.find(address => address.id === addressId)

const fetchFailed = (state, action) => {
  const newState = cloneDeep(state)
  newState.actions.fetch = { status: ActionType.FAILED, error: action.error }

  return newState
}
const fetchLoading = state => {
  const newState = cloneDeep(state)
  newState.actions.fetch = { status: ActionType.LOADING }

  return newState
}
const fetchSuccess = (state, { payload }) => {
  const newState = cloneDeep(state)
  /**
   * Recharge send us back a list of products, each product is part of a
   * subscription. In order to know which product is in which subscription,
   * we need to sort them by `address_id`
   *
   * So each subscription will be as this:
   *
   * {
   *    id: 123456789, // The 'address_id',
   *    ... // Every data identical for a single subscription
   *    products: [] // containing the complete product object
   * }
   */
  newState.list = []
  const { subscriptions, addresses, products } = payload
  newState.allProducts = products
  subscriptions.forEach(product => {
    const existingSubscription = newState.list.find(
      subscription => subscription.id === product.address_id
    )

    if (existingSubscription) {
      existingSubscription[
        product.status.toUpperCase() === 'ACTIVE'
          ? 'products'
          : 'inactiveProducts'
      ].push(product)
    } else {
      newState.list.push({
        id: product.address_id,
        nextChargedScheduledAt: product.next_charge_scheduled_at,
        createdAt: product.created_at,
        address: getAddress(addresses, product.address_id),
        products: product.status.toUpperCase() === 'ACTIVE' ? [product] : [],
        inactiveProducts:
          product.status.toUpperCase() !== 'ACTIVE' ? [product] : [],
      })
    }
  })

  newState.actions.fetch = { status: ActionType.SUCCESS }

  return newState
}

const cancelFailed = (state, action) => {
  const newState = cloneDeep(state)
  newState.actions.cancel = { status: ActionType.FAILED, error: action.error }

  return newState
}
const cancelLoading = state => {
  const newState = cloneDeep(state)
  newState.actions.cancel = { status: ActionType.LOADING }

  return newState
}
const cancelSuccess = (state, { payload }) => {
  const newState = cloneDeep(state)

  payload.subscriptions.map(subscription => {
    return (newState.list.find(i => i.id === subscription.address_id).products =
      [])
  })

  newState.actions.cancel = { status: ActionType.SUCCESS }

  return newState
}

const addProductFailed = (state, action) => {
  const newState = cloneDeep(state)
  newState.actions.addProduct = {
    status: ActionType.FAILED,
    error: action.error,
  }

  return newState
}
const addProductLoading = state => {
  const newState = cloneDeep(state)
  newState.actions.addProduct = { status: ActionType.LOADING }

  return newState
}
const addProductSuccess = (state, { payload }) => {
  const newState = cloneDeep(state)
  // error because doesn't have products field in payload

  // const selectedAdressIndex = newState.list.findIndex((i) => i.id === payload.subscription.address_id)
  // newState.list[selectedAdressIndex].products = [...newState.list[selectedAdressIndex].products, payload.subscription]

  newState.actions.addProduct = { status: ActionType.SUCCESS }

  return newState
}

const removeProductFailed = (state, action) => {
  const newState = cloneDeep(state)
  newState.actions.removeProduct = {
    status: ActionType.FAILED,
    error: action.error,
  }

  return newState
}
const removeProductLoading = state => {
  const newState = cloneDeep(state)
  newState.actions.removeProduct = { status: ActionType.LOADING }

  return newState
}
const removeProductSuccess = (state, { payload }) => {
  const newState = cloneDeep(state)
  const selectedAdressIndex = newState.list.findIndex(
    i => i.id === payload.subscription.address_id
  )
  const productIndex = newState.list[selectedAdressIndex].products.findIndex(
    i => i.product_title === payload.subscription.product_title
  )
  newState.list[selectedAdressIndex].products.splice(productIndex, 1)
  newState.actions.removeProduct = { status: ActionType.SUCCESS }

  return newState
}

const changeAddressSubscriptionSuccess = (state, { payload }) => {
  const newState = cloneDeep(state)
  const selectedAdressIndex = newState.list.findIndex(i => i.id === payload.id)
  newState.list[selectedAdressIndex].address = payload

  newState.actions.changeAddress = { status: ActionType.SUCCESS }

  return newState
}
const changeAddressSubscriptionLoading = (state, action) => {
  const newState = cloneDeep(state)
  const selectedAdressIndex = newState.list.findIndex(
    i => i.id === action.data.rechargeSubscriptionId
  )
  newState.list[selectedAdressIndex].address = {}
  newState.actions.changeAddress = { status: ActionType.LOADING }

  return newState
}
const changeAddressSubscriptionFailed = (state, action) => {
  const newState = cloneDeep(state)
  newState.actions.changeAddress = {
    status: ActionType.FAILED,
    error: action.error,
  }

  return newState
}

const changeDeliveryDateSuccess = (state, { payload }) => {
  const newState = cloneDeep(state)
  const selectedAdressIndex = newState.list.findIndex(
    i => i.id === payload.subscriptions[0].address_id
  )
  newState.list[selectedAdressIndex].products[0].next_charge_scheduled_at =
    payload.subscriptions[0].next_charge_scheduled_at

  newState.actions.changeDeliveryDate = { status: ActionType.SUCCESS }

  return newState
}
const changeDeliveryDateLoading = state => {
  const newState = cloneDeep(state)
  newState.actions.changeDeliveryDate = { status: ActionType.LOADING }

  return newState
}
const changeDeliveryDateFailed = (state, action) => {
  const newState = cloneDeep(state)
  newState.actions.changeDeliveryDate = {
    status: ActionType.FAILED,
    error: action.error,
  }

  return newState
}

const addDiscountFailed = (state, action) => {
  const newState = cloneDeep(state)
  newState.actions.addDiscount = {
    status: ActionType.FAILED,
    error: action.error,
  }

  return newState
}
const addDiscountLoading = state => {
  const newState = cloneDeep(state)
  newState.actions.addDiscount = { status: ActionType.LOADING }

  return newState
}

const addDiscountSuccess = (state, { payload, discountCode }) => {
  const newState = cloneDeep(state)
  newState.list.find(i => i.id === payload.address.id).address.discount.code =
    discountCode
  newState.actions.addDiscount = { status: ActionType.SUCCESS }
  return newState
}

const removeDiscountFailed = (state, action) => {
  const newState = cloneDeep(state)
  newState.actions.removeDiscount = {
    status: ActionType.FAILED,
    error: action.error,
  }

  return newState
}
const removeDiscountLoading = state => {
  const newState = cloneDeep(state)
  newState.actions.removeDiscount = { status: ActionType.LOADING }

  return newState
}

const removeDiscountSuccess = (state, { payload }) => {
  const newState = cloneDeep(state)
  newState.list.find(i => i.id === payload.address.id).address.discount.code =
    ''
  newState.actions.removeDiscount = { status: ActionType.SUCCESS }
  return newState
}

const switchOrderIntervalFailed = (state, action) => {
  const newState = cloneDeep(state)
  newState.actions.switchOrderInterval = {
    status: ActionType.FAILED,
    error: action.error,
  }

  return newState
}
const switchOrderIntervalLoading = state => {
  const newState = cloneDeep(state)
  newState.actions.switchOrderInterval = { status: ActionType.LOADING }

  return newState
}

const switchOrderIntervalSuccess = (state, { payload, interval }) => {
  const newState = cloneDeep(state)

  // payload.subscriptions.map(subscription => {
  //   return (newState.list.find(i => i.id === subscription.address_id).products =
  //     newState.list
  //       .find(i => i.id === subscription.address_id)
  //       .products.map(p => {
  //         const price = payload.subscriptions.find(i => i.id === p.id).price
  //         return {
  //           ...p,
  //           price,
  //           // charge_interval_frequency: interval,
  //           // order_interval_frequency: interval,
  //         }
  //       }))
  // })

  newState.actions.switchOrderInterval = { status: ActionType.SUCCESS }

  return newState
}

const changeQuantitySuccess = (state, { payload }) => {
  const newState = cloneDeep(state)
  newState.list
    .find(i => i.id === payload.address_id)
    .products.find(i => i.id === payload.id).quantity = payload.quantity
  newState.actions.changeQuantity = { status: ActionType.SUCCESS }
  return newState
}

const changeQuantityFailed = (state, action) => {
  const newState = cloneDeep(state)
  newState.actions.changeQuantity = {
    status: ActionType.FAILED,
    error: action.error,
  }

  return newState
}
const changeQuantityLoading = state => {
  const newState = cloneDeep(state)
  newState.actions.changeQuantity = { status: ActionType.LOADING }

  return newState
}

const createSuccess = (state, { payload }) => {
  const newState = cloneDeep(state)
  newState.actions.create = { status: ActionType.SUCCESS }
  return newState
}

const createFailed = (state, action) => {
  const newState = cloneDeep(state)
  newState.actions.create = {
    status: ActionType.FAILED,
    error: action.error,
  }

  return newState
}
const createLoading = state => {
  const newState = cloneDeep(state)
  newState.actions.create = { status: ActionType.LOADING }

  return newState
}
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Constant.FETCH_SUBSCRIPTIONS_FAILED:
      return fetchFailed(state, action)
    case Constant.FETCH_SUBSCRIPTIONS_LOADING:
      return fetchLoading(state)
    case Constant.FETCH_SUBSCRIPTIONS_SUCCESS:
      return fetchSuccess(state, action)

    case Constant.CANCEL_SUBSCRIPTION_FAILED:
      return cancelFailed(state, action)
    case Constant.CANCEL_SUBSCRIPTION_LOADING:
      return cancelLoading(state)
    case Constant.CANCEL_SUBSCRIPTION_SUCCESS:
      return cancelSuccess(state, action)

    case Constant.ADD_PRODUCT_TO_SUBSCRIPTION_FAILED:
      return addProductFailed(state, action)
    case Constant.ADD_PRODUCT_TO_SUBSCRIPTION_LOADING:
      return addProductLoading(state)
    case Constant.ADD_PRODUCT_TO_SUBSCRIPTION_SUCCESS:
      return addProductSuccess(state, action)

    case Constant.REMOVE_PRODUCT_FROM_SUBSCRIPTION_FAILED:
      return removeProductFailed(state, action)
    case Constant.REMOVE_PRODUCT_FROM_SUBSCRIPTION_LOADING:
      return removeProductLoading(state)
    case Constant.REMOVE_PRODUCT_FROM_SUBSCRIPTION_SUCCESS:
      return removeProductSuccess(state, action)

    case Constant.CHANGE_ADDRESS_SUBSCRIPTION_FAILED:
      return changeAddressSubscriptionFailed(state, action)
    case Constant.CHANGE_ADDRESS_SUBSCRIPTION_LOADING:
      return changeAddressSubscriptionLoading(state, action)
    case Constant.CHANGE_ADDRESS_SUBSCRIPTION_SUCCESS:
      return changeAddressSubscriptionSuccess(state, action)

    case Constant.CHANGE_DELIVERY_DATE_SUBSCRIPTION_FAILED:
      return changeDeliveryDateFailed(state, action)
    case Constant.CHANGE_DELIVERY_DATE_SUBSCRIPTION_LOADING:
      return changeDeliveryDateLoading(state)
    case Constant.CHANGE_DELIVERY_DATE_SUBSCRIPTION_SUCCESS:
      return changeDeliveryDateSuccess(state, action)

    case Constant.ADD_DISCOUNT_TO_SUBSCRIPTION_FAILED:
      return addDiscountFailed(state, action)
    case Constant.ADD_DISCOUNT_TO_SUBSCRIPTION_LOADING:
      return addDiscountLoading(state)
    case Constant.ADD_DISCOUNT_TO_SUBSCRIPTION_SUCCESS:
      return addDiscountSuccess(state, action)

    case Constant.REMOVE_DISCOUNT_TO_SUBSCRIPTION_FAILED:
      return removeDiscountFailed(state, action)
    case Constant.REMOVE_DISCOUNT_TO_SUBSCRIPTION_LOADING:
      return removeDiscountLoading(state)
    case Constant.REMOVE_DISCOUNT_TO_SUBSCRIPTION_SUCCESS:
      return removeDiscountSuccess(state, action)

    case Constant.SWITCH_ORDER_INTERVAL_SUBSCRIPTION_FAILED:
      return switchOrderIntervalFailed(state, action)
    case Constant.SWITCH_ORDER_INTERVAL_SUBSCRIPTION_SUCCESS:
      return switchOrderIntervalSuccess(state, action)
    case Constant.SWITCH_ORDER_INTERVAL_SUBSCRIPTION_LOADING:
      return switchOrderIntervalLoading(state)

    case Constant.CHANGE_QUANTITY_SUBSCRIPTION_FAILED:
      return changeQuantityFailed(state, action)
    case Constant.CHANGE_QUANTITY_SUBSCRIPTION_SUCCESS:
      return changeQuantitySuccess(state, action)
    case Constant.CHANGE_QUANTITY_SUBSCRIPTION_LOADING:
      return changeQuantityLoading(state)

    case Constant.CREATE_SUBSCRIPTION_FAILED:
      return createFailed(state, action)
    case Constant.CREATE_SUBSCRIPTION_SUCCESS:
      return createSuccess(state, action)
    case Constant.CREATE_SUBSCRIPTION_LOADING:
      return createLoading(state)

    default:
      return state
  }
}
