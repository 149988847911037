import React from 'react'
import { Container, NavLink } from './style'

import { useDispatch } from 'react-redux'
import { logout } from '../../../../store/auth-user'

const PopupNavShopContent = () => {
  const dispatch = useDispatch()

  return (
    <Container>
      <NavLink onClick={() => dispatch(logout())} underline>
        Me déconnecter
      </NavLink>
    </Container>
  )
}

export default PopupNavShopContent
