import cloneDeep from 'lodash.clonedeep'

import Constant from './constants'
import initialState from './state'
import { ActionType } from '../../utils/constants'
import { addDataToLocalStorage } from '../../utils/helpers'

const loginFailed = (state, action) => {
  const newState = cloneDeep(state)
  newState.actions.login = { status: ActionType.FAILED, error: action.error }

  return newState
}
const loginLoading = state => {
  const newState = cloneDeep(state)
  newState.actions.login = { status: ActionType.LOADING }

  return newState
}
const loginSuccess = (state, { payload }) => {
  const newState = cloneDeep(state)
  newState.tokens.shopify = payload.shopifyToken
  newState.tokens.shopifyExpiry = payload.shopifyTokenExpire
  newState.tokens.recharge = payload.rechargeToken
  newState.tokens.temp_token = payload.temp_token
  newState.tokens.temp_token_expires_at = payload.temp_token_expires_at
  newState.current = payload.user
  newState.actions.login = { status: ActionType.SUCCESS }

  addDataToLocalStorage({
    user: JSON.stringify(newState.current),
    tokens: JSON.stringify({
      ...newState.tokens,
    }),
  })

  return newState
}

const registerFailed = (state, action) => {
  const newState = cloneDeep(state)
  newState.actions.register = {
    status: ActionType.FAILED,
    error: action.error,
  }

  return newState
}
const registerLoading = state => {
  const newState = cloneDeep(state)
  newState.actions.register = { status: ActionType.LOADING }

  return newState
}
const registerSuccess = (state, action) => {
  const newState = cloneDeep(state)
  newState.actions.register = { status: ActionType.SUCCESS }

  return newState
}

const logoutFailed = (state, action) => {
  const newState = cloneDeep(state)
  newState.actions.logout = { status: ActionType.FAILED, error: action.error }

  return newState
}
const logoutLoading = state => {
  const newState = cloneDeep(state)
  newState.actions.logout = { status: ActionType.LOADING }

  return newState
}
const logoutSuccess = () => {
  window.location = '/login'

  return initialState
}

const setAuthUser = (state, { payload }) => {
  const newState = cloneDeep(state)
  newState.current = payload

  return newState
}
const setAuthTokens = (state, { payload }) => {
  const newState = cloneDeep(state)
  newState.tokens = { ...payload }

  return newState
}

const getCustomerFailed = (state, action) => {
  const newState = cloneDeep(state)
  newState.actions.getCustomer = {
    status: ActionType.FAILED,
    error: action.error,
  }

  return newState
}
const getCustomerLoading = state => {
  const newState = cloneDeep(state)
  newState.actions.getCustomer = { status: ActionType.LOADING }

  return newState
}
const getCustomerSuccess = (state, { payload }) => {
  const newState = cloneDeep(state)
  newState.customerShopify = payload.customer
  newState.actions.getCustomer = { status: ActionType.SUCCESS }

  return newState
}

const updateCustomerFailed = (state, action) => {
  const newState = cloneDeep(state)
  newState.actions.updateCustomer = {
    status: ActionType.FAILED,
    error: action.error,
  }

  return newState
}
const updateCustomerLoading = state => {
  const newState = cloneDeep(state)
  newState.actions.updateCustomer = { status: ActionType.LOADING }

  return newState
}
const updateCustomerSuccess = (state, { payload }) => {
  const newState = cloneDeep(state)
  newState.customerShopify = payload.customerUpdate.customer
  newState.actions.updateCustomer = { status: ActionType.SUCCESS }

  return newState
}

const customerRecoverFailed = (state, action) => {
  const newState = cloneDeep(state)
  newState.actions.customerRecover = {
    status: ActionType.FAILED,
    error: action.error,
  }

  return newState
}
const customerRecoverLoading = state => {
  const newState = cloneDeep(state)
  newState.actions.customerRecover = { status: ActionType.LOADING }

  return newState
}
const customerRecoverSuccess = (state, { payload }) => {
  const newState = cloneDeep(state)
  // newState.customerShopify = payload.customerUpdate.customer
  newState.actions.customerRecover = { status: ActionType.SUCCESS }

  return newState
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Constant.LOGIN_FAILED:
      return loginFailed(state, action)
    case Constant.LOGIN_LOADING:
      return loginLoading(state)
    case Constant.LOGIN_SUCCESS:
      return loginSuccess(state, action)

    case Constant.REGISTER_FAILED:
      return registerFailed(state, action)
    case Constant.REGISTER_LOADING:
      return registerLoading(state)
    case Constant.REGISTER_SUCCESS:
      return registerSuccess(state, action)

    case Constant.LOGOUT_FAILED:
      return logoutFailed(state, action)
    case Constant.LOGOUT_LOADING:
      return logoutLoading(state)
    case Constant.LOGOUT_SUCCESS:
      return logoutSuccess()

    case Constant.SET_AUTH_USER:
      return setAuthUser(state, action)
    case Constant.SET_AUTH_TOKENS:
      return setAuthTokens(state, action)

    case Constant.FETCH_CUSTOMER_FAILED:
      return getCustomerFailed(state, action)
    case Constant.FETCH_CUSTOMER_LOADING:
      return getCustomerLoading(state)
    case Constant.FETCH_CUSTOMER_SUCCESS:
      return getCustomerSuccess(state, action)

    case Constant.UPDATE_CUSTOMER_FAILED:
      return updateCustomerFailed(state, action)
    case Constant.UPDATE_CUSTOMER_LOADING:
      return updateCustomerLoading(state)
    case Constant.UPDATE_CUSTOMER_SUCCESS:
      return updateCustomerSuccess(state, action)

    case Constant.CUSTOMER_RECOVER_FAILED:
      return customerRecoverFailed(state, action)
    case Constant.CUSTOMER_RECOVER_LOADING:
      return customerRecoverLoading(state)
    case Constant.CUSTOMER_RECOVER_SUCCESS:
      return customerRecoverSuccess(state, action)

    default:
      return state
  }
}
