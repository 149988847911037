const IconProgram = () => (
  <svg
    id='Groupe_1041'
    data-name='Groupe 1041'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 104.16 104.159'
  >
    <g id='Groupe_769' data-name='Groupe 769' transform='translate(0)'>
      <path
        id='Tracé_683'
        data-name='Tracé 683'
        d='M13.1,6A6.936,6.936,0,0,0,8.182,8.145,7.056,7.056,0,0,0,6,13.1V82.9a7.043,7.043,0,0,0,2.145,4.956A7.029,7.029,0,0,0,13.1,90.038h13.02v12.983a7.406,7.406,0,0,0,7.1,7.139h69.835a7.482,7.482,0,0,0,7.1-7.1c0-.013,0-.025,0-.037v-69.8a7.056,7.056,0,0,0-2.182-4.956,6.935,6.935,0,0,0-4.919-2.145H90.038V13.1a7.056,7.056,0,0,0-2.182-4.956A6.936,6.936,0,0,0,82.936,6Zm0,4.735H82.936A2.722,2.722,0,0,1,85.3,13.1v13.02H33.224A6.936,6.936,0,0,0,28.3,28.267a7.056,7.056,0,0,0-2.182,4.956V85.3H13.1a2.613,2.613,0,0,1-1.591-.814,2.547,2.547,0,0,1-.777-1.59V13.1A2.722,2.722,0,0,1,13.1,10.735ZM33.224,30.856h69.835a2.722,2.722,0,0,1,2.367,2.367v69.834a2.529,2.529,0,0,1-.777,1.59,2.577,2.577,0,0,1-1.591.777H33.224a2.613,2.613,0,0,1-1.591-.814,2.547,2.547,0,0,1-.777-1.591v-69.8a2.722,2.722,0,0,1,2.367-2.367ZM65.182,48.611A21.39,21.39,0,0,0,54.566,88.521a2.368,2.368,0,1,0,2.293-4.106,16.631,16.631,0,1,1,24.6-17.5l-6.843-3.81a2.368,2.368,0,0,0-2.293,4.143l10.653,5.918a2.368,2.368,0,0,0,3.218-.925L92.11,61.594A2.368,2.368,0,1,0,87.967,59.3l-2.441,4.365A21.337,21.337,0,0,0,65.182,48.611Z'
        transform='translate(-6 -6)'
        fill='#02865b'
      />
    </g>
  </svg>
)

export default IconProgram
