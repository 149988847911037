import React, { useState, useEffect } from 'react'
import PauseDialog from '../pause-dialog'
import { Dialog, Button } from '../../ui'
import {
  Title,
  CalendarWrapper,
  ButtonGroup,
  PickTime,
  Close,
  Text,
  SubTitle,
} from './style'
import { Color } from '../../../utils/constants'
import { IoCloseOutline } from 'react-icons/io5'
import {
  useChangeDeliveryDateSubscription,
  useSubscription,
} from '../../../hooks'
import 'react-calendar/dist/Calendar.css'
import Calendar from 'react-calendar'
import moment from 'moment'

const RenewalDateDialog = ({ isDialogOpen, setIsDialogOpen, subscription }) => {
  const [dateValue, onChange] = useState(new Date())

  const [isPauseDialogOpen, setIsPauseDialogOpen] = useState(false)
  const {
    change: changeDate,
    isLoading: changeDateIsLoading,
    isSuccess: changeDateIsSuccess,
  } = useChangeDeliveryDateSubscription()
  const {
    cancel: cancelSubscription,
    isLoading: isCancelLoading,
    isSuccess: isCancelSuccess,
  } = useSubscription()

  const onChangeDate = (subscription, dateValue) => {
    var startDate = new Date(dateValue)
    // seconds * minutes * hours * milliseconds = 1 day
    var day = 60 * 60 * 24 * 1000
    var endDate = new Date(startDate.getTime() + day)

    const products = subscription?.products.map(p => {
      return {
        id: p.id,
        next_charge_scheduled_at: endDate,
        status: p.status,
      }
    })
    changeDate(subscription?.id, products)
  }

  useEffect(() => {
    changeDateIsLoading === false && setIsDialogOpen(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeDateIsSuccess])

  useEffect(() => {
    isCancelLoading === false && setIsDialogOpen(false)
    isCancelLoading === false && setIsPauseDialogOpen(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCancelSuccess])

  return (
    <>
      <Dialog isOpen={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <Close onClick={() => setIsDialogOpen(false)}>
          <IoCloseOutline />
        </Close>
        <Title>Modifier la date de renouvellement</Title>
        <SubTitle>Renouveller ma prochaine cure dans: </SubTitle>
        <PickTime>
          <span
            onClick={() =>
              onChangeDate(
                subscription,
                moment(moment(), 'YYYY-MM-DD').add(14, 'days')
              )
            }
          >
            2 semaines
          </span>
          <span
            onClick={() =>
              onChangeDate(
                subscription,
                moment(moment(), 'YYYY-MM-DD').add(30, 'days')
              )
            }
          >
            1 mois
          </span>
          <span
            onClick={() =>
              onChangeDate(
                subscription,
                moment(moment(), 'YYYY-MM-DD').add(60, 'days')
              )
            }
          >
            2 mois
          </span>
        </PickTime>
        <CalendarWrapper>
          <Calendar
            minDate={new Date()}
            onChange={onChange}
            value={dateValue}
          />
        </CalendarWrapper>
        <Text>
          Pour commander votre programme immédiatement, décalez la date de
          renouvellement à aujourd'hui et confirmez la commande dans votre
          programme
        </Text>
        <ButtonGroup>
          <Button
            onClick={() => setIsPauseDialogOpen(true)}
            style={{ padding: '0' }}
            color={Color.DEEP_SEA}
            iconRight='arrow-right'
            appearance='underline'
            // onClick={() => cancelSubscription(subscription)}
          >
            Mettre mon programme en pause
          </Button>

          <Button
            appearance='primary'
            onClick={() => onChangeDate(subscription, dateValue)}
            isLoading={changeDateIsLoading}
          >
            Sauvegarde les modifications
          </Button>
        </ButtonGroup>
      </Dialog>
      <PauseDialog
        isDialogOpen={isPauseDialogOpen}
        setIsDialogOpen={setIsPauseDialogOpen}
        onCancelSubscription={(subscription, reason) =>
          cancelSubscription(subscription, reason)
        }
        subscription={subscription}
        isCancelLoading={isCancelLoading}
      />
    </>
  )
}

export default RenewalDateDialog
