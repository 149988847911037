import { createGlobalStyle } from 'styled-components/macro'

import { Font } from '../utils/constants'

export default createGlobalStyle`
  *,
  *:before,
  *:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  body,
  #root {
    width: 100vw;
    min-height: 100vh;
    font-family: ${Font.BASIS_GROTESQUE_PRO};
    z-index: 0;
  }

  #dialog-root {
    z-index: 100;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
`
