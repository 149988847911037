import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLazyQuery } from '@apollo/client'
import { toast } from 'react-toastify'

import { ActionType } from '../utils/constants'
import {
  fetchLoading,
  getFetchProductsStatus,
  fetchSuccess,
  fetchFailed,
} from '../store/products'
import { COLLECTION_BY_HANDLE } from '../service/queries'

export const useProducts = () => {
  const dispatch = useDispatch()
  const fetchProductsStatus = useSelector(getFetchProductsStatus)
  //   const authToken = useSelector(getUserToken)
  //   const context = authToken
  //     ? { headers: { Authorization: `Bearer ${authToken}` } }
  //     : undefined
  const [
    fetchProducts,
    { data: productsData, loading: productsLoading, error: productsError },
  ] = useLazyQuery(COLLECTION_BY_HANDLE)

  useEffect(() => {
    fetchProductsStatus !== ActionType.LOADING &&
      fetchProducts({ variables: { perPage: 21 } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * Sync fetchAuthPrescriber with store
   */
  useEffect(() => {
    productsLoading &&
      fetchProductsStatus !== ActionType.LOADING &&
      dispatch(fetchLoading())
  }, [productsLoading, dispatch, fetchProductsStatus])
  useEffect(() => {
    productsData &&
      fetchProductsStatus !== ActionType.SUCCESS &&
      dispatch(fetchSuccess(productsData))
  }, [productsData, dispatch, fetchProductsStatus])
  useEffect(() => {
    if (productsError && fetchProductsStatus !== ActionType.FAILED) {
      dispatch(fetchFailed(productsError))
      toast.error(
        "Une erreur s'est produite lors de la récupération des produits, réessayez plus tard"
      )
    }
  }, [productsError, dispatch, fetchProductsStatus])
  return {
    fetchProducts: fetchProducts,
    data: productsData?.collectionByHandle?.products.edges,
    failed: fetchProductsStatus === ActionType.FAILED,
    loading: fetchProductsStatus === ActionType.LOADING,
    success: fetchProductsStatus === ActionType.SUCCESS,
  }
}
