const IconDiagnostic = () => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 104.66 104.66'>
    <path
      id='Tracé_688'
      data-name='Tracé 688'
      d='M476.971,924.89l9.564-8.551a1.578,1.578,0,0,1,1.052-.4h32a1.578,1.578,0,0,1,0,3.156H488.19l-11.219,10.03v61a11.034,11.034,0,0,1-11.056,11.037h-37.86A11.052,11.052,0,0,1,417,990.125v-82.09A11.033,11.033,0,0,1,428.056,897h37.86a11.057,11.057,0,0,1,11.056,11.037Zm-47.346-24.734h-1.57a7.877,7.877,0,0,0-7.9,7.879v82.09a7.9,7.9,0,0,0,7.9,7.879h37.86a7.878,7.878,0,0,0,7.9-7.881V931.946l-22.449,20.069a4.733,4.733,0,1,1-2.1-2.353l24.553-21.95V908.037a7.9,7.9,0,0,0-7.9-7.881h-1.57V904.1a7.111,7.111,0,0,1-7.112,7.1h-20.5a7.107,7.107,0,0,1-7.112-7.1Zm3.156,0V904.1a3.95,3.95,0,0,0,3.956,3.945h20.5a3.955,3.955,0,0,0,3.956-3.945v-3.946ZM495.909,933.3a1.578,1.578,0,1,1,0-3.156h23.673a1.578,1.578,0,0,1,0,3.156Zm0,14.2a1.578,1.578,0,1,1,0-3.156h23.673a1.578,1.578,0,1,1,0,3.156Z'
      transform='translate(-416.75 -896.75)'
      fill='#02865b'
      stroke='#02865b'
      stroke-width='0.5'
    />
  </svg>
)

export default IconDiagnostic
