import React, { useState } from 'react'
import { Container, Header, Nav, Title, NavLink } from '../../style'
import MyProgram from '../my-program'
import OrderHistory from '../order-history'
import { useAuth } from '../../../hooks'

const ProgramSection = () => {
  const [nav, setNav] = useState(2)
  const { authUser } = useAuth()

  return (
    <Container>
      <Header>
        <Title>Le programme de {authUser?.firstName}</Title>
        <Nav>
          <NavLink onClick={() => setNav(2)} active={nav === 2}>
            Mon programme
            {nav === 2 && <span></span>}
          </NavLink>
          <NavLink onClick={() => setNav(1)} active={nav === 1}>
            Mon historique de commandes
            {nav === 1 && <span></span>}
          </NavLink>
        </Nav>
      </Header>

      {nav === 2 && <MyProgram />}
      {nav === 1 && <OrderHistory />}
    </Container>
  )
}

export default ProgramSection
