import styled from 'styled-components/macro'

import { Color } from '../../../utils/constants'

export const Icon = styled.div`
  width: 14px;
  height: 14px;
  position: relative;

  > svg {
    position: absolute;
    top: 0;
    left: 0;
    fill: ${({ color }) => color || Color.MINE_SHAFT};
  }
`
