const Constants = {
  LOGIN_FAILED: `LOGIN_FAILED`,
  LOGIN_LOADING: `LOGIN_LOADING`,
  LOGIN_SUCCESS: `LOGIN_SUCCESS`,
  LOGOUT_FAILED: `LOGOUT_FAILED`,
  LOGOUT_LOADING: `LOGOUT_LOADING`,
  LOGOUT_SUCCESS: `LOGOUT_SUCCESS`,
  REGISTER_FAILED: `REGISTER_FAILED`,
  REGISTER_LOADING: `REGISTER_LOADING`,
  REGISTER_SUCCESS: `REGISTER_SUCCESS`,
  SET_AUTH_USER: `SET_AUTH_USER`,
  SET_AUTH_TOKENS: `SET_AUTH_TOKENS`,
  FETCH_CUSTOMER_LOADING: 'FETCH_CUSTOMER_LOADING',
  FETCH_CUSTOMER_SUCCESS: 'FETCH_CUSTOMER_SUCCESS',
  FETCH_CUSTOMER_FAILED: 'FETCH_CUSTOMER_FAILED',
  UPDATE_CUSTOMER_LOADING: 'UPDATE_CUSTOMER_LOADING',
  UPDATE_CUSTOMER_SUCCESS: 'UPDATE_CUSTOMER_SUCCESS',
  UPDATE_CUSTOMER_FAILED: 'UPDATE_CUSTOMER_FAILED',
  CUSTOMER_RECOVER_LOADING: 'CUSTOMER_RECOVER_LOADING',
  CUSTOMER_RECOVER_SUCCESS: 'CUSTOMER_RECOVER_SUCCESS',
  CUSTOMER_RECOVER_FAILED: 'CUSTOMER_RECOVER_FAILED',
}

export default Constants
