import styled, { keyframes, css } from 'styled-components/macro'

import { Icon } from '../icon/style'
import { Color, MediaQuery } from '../../../utils/constants'

const spin = keyframes`
  100% { transform: rotate(360deg) }
`

export const ButtonBase = styled.button`
  padding: 8px 25px;
  /* border-radius: 30px; */
  font-size: ${({ $size }) => ($size ? $size : '15px')};
  line-height: 125%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  /* color: ${Color.MINE_SHAFT}; */
  color: ${({ $color }) => ($color ? $color : '')};
  text-decoration: none;
  text-align: center;
  /* opacity: ${({ $isDisabled }) => ($isDisabled ? '0.5' : '1')}; */
  cursor: ${({ $isDisabled }) => ($isDisabled ? 'not-allowed' : 'pointer')};
  /* background-color: ${Color.TRANSPARENT}; */
  background: none;
  border: none;

  &:hover {
    /* background-color: ${({ $isDisabled }) =>
      $isDisabled ? 'none' : 'rgba(0, 0, 0, 0.03)'}; */
  }

  ${MediaQuery.M_AND_DOWN} {
    /* padding: 8px 15px; */
  }

  > ${Icon} {
    margin-right: ${({ $hasMarginRight }) => ($hasMarginRight ? '5px' : '0')};
    margin-left: ${({ $hasMarginLeft }) => ($hasMarginLeft ? '5px' : '0')};
    animation: ${({ $isLoading }) =>
      $isLoading
        ? css`
            ${spin} 1s linear infinite
          `
        : 'none'};
  }
`

export const ButtonDefault = styled(ButtonBase)`
  border: 1px solid #ddd;
`
export const ButtonUnderline = styled(ButtonBase)`
  border-bottom: ${({ $color }) =>
    $color ? `1px solid ${$color}` : ' 1px solid black'};
`

export const ButtonPrimary = styled(ButtonBase)`
  border: 1px solid ${Color.DEEP_SEA};
  color: white;
  background-color: ${Color.DEEP_SEA};

  /* &:hover {
    ${({ $isDisabled }) => {
    if (!$isDisabled) return `color: ${Color.DEEP_SEA};`
  }}
  } */
`

export const ButtonMinimal = styled(ButtonBase)`
  color: ${Color.DEEP_SEA};

  &:hover {
    text-decoration: underline;
  }
`
