import React, { useState } from 'react'
import { Container, Header, Nav, Title, NavLink } from '../../style'
import { useAuth } from '../../../hooks'
import DiscussNutritionist from '../discuss-nutritionist'
// import Consultations from '../consultations'

const NutritionistSection = () => {
  const [nav, setNav] = useState(1)
  const { authUser } = useAuth()

  return (
    <Container>
      <Header>
        <Title>La nutritionniste de {authUser?.firstName}</Title>
        <Nav>
          <NavLink onClick={() => setNav(1)} active={nav === 1}>
            Discuter avec ma nutritionniste
            {nav === 1 && <span></span>}
          </NavLink>
          {/* <NavLink onClick={() => setNav(2)} active={nav === 2}>
            Mes consultations
            {nav === 2 && <span></span>}
          </NavLink> */}
        </Nav>
      </Header>

      {nav === 1 && <DiscussNutritionist />}
      {/* {nav === 2 && <Consultations />} */}
    </Container>
  )
}

export default NutritionistSection
