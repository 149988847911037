import styled from 'styled-components/macro'
import { Color, MediaQuery } from '../../../utils/constants'

export const Close = styled.div`
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 30px;
  z-index: 10001;
`

export const Title = styled.h3`
  font-size: 25px;
  color: ${Color.DEEP_SEA};
  text-align: center;
  margin-bottom: 30px;
  font-weight: 300;
`

export const Nav = styled.div`
  display: flex;
  justify-content: center;
`

export const NavItem = styled.div`
  cursor: pointer;
  margin: 0 10px;

  border-bottom: ${props => (props.active ? '1px solid black' : '')};
`

export const Hr = styled.hr`
  margin: 15px 0;
  width: 100%;
  border: 0 dotted;
  height: 1px;
  background: #eee;
`

export const ProductList = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(22%, 1fr));
  grid-gap: 30px;
  width: 100%;
  margin: 0 auto;

  ${MediaQuery.L_AND_DOWN} {
    grid-template-columns: repeat(auto-fill, minmax(33%, 1fr));
  }
`

export const SpinnerWrapper = styled.div`
  z-index: 10000;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.3);
`

export const Input = styled.input`
  background: #f8f9fc;
  color: #080038;
  font-size: 16px;
  border: none;
  outline: none;
  height: 40px;
  padding: 10px 10px;
`

export const ButtonWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
`
