import styled from 'styled-components/macro'
import { Color } from '../../../../utils/constants'

export const PromoCode = styled.form`
  display: flex;
  align-items: center;
  input {
    font-size: 12px;
    border: none;
    outline: none;
    height: 25px;
    padding: 0px 6px;
    background-color: #e7e7e7;
  }
  p {
    display: flex;
    align-items: center;
    font-size: 12px;
    height: 25px;
    padding: 0px 6px;
    background-color: #e7e7e7;
  }
  button {
    border: none;
    font-size: 12px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    height: 25px;
    background-color: #e7e7e7;
    color: ${Color.DEEP_SEA};
  }
`

export const LoadingIcon = styled.button`
  span {
    font-size: 10px;
    -webkit-animation: rotating 1s linear infinite;
    -moz-animation: rotating 1s linear infinite;
    -ms-animation: rotating 1s linear infinite;
    -o-animation: rotating 1s linear infinite;
    animation: rotating 1s linear infinite;
  }

  @-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`
