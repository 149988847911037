import React, { useState, useEffect } from 'react'
import {
  Wrapper,
  Image,
  Tags,
  Name,
  Footer,
  Price,
  ImageWrapper,
  Header,
  QuantityChoiceWrapper,
  QuantityChoice,
  Select,
} from './style'
import { Button } from '../../ui'
import { Color } from '../../../utils/constants'

const ProductCartReco = ({
  mainImage,
  title,
  img,
  collectionhighlight,
  collectionallegation,
  variant,
  onAddClick,
  isLoading,
  variants,
  sellingPlans,
}) => {
  const [currentPlan, setCurrentPlan] = useState(sellingPlans?.[0]?.node?.id)

  const sortedVariants = [...variants]
    ?.sort((a, b) => Number(a?.node?.price) - Number(b?.node?.price))
    ?.filter(item => item?.node?.title?.startsWith('mp'))

  const [selectedvariant, setSelectedvariant] = useState(
    variants?.find(i => i?.node?.id === sortedVariants[0]?.node?.id)
  )

  useEffect(() => {
    if (variants && sortedVariants.length > 0) {
      const defaultVariant = variants.find(
        i => i?.node?.id === sortedVariants[0]?.node?.id
      )
      setSelectedvariant(defaultVariant)
    }
  }, [variants, sortedVariants])

  const handleChange = event => {
    setCurrentPlan(event.target.value)
  }

  function translateToFrench(phrase) {
    const regex = /Delivery every (\d+) Days/
    const matches = phrase.match(regex)

    if (matches && matches.length > 1) {
      const days = matches[1]
      return `Livraison tous les ${days} jours`
    }

    return 'Translation not found'
  }

  function gatDaysInterval(phrase) {
    if (phrase) {
      const regex = /Delivery every (\d+) Days/
      const matches = phrase.match(regex)

      if (matches && matches.length > 1) {
        const days = matches[1]
        return days
      }

      return null
    } else return null
  }

  return (
    <Wrapper>
      <Header>
        <ImageWrapper>
          <Image src={mainImage} />
        </ImageWrapper>
        <Name>{title}</Name>
      </Header>
      <Tags></Tags>

      {!!sellingPlans?.length && (
        <Select value={currentPlan} onChange={handleChange}>
          {sellingPlans?.map(plan => (
            <option key={plan.node.id} value={plan.node.id}>
              {translateToFrench(plan.node.name)}
            </option>
          ))}
        </Select>
      )}

      <QuantityChoiceWrapper>
        {sortedVariants?.map((item, index) => {
          return (
            <QuantityChoice
              selected={item?.id === selectedvariant?.id}
              key={index}
              onClick={() => {
                setSelectedvariant(item)
              }}
            >
              {item?.node?.title}
            </QuantityChoice>
          )
        })}
      </QuantityChoiceWrapper>

      <Footer>
        <Price>{selectedvariant?.node?.priceV2?.amount}€</Price>
        <Button
          style={{
            padding: '0',
            border: 'none',
            cursor: isLoading ? 'wait' : '',
          }}
          color={Color.ORANGE}
          iconRight='plus'
          onClick={() =>
            onAddClick(
              selectedvariant?.node?.id,
              gatDaysInterval(
                sellingPlans?.find(i => i?.node?.id === currentPlan)?.node?.name
              )
            )
          }
        >
          ajouter
        </Button>
      </Footer>
    </Wrapper>
  )
}

export default ProductCartReco
