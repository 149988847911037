import { useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { Title, Form, FlexInput, ButtonWrapper, CountryField } from './style'
import { Dialog, Field, Button } from '../../ui'
import ReactSelect from 'react-select'
import countryList from 'react-select-country-list'

import { useChangeAddressSubscription } from '../../../hooks'

const validationSchema = Yup.object().shape({
  // address1: Yup.string().required('Champs obligatoire'),
  // city: Yup.string().required('Champs obligatoire'),
  // country: Yup.string().required('Champs obligatoire'),
  // firstName: Yup.string().required('Champs obligatoire'),
  // lastName: Yup.string().required('Champs obligatoire'),
  // phone: Yup.string().required('Champs obligatoire'),
  // province: Yup.string(),
  // zip: Yup.string().required('Champs obligatoire'),
})

const SubscriptionAddressModal = ({
  subscription,
  isOpen,
  onClose,
  onChange,
}) => {
  const { change, isLoading, isSuccess } = useChangeAddressSubscription()
  const options = useMemo(() => countryList().getData(), [])

  const value = options.filter(
    i => i.label === subscription?.address?.country
  )[0]?.value
  const label = options.filter(
    i => i.label === subscription?.address?.country
  )[0]?.label

  const [country, setCountry] = useState(
    value && label ? { label: label, value: value } : ''
  )

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues: {
      address1: subscription?.address.address1 || '',
      address2: subscription?.address.address1 || '',
      city: subscription?.address.city || '',
      country: subscription?.address.country || '',
      firstName: subscription?.address.first_name || '',
      lastName: subscription?.address.last_name || '',
      phone: subscription?.address.phone || '',
      province: '',
      zip: subscription?.address.zip || '',
    },
    validationSchema,
    onSubmit: values => {
      change({
        rechargeSubscriptionId: subscription.id,
        address1: values.address1,
        city: values.city,
        country: country.label,
        firstName: values.firstName,
        lastName: values.lastName,
        phone: values.phone,
        province: values.province,
        zip: values.zip,
      })
    },
  })

  const changeHandler = value => {
    setCountry(value)
  }

  useEffect(() => {
    if (!subscription) return

    subscription.address.address1 &&
      setFieldValue('address1', subscription.address.address1)
    subscription.address.address2 &&
      setFieldValue('address2', subscription.address.address2)
    subscription.address.city &&
      setFieldValue('city', subscription.address.city)
    subscription.address.country &&
      setFieldValue('country', subscription.address.country)
    subscription.address.first_name &&
      setFieldValue('firstName', subscription.address.first_name)
    subscription.address.last_name &&
      setFieldValue('lastName', subscription.address.last_name)
    subscription.address.phone &&
      setFieldValue('phone', subscription.address.phone)
    subscription.address.province &&
      setFieldValue('province', subscription.address.province)
    subscription.address.zip && setFieldValue('zip', subscription.address.zip)
  }, [setFieldValue, subscription])

  useEffect(() => {
    isLoading === false && onClose()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess])

  return (
    <Dialog isOpen={isOpen} onClose={onClose}>
      <Title>Modifiez votre addresse de livraison</Title>
      <Form onSubmit={handleSubmit}>
        <FlexInput>
          <Field
            label='Prénom'
            name='firstName'
            id='firstName'
            type='text'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.firstName}
            error={errors.firstName}
            touched={touched.firstName}
          />
          <Field
            label='Nom de famille'
            name='lastName'
            id='lastName'
            type='text'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.lastName}
            error={errors.lastName}
            touched={touched.lastName}
          />
        </FlexInput>
        <Field
          label='Adresse'
          name='address1'
          id='address1'
          type='text'
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.address1}
          error={errors.address1}
          touched={touched.address1}
        />
        {/* <Field
          label='Adresse 2'
          name='address2'
          id='address2'
          type='text'
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.address2}
          error={errors.address2}
          touched={touched.address2}
        /> */}
        <Field
          label='Ville'
          name='city'
          id='city'
          type='text'
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.city}
          error={errors.city}
          touched={touched.city}
        />
        <CountryField>
          <label>Pays</label>
          <ReactSelect
            options={options}
            // value={country}
            onChange={changeHandler}
            // defaultInputValue={subscription?.address.country}
            defaultValue={{ label: label, value: value }}
          />
        </CountryField>
        <Field
          label='Code postal'
          name='zip'
          id='zip'
          type='number'
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.zip}
          error={errors.zip}
          touched={touched.zip}
        />

        <Field
          label='Phone'
          name='phone'
          id='phone'
          type='tel'
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.phone}
          error={errors.phone}
          touched={touched.phone}
        />
        {/* <Field
          label='Province'
          name='province'
          id='province'
          type='text'
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.province}
          error={errors.province}
          touched={touched.province}
        /> */}
        <ButtonWrapper>
          <Button isLoading={isLoading} appearance='primary' type='submit'>
            Valider
          </Button>
        </ButtonWrapper>
      </Form>
    </Dialog>
  )
}

SubscriptionAddressModal.propTypes = {
  isOpen: PropTypes.bool,
  onDateSelected: PropTypes.func,
  onClose: PropTypes.func,
}

export default SubscriptionAddressModal
