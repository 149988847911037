import React from 'react'
import PropTypes from 'prop-types'

import {
  ButtonDefault,
  ButtonMinimal,
  ButtonPrimary,
  ButtonUnderline,
} from './style'
import Icon from '../icon'

const Buttons = {
  default: ButtonDefault,
  underline: ButtonUnderline,
  minimal: ButtonMinimal,
  primary: ButtonPrimary,
}

const getButtonComponent = appearance => Buttons[appearance]

const ButtonEl = ({
  appearance,
  children,
  iconLeft,
  iconRight,
  isDisabled,
  isLoading,
  color,
  size,
  ...props
}) => {
  // const [isHovering, setIsHovering] = useState(false)
  const Button = getButtonComponent(appearance)
  const iconColor = appearance === 'primary' ? 'white' : color
  // const iconColorHover = appearance === 'primary' ? Color.DEEP_SEA : undefined

  return (
    <Button
      {...props}
      $hasMarginRight={!!iconLeft}
      $hasMarginLeft={!!iconRight}
      $isDisabled={isDisabled || isLoading}
      $isLoading={isLoading}
      $color={color}
      $size={size}
      // onMouseEnter={() => setIsHovering(true)}
      // onMouseLeave={() => setIsHovering(false)}
    >
      {iconLeft && !isLoading && <Icon name={iconLeft} color={iconColor} />}
      {isLoading && <Icon name='loading' />}
      {children}

      {iconRight && !isLoading && (
        <Icon
          name={iconRight}
          // color={isHovering ? iconColorHover : iconColor}
          color={iconColor}
        />
      )}
    </Button>
  )
}

ButtonEl.propTypes = {
  appearance: PropTypes.oneOf(Object.keys(Buttons)),
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
}

ButtonEl.defaultProps = {
  appearance: 'default',
  isDisabled: false,
  isLoading: false,
}

export default ButtonEl
