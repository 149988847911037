import Constants from './constants'
// import axios from 'axios'

export const fetchLoading = () => ({ type: Constants.FETCH_PRODUCTS_LOADING })
export const fetchSuccess = payload => ({
  type: Constants.FETCH_PRODUCTS_SUCCESS,
  payload,
})
export const fetchFailed = error => ({
  type: Constants.FETCH_PRODUCTS_FAILED,
  error,
})

// export const fetchAll = () => dispatch => {
//   dispatch(fetchLoading())
//   axios
//     .post(
//       'https://europe-west1-compliment-backoffice.cloudfunctions.net/shopifyGraphqlQuery',
//       {
//         query: `{
//           collectionByHandle(handle: "nos-produits-cure-vitamines-complements-alimentaires") {
//             products(first: 40) {
//               edges {
//                 node {
//                   id
//                   handle
//                   title
//                   description
//                   featuredImage {
//                     src
//                   }
//                   variants(first: 10) {
//                     edges {
//                       node {
//                         id
//                         sku
//                         price
//                         selectedOptions {
//                           name
//                           value
//                         }
//                       }
//                     }
//                   }
//                 }
//                 cursor
//               }
//               pageInfo {
//                 hasNextPage
//               }
//             }
//           }
//         }`,
//         variables: {},
//       }
//     )
//     .then(res =>
//       dispatch(fetchSuccess(res.data.data.collectionByHandle.products.edges))
//     )
//     .catch(e => dispatch(fetchFailed(e.message)))
// }
