const IconProfil = () => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 89.422 104.478'>
    <g
      id='Groupe_1042'
      data-name='Groupe 1042'
      transform='translate(0.15 0.169)'
    >
      <path
        id='Tracé_684'
        data-name='Tracé 684'
        d='M82.545,37.861A25.523,25.523,0,1,0,57.023,63.245,25.522,25.522,0,0,0,82.545,37.861Zm-45.527,0a20,20,0,1,1,20,19.866,20,20,0,0,1-20-19.866Z'
        transform='translate(-12.462 -12.199)'
        fill='#02865b'
        stroke='rgba(2,134,91,0.25)'
        stroke-width='0.3'
      />
      <path
        id='Tracé_685'
        data-name='Tracé 685'
        d='M106.822,98.416c0-25.936-19.59-46.216-44.561-46.216S17.7,72.48,17.7,98.416a2.759,2.759,0,0,0,5.518,0c0-23.177,16.831-40.7,39.043-40.7S101.3,75.239,101.3,98.416a2.759,2.759,0,0,0,5.518,0Z'
        transform='translate(-17.7 2.984)'
        fill='#02865b'
        stroke='rgba(2,134,91,0.25)'
        stroke-width='0.3'
      />
    </g>
  </svg>
)

export default IconProfil
