import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import thunk from 'redux-thunk'

import { reducer as authReducer } from './auth-user'
import { reducer as ordersReducer } from './orders'
import { reducer as paymentMethodsReducer } from './payment-methods'
import { reducer as subscriptionsReducer } from './subscriptions'
import { reducer as productsReducer } from './products'
import { reducer as diagnosticsReducer } from './diagnostics'
import { reducer as callsReducer } from './calls'

const rootReducer = combineReducers({
  auth: authReducer,
  orders: ordersReducer,
  paymentMethods: paymentMethodsReducer,
  products: productsReducer,
  subscriptions: subscriptionsReducer,
  diagnostics: diagnosticsReducer,
  calls: callsReducer,
})

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)))

export default store
