import styled from 'styled-components/macro'
import { Color } from '../../../utils/constants'

export const Container = styled.div`
  margin-right: 20px;
  background-color: ${props => (props.added ? Color.COLOR_SECONDARY : 'white')};
  border: ${props => (props.added ? `1.6px solid ${Color.DEEP_SEA}` : '')};
  display: inline-block;
  padding: 15px 15px;
  border-radius: 1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  font-size: 14px;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.1);
  width: 170px;
  &:hover {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  }
`

export const Img = styled.img`
  width: 70px;
  height: auto;
  object-fit: contain;
  border-radius: 100%;
  margin-bottom: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const ImgWrapper = styled.div`
  width: 100px;
  min-height: 100px;
  border-radius: 100px;
  background-color: #eee;
  position: relative;
  margin-bottom: 15px;
  cursor: pointer;
`

export const Text = styled.div`
  font-size: 12px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
export const Description = styled.p`
  margin-bottom: 6px;
`
export const Flex = styled.h4`
  display: flex;
  justify-content: space-between;
`
export const Title = styled.h4``

export const Price = styled.span``

export const MoreInfo = styled.p`
  cursor: pointer;
  text-decoration: underline;
`
