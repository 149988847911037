import React from 'react'
import Collapsible from 'react-collapsible'
import { Container, Wrapper, Title } from './style'
import { MdKeyboardArrowDown } from 'react-icons/md'

const Faq = () => {
  return (
    <Container>
      <Title>Questions fréquentes</Title>
      <Wrapper>
        <Collapsible
          transitionTime={200}
          trigger={
            <div>
              <p>A quoi sert un compte Compliment ?</p>{' '}
              <span>
                <MdKeyboardArrowDown />
              </span>
            </div>
          }
        >
          <p>
            Le compte Compliment vous permet de gérer votre abonnement. Vous
            pouvez y modifier le contenu de votre programme personnalisée,
            modifier votre moyen de paiement ou encore modifier votre date de
            renouvellement de commande.
          </p>
        </Collapsible>
        <Collapsible
          transitionTime={200}
          trigger={
            <div>
              <p>Je n'arrive pas à me connecter à mon compte.</p>{' '}
              <span>
                <MdKeyboardArrowDown />
              </span>
            </div>
          }
        >
          <p>Pas de panique! Voici les étapes à suivre:</p>
          <p>
            1. Assurez-vous que vous utilisez bien l'email avec lequel vous avez
            passé commande.
          </p>
          <p>
            2. Si vous ne recevez pas l'email de réinitialisation de mot de
            passe , c'est que vous n'avez jamais confirmé votre compte. Il vous
            suffit de retrouver l'email qui vous a été envoyé lors de votre
            première commande et de cliquer sur le lien pour confirmer votre
            compte. Si vous ne retrouvez pas cet email, veuillez contacter notre
            support, nous vous le renverrons dans les plus brefs délais.
          </p>
        </Collapsible>
      </Wrapper>
    </Container>
  )
}

export default Faq
