import React from 'react'
import PropTypes from 'prop-types'

import { Icon } from './style'
import {
  IconAngleRight,
  IconArrowLeft,
  IconCancel,
  IconCheck,
  IconComment,
  IconCommentReversed,
  IconInfo,
  IconLoading,
  IconMenu,
  IconPencil,
  IconPlus,
  IconSearch,
  IconShipping,
  IconTrash,
  IconCart,
  IconArrowRight,
  IconDiagnostic,
  IconNutritionist,
  IconProfil,
  IconProgram,
} from './icons'

const icons = {
  'angle-right': <IconAngleRight />,
  'arrow-left': <IconArrowLeft />,
  'arrow-right': <IconArrowRight />,
  'comment-reversed': <IconCommentReversed />,
  cancel: <IconCancel />,
  check: <IconCheck />,
  comment: <IconComment />,
  info: <IconInfo />,
  loading: <IconLoading />,
  menu: <IconMenu />,
  pencil: <IconPencil />,
  plus: <IconPlus />,
  search: <IconSearch />,
  shipping: <IconShipping />,
  trash: <IconTrash />,
  cart: <IconCart />,
  diagnostic: <IconDiagnostic />,
  nutritionist: <IconNutritionist />,
  profil: <IconProfil />,
  program: <IconProgram />,
}

const IconEl = ({ name, ...props }) => <Icon {...props}>{icons[name]}</Icon>

IconEl.propTypes = {
  /**
   * The icon name
   */
  name: PropTypes.oneOf(Object.keys(icons)).isRequired,
  color: PropTypes.string,
}

IconEl.defaultProps = {
  color: undefined,
}

export default IconEl
