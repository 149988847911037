import React, { useState } from 'react'
import {
  Container,
  Img,
  Text,
  Title,
  Description,
  Price,
  Flex,
  ImgWrapper,
  MoreInfo,
} from './style'
import SideModalProduct from '../../product/side-modal-product'

const RecommendedProduct = ({ item }) => {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const recoproductwhy =
    item.shopify_product.shopify_product.metafields.edges.filter(
      i => i.node.key === 'recoproductwhy'
    )[0].node.value

  const productpicture = JSON.parse(
    item?.shopify_product?.shopify_product?.metafields?.edges?.filter(
      i => i?.node?.key === 'productpicture'
    )[0]?.node?.value
  )[0]?.original_src

  return (
    <Container>
      <SideModalProduct
        handle={item?.shopify_product?.shopify_product?.handle}
        isOpen={isOpenModal}
        onClose={() => setIsOpenModal(false)}
      />
      <ImgWrapper onClick={() => setIsOpenModal(true)}>
        <Img src={productpicture} />
      </ImgWrapper>
      <Text>
        <Description>{recoproductwhy}</Description>
        <MoreInfo onClick={() => setIsOpenModal(true)}>Plus d'info</MoreInfo>
        <Flex>
          <Title>{item?.shopify_product?.shopify_product?.title}</Title>
          <Price>9€/mois</Price>
        </Flex>
      </Text>
    </Container>
  )
}

export default RecommendedProduct
