import { Link } from 'react-router-dom'

import Layout from '../../components/layout/default'
import InitializeForm from '../../components/auth/initialize-form'
import { Wrapper, Title, SwitchButton, Button } from './style'
import Faq from '../../components/auth/faq'

const InitializePage = ({ location }) => (
  <Layout>
    <Title>Se connecter</Title>

    <SwitchButton>
      <Button actif>J'ai déja un compte</Button>

      <Link
        to={{
          pathname: '/register',
          search: location.search,
        }}
      >
        <Button>Céer un compte</Button>
      </Link>
    </SwitchButton>
    <Wrapper>
      <InitializeForm />
    </Wrapper>

    <Faq />
  </Layout>
)

export default InitializePage
