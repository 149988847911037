import React, { useState } from 'react'
import { BsPlusCircleFill } from 'react-icons/bs'
import { AiOutlineDelete, AiOutlineLoading } from 'react-icons/ai'
import { PromoCode, LoadingIcon } from './style'
import { useQuery, useMutation } from 'react-query'
import { getDiscount } from '../../../../service/ReCharge/discount'
import axios from 'axios'
import { toast } from 'react-toastify'

const PromoCodeLine = ({ subscription }) => {
  // Attribites
  const [promoCodeHandle, setPromoCodeHandle] = useState('')
  const { data: discount } = useQuery(
    ['DISCOUNT', subscription?.address?.discount_id],
    () => getDiscount(subscription?.address?.discount_id),
    {
      enabled: !!subscription?.address?.discount_id,
    }
  )

  // Functions
  function reload() {
    window.location.reload(false)
  }

  const removeDiscount = useMutation(
    async addresseId =>
      await axios.post('https://www.compliment.me/api/removeDiscount', {
        addresseId,
      }),
    {
      onSuccess: () => {
        reload()
      },
    }
  )

  const applydiscountToAnAddress = useMutation(
    async payload =>
      await axios.post(
        'https://www.compliment.me/api/applydiscountToAnAddress',
        {
          addresseId: payload.addresseId,
          discountCode: payload.discountCode,
        }
      ),
    {
      onSuccess: () => {
        reload()
      },
      onError: () => {
        toast.error('Le code de promo est non valide')
      },
    }
  )

  // Render
  return (
    <>
      <span>Code promo</span>
      <PromoCode>
        {!subscription?.address?.discount_id ? (
          <>
            <input
              placeholder='Ajouter un code'
              value={promoCodeHandle}
              onChange={e => setPromoCodeHandle(e.target.value)}
            />
            {!applydiscountToAnAddress.isLoading ? (
              <button
                onClick={e => {
                  e.preventDefault()
                  applydiscountToAnAddress.mutate({
                    discountCode: promoCodeHandle,
                    addresseId: subscription?.address?.id,
                  })
                }}
              >
                <BsPlusCircleFill />
              </button>
            ) : (
              <LoadingIcon>
                <span>
                  <AiOutlineLoading />
                </span>
              </LoadingIcon>
            )}
          </>
        ) : (
          <>
            <p>{discount?.data?.discount?.code}</p>
            {!removeDiscount.isLoading ? (
              <button
                onClick={e => {
                  e.preventDefault()
                  removeDiscount.mutate(subscription?.address?.id)
                  setPromoCodeHandle('')
                }}
              >
                <AiOutlineDelete />
              </button>
            ) : (
              <LoadingIcon>
                <span>
                  <AiOutlineLoading />
                </span>
              </LoadingIcon>
            )}
          </>
        )}
      </PromoCode>
      <hr></hr>
    </>
  )
}

export default PromoCodeLine
