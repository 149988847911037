import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLazyQuery } from '@apollo/client'
import { toast } from 'react-toastify'

import { ActionType } from '../utils/constants'
import {
  fetchLoading,
  fetchSuccess,
  fetchFailed,
  // getOrders,
  getFetchOrdersStatus,
  // getFetchOrdersError,
} from '../store/orders'
import { getShopifyToken, getAuthUser } from '../store/auth-user'
// import { getRechargeToken } from '../store/auth-user'
import { USER_ORDERS } from '../service/queries'

// export const useOrders = () => {
//   const list = useSelector(getOrders)

//   return { list }
// }

// export const useFetchOrders = () => {
//   const dispatch = useDispatch()
//   const fetchStatus = useSelector(getFetchOrdersStatus)
//   const fetchError = useSelector(getFetchOrdersError)
//   const token = useSelector(getRechargeToken)

//   return {
//     error: fetchError,
//     // fetch: () => dispatch(fetchAll(token)),
//     isError: fetchStatus === ActionType.FAILED,
//     isLoading: fetchStatus === ActionType.LOADING,
//     isSuccess: fetchStatus === ActionType.SUCCESS,
//   }
// }

export const useOrders = () => {
  const dispatch = useDispatch()
  const shopifyToken = useSelector(getShopifyToken)
  const fetchStatus = useSelector(getFetchOrdersStatus)
  const authUser = useSelector(getAuthUser)

  //   const authToken = useSelector(getUserToken)
  //   const context = authToken
  //     ? { headers: { Authorization: `Bearer ${authToken}` } }
  //     : undefined
  const [
    fetchorders,
    { data: ordersData, loading: ordersLoading, error: ordersError },
  ] = useLazyQuery(
    USER_ORDERS
    //     {
    //      headers: { Authorization: `Bearer URwR7SqiHkwscqDaBlYugZS2uRSUQZNXlI52KilXsrhdRuRa1rpXYIfPOQltNgqJ` },
    //    }
  )

  useEffect(() => {
    fetchStatus !== ActionType.LOADING &&
      fetchorders({ variables: { customerAccessToken: shopifyToken } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * Sync fetchAuthPrescriber with store
   */
  useEffect(() => {
    ordersLoading &&
      fetchStatus !== ActionType.LOADING &&
      dispatch(fetchLoading())
  }, [ordersLoading, dispatch, fetchStatus])
  useEffect(() => {
    ordersData &&
      fetchStatus !== ActionType.SUCCESS &&
      dispatch(fetchSuccess(ordersData))
  }, [ordersData, dispatch, fetchStatus])
  useEffect(() => {
    if (ordersError && fetchStatus !== ActionType.FAILED) {
      dispatch(fetchFailed(ordersError))
      toast.error(
        "Une erreur s'est produite lors de la récupération des commandes, réessayez plus tard"
      )
    }
  }, [ordersError, dispatch, fetchStatus])
  return {
    fetchorders: fetchorders,
    data: ordersData ? ordersData?.customer?.orders?.edges : [],
    failed: fetchStatus === ActionType.FAILED,
    loading: fetchStatus === ActionType.LOADING,
    success: fetchStatus === ActionType.SUCCESS,
  }
}
