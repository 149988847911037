import { useState } from 'react'
import { Container, Header, Nav, Title, NavLink } from '../../style'
import MyDiagnosis from '../my-diagnosis/MyDiagnosis'
import DiagnosisHistory from '../diagnosis-history/DiagnosisHistory'
import DialogDignostic from '../dialog-dignostic'
import { useAuth } from '../../../hooks'

const DiagnosisSection = () => {
  const [nav, setNav] = useState(1)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const { authUser } = useAuth()

  return (
    <Container>
      <Header>
        <Title>Le profil de {authUser?.firstName}</Title>
        <Nav>
          <NavLink onClick={() => setNav(1)} active={nav === 1}>
            Mon dernier diagnostic
            {nav === 1 && <span></span>}
          </NavLink>
          <NavLink onClick={() => setNav(2)} active={nav === 2}>
            Mon historique de diagnostics
            {nav === 2 && <span></span>}
          </NavLink>
        </Nav>
      </Header>

      {nav === 1 && <MyDiagnosis setIsDialogOpen={setIsDialogOpen} />}
      {nav === 2 && <DiagnosisHistory setIsDialogOpen={setIsDialogOpen} />}

      <DialogDignostic
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
      />
    </Container>
  )
}

export default DiagnosisSection
