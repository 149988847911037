import styled from 'styled-components/macro'
import { Color } from '../../../../utils/constants'

export const Container = styled.div`
  padding: 80px 30px 0px 30px;
`

export const IconProfile = styled.span`
  margin-right: 8px;
  margin-top: 5px;
  font-size: 30px;
`
export const Name = styled.span`
  position: absolute;
  top: 15px;
  right: 30px;
  display: flex;
  align-items: center;
  font-size: 20px;
  opacity: 0.7;
`
export const Notifications = styled.span`
  margin-left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: ${Color.ORANGE};
`
export const TextLinkWrapper = styled.div`
  display: flex;
  align-items: center;
`
