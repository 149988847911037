import styled from 'styled-components/macro'

import { Font } from '../utils/constants'

export { default as GlobalStyle } from './GlobalStyle'
export { default as ThemeProvider } from './ThemeProvider'

export const BaseText = styled.p`
  font-family: ${Font.BASIS_GROTESQUE_PRO};
  font-weight: 300;
  font-size: 16px;
  line-height: 120%;
  color: rgb(72, 72, 72);
`

export const BaseHeading = styled.h1`
  font-family: ${Font.BASIS_GROTESQUE_PRO};
  font-weight: 600;
  font-size: 32px;
  line-height: 125%;
  color: rgb(72, 72, 72);
`
