import styled from 'styled-components/macro'

import { Color, MediaQuery } from '../../../utils/constants'

export const Header = styled.header`
  display: flex;
  width: 100%;
  /* width: 1280px; */
  margin: 0 auto;
  height: 75px;
  min-height: 75px;
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.2);
  position: sticky;
  top: 0;
  padding: 0 20px;
  background-color: white;
  z-index: 10;
  ${MediaQuery.XL_AND_DOWN} {
    width: 100%;
  }
  ${MediaQuery.L_AND_DOWN} {
    display: none;
  }
`

export const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  width: 100%;
  height: 100%;
`

export const UlLeft = styled.ul`
  display: flex;
  justify-content: flex-start;
  height: 100%;
  width: 40%;
  list-style: none;
  margin: 0;
  padding: 0 20px;
  box-sizing: border-box;
  /* background-color: red; */
`
export const UlRight = styled.ul`
  display: flex;
  justify-content: flex-end;
  height: 100%;
  width: 40%;
  list-style: none;
  margin: 0;
  padding: 0 20px;
  box-sizing: border-box;
  /* background-color: blue; */
`

export const Li = styled.li`
  display: flex;
  align-items: center;
  height: 100%;
  box-sizing: border-box;
  padding: 10px 0;

  > a {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    height: 100%;
    color: black;
    text-decoration: none;
    padding: 0 20px;
    border-radius: 5px;
    font-size: 14px;
    line-height: 18px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
`

export const Button = styled.button`
  background-color: #fd5b25;
  border: none;
  padding: 12px 35px;
  border-radius: 30px;
  > a {
    color: white;
    text-decoration: none;
  }
`

export const PopupNav = styled.div`
  height: 200px;
`

export const QuestionIcon = styled.span`
  font-size: 20px;
`
export const ProfileIcon = styled.p`
  font-size: 20px;
  margin-right: 10px;
`
export const ArrowDownIcon = styled.span`
  font-size: 20px;
  margin-left: 10px;
  transition: all 0.1s ease-in;
`
export const NavLinkProfileRound = styled.div`
  opacity: 0.5;
  padding: 6px 10px;
  height: 35px;
  border: 1px solid black;
  border-radius: 30px;
  display: flex;
  align-items: center;
  line-height: 50%;
  cursor: pointer;
  &:hover span {
    transform: rotate(-180deg);
  }
`

export const Name = styled.p``

export const CartIconWrapper = styled.div`
  font-size: 20px;
  position: relative;
`
export const CartIconNotif = styled.span`
  position: absolute;
  top: -9px;
  right: -12px;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background-color: ${Color.ORANGE};
  color: white;
  font-size: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const OrderButton = styled.a``
