import React from 'react'
import ReactDOM from 'react-dom'
import { ApolloClient, InMemoryCache } from '@apollo/client'
// import { createHttpLink } from 'apollo-link-http'
// import { setContext } from 'apollo-link-context'
// import { InMemoryCache } from 'apollo-cache-inmemory'
// import { ApolloProvider } from 'react-apollo'
import { ApolloProvider } from '@apollo/client/react'
import { Provider } from 'react-redux'
import { QueryClient, QueryClientProvider } from 'react-query'
// import { ReactQueryDevtools } from 'react-query-devtools'

import App from './App'
import store from './store'
import { Font } from './utils/constants'
import { ToastContainer } from './components/ui'
import { ThemeProvider, GlobalStyle } from './style'
import basisGrotesqueProEOTUrl from './assets/fonts/BasisGrotesquePro-Medium.eot'
import basisGrotesqueProSVGUrl from './assets/fonts/BasisGrotesquePro-Medium.svg'
import basisGrotesqueProTTFUrl from './assets/fonts/BasisGrotesquePro-Medium.ttf'
import basisGrotesqueProWOFF2Url from './assets/fonts/BasisGrotesquePro-Medium.woff2'
import basisGrotesqueProWOFFUrl from './assets/fonts/BasisGrotesquePro-Medium.woff'

// const httpLink = createHttpLink({ uri: process.env.REACT_APP_API_URL })
// const middlewareLink = setContext(() => ({
//   headers: {
//     'X-Shopify-Storefront-Access-Token':
//       process.env.REACT_APP_SHOPIFY_STOREFRONT_ACCESS_TOKEN,
//   },
// }))
const client = new ApolloClient({
  // link: middlewareLink.concat(httpLink),
  uri: process.env.REACT_APP_API_URL,
  cache: new InMemoryCache(),
  headers: {
    'x-hasura-admin-secret': `${process.env.REACT_APP_HASURA_SECRET}`,
  },
})

const loadFonts = () => {
  const head = document.getElementsByTagName('head')[0]
  const style = document.createElement('style')
  const fontFace = `
    @font-face {
      font-family: ${Font.BASIS_GROTESQUE_PRO};
      src: url('${basisGrotesqueProEOTUrl}');
      src: url('${basisGrotesqueProEOTUrl}?#iefix') format('embedded-opentype'),
        url('${basisGrotesqueProWOFF2Url}') format('woff2'),
        url('${basisGrotesqueProWOFFUrl}') format('woff'),
        url('${basisGrotesqueProTTFUrl}') format('truetype'),
        url('${basisGrotesqueProSVGUrl}#BasisGrotesquePro-Medium') format('svg');
      font-weight: 500;
      font-style: normal;
      font-display: swap;
    }
  `
  style.innerHTML = fontFace
  head.appendChild(style)
}

const queryClient = new QueryClient()

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider>
      <GlobalStyle />
      <QueryClientProvider client={queryClient}>
        <ApolloProvider client={client}>
          <ToastContainer />
          <App />
        </ApolloProvider>
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </QueryClientProvider>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root'),
  loadFonts
)
