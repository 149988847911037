import React from 'react'

import {
  Header,
  Nav,
  UlLeft,
  UlRight,
  Li,
  NavLinkProfileRound,
  Name,
  ProfileIcon,
  ArrowDownIcon,
  OrderButton,
} from './style'
import { Logo, NavLink, Dropdown } from '../../ui'
import PopupNavProfileContent from './PopupNavProfileContent'
import { CgProfile } from 'react-icons/cg'
import { IoIosArrowDown } from 'react-icons/io'
import { useAuth } from '../../../hooks'

const MainHeaderEl = () => {
  const { authUser } = useAuth()
  return (
    <Header>
      <Nav>
        <UlLeft>
          {authUser?.firstName && (
            <NavLink href='/settings/profile' uppercase={true}>
              Mes informations
            </NavLink>
          )}
          {!authUser?.firstName && (
            <a href='https://www.compliment-pro.com/patient'>
              <Logo />
            </a>
          )}
        </UlLeft>

        {authUser?.firstName && (
          <NavLink href='/'>
            <Logo />
          </NavLink>
        )}
        <UlRight>
          {!authUser?.firstName && (
            <>
              <Li>
                <a href='https://www.compliment-pro.com/patient'>
                  Espace patient
                </a>
              </Li>
              <Li>
                <OrderButton href='https://app.compliment.me/patient-catalog'>
                  Commander
                </OrderButton>
              </Li>
            </>
          )}
          {authUser?.firstName ? (
            <Dropdown content={<PopupNavProfileContent />}>
              <Li>
                <NavLinkProfileRound>
                  <ProfileIcon>
                    <CgProfile />
                  </ProfileIcon>
                  <Name>{authUser?.firstName}</Name>
                  <ArrowDownIcon>
                    <IoIosArrowDown />
                  </ArrowDownIcon>
                </NavLinkProfileRound>
              </Li>
            </Dropdown>
          ) : (
            ''
          )}
        </UlRight>
      </Nav>
    </Header>
  )
}

export default MainHeaderEl
