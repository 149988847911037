import styled from 'styled-components/macro'
import { MediaQuery } from '../../../utils/constants'

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 280px;
  ${MediaQuery.S_AND_DOWN} {
    min-width: 95%;
    margin: 0 auto;
  }
  > button {
    margin-top: 20px;
  }
`
