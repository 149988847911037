import Constants from './constants'

export const fetchLoading = () => ({
  type: Constants.FETCH_DIAGNOSTICS_LOADING,
})
export const fetchSuccess = payload => ({
  type: Constants.FETCH_DIAGNOSTICS_SUCCESS,
  payload,
})
export const fetchFailed = error => ({
  type: Constants.FETCH_DIAGNOSTICS_FAILED,
  error,
})

export const fetchLastDiagnosticLoading = () => ({
  type: Constants.FETCH_LAST_DIAGNOSTICS_LOADING,
})
export const fetchLastDiagnosticSuccess = payload => ({
  type: Constants.FETCH_LAST_DIAGNOSTICS_SUCCESS,
  payload,
})
export const fetchLastDiagnosticFailed = error => ({
  type: Constants.FETCH_LAST_DIAGNOSTICS_FAILED,
  error,
})
