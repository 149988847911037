import React, { useState } from 'react'

import PopupNavMobileContent from './PopupNavMobileContent'
import PopupShopNavMobileContent from './PopupShopNavMobileContent'
import PopupAboutNavMobileContent from './PopupAboutNavMobileContent'
import PopupFaqNavMobileContent from './PopupFaqNavMobileContent'

import {
  Header,
  Nav,
  UlLeft,
  UlRight,
  Li,
  HamburgerIcon,
  HamburgerIconLabel,
  HamburgerIconlInput,
  HamburgerIconSpan,
  Dialog,
  // CartIconWrapper,
  // CartIconNotif,
} from './style'
// import { useSpring, animated } from 'react-spring'
import { Logo, NavLink } from '../../ui'
// import { RiShoppingCartLine } from 'react-icons/ri'
import { useAuth } from '../../../hooks'
import { navDisplay } from '../../../utils/constants'

const MainHeaderEl = () => {
  const { authUser } = useAuth()
  const [isDialogOpen, setDialogOpen] = useState(false)
  const [navBarContent, setNavBarContent] = useState(navDisplay.MAINNAV)

  // const useSpringNav = nav => {
  //   return useSpring({
  //     to: {
  //       opacity: navBarContent === nav ? 1 : 0,
  //       transform: navBarContent === nav ? 'translateX(0)' : 'translateX(110%)',
  //       display: navBarContent === nav ? 'block' : 'none',
  //     },
  //     config: { duration: 200 },
  //   })
  // }

  return (
    <Header>
      <Nav>
        <UlLeft>
          <Li>
            <HamburgerIcon
              style={{
                display:
                  navBarContent === navDisplay.MAINNAV ? 'block' : 'none',
              }}
            >
              <HamburgerIconLabel for='check'>
                <HamburgerIconlInput
                  onClick={() => setDialogOpen(isDialogOpen => !isDialogOpen)}
                  type='checkbox'
                  id='check'
                />
                <HamburgerIconSpan></HamburgerIconSpan>
                <HamburgerIconSpan></HamburgerIconSpan>
                <HamburgerIconSpan></HamburgerIconSpan>
              </HamburgerIconLabel>
            </HamburgerIcon>
          </Li>
        </UlLeft>

        {authUser?.firstName && (
          <NavLink href='https://compliment.me'>
            <Logo mobile />
          </NavLink>
        )}
        {!authUser?.firstName && (
          <NavLink href='https://www.compliment-pro.com/patient'>
            <Logo mobile />
          </NavLink>
        )}
        <UlRight>
          <Li>
            {/* <NavLink>
              <CartIconWrapper>
                <CartIconNotif>1</CartIconNotif>
                <RiShoppingCartLine />
              </CartIconWrapper>
            </NavLink> */}
          </Li>
        </UlRight>
      </Nav>

      <Dialog isOpen={isDialogOpen}>
        {/* {selectNavBarContent(navBarContent)} */}

        <div
          style={{
            display: navBarContent === navDisplay.MAINNAV ? 'block' : 'none',
          }}
        >
          <PopupNavMobileContent
            setNavBarContent={setNavBarContent}
            firstName={authUser?.firstName}
          />
        </div>

        <div
          style={{
            display: navBarContent === navDisplay.SHOPNAV ? 'block' : 'none',
          }}
        >
          <PopupShopNavMobileContent setNavBarContent={setNavBarContent} />
        </div>
        <div
          style={{
            display: navBarContent === navDisplay.ABOUTNAV ? 'block' : 'none',
          }}
        >
          <PopupAboutNavMobileContent setNavBarContent={setNavBarContent} />
        </div>
        <div
          style={{
            display: navBarContent === navDisplay.FAQNAV ? 'block' : 'none',
          }}
        >
          <PopupFaqNavMobileContent setNavBarContent={setNavBarContent} />
        </div>
      </Dialog>
    </Header>
  )
}

export default MainHeaderEl
