import cloneDeep from 'lodash.clonedeep'

import Constant from './constants'
import initialState from './state'
import { ActionType } from '../../utils/constants'

const fetchFailed = (state, action) => {
  const newState = cloneDeep(state)
  newState.actions.fetch = { status: ActionType.FAILED, error: action.error }

  return newState
}
const fetchLoading = state => {
  const newState = cloneDeep(state)
  newState.actions.fetch = { status: ActionType.LOADING }

  return newState
}
const fetchSuccess = (state, { payload }) => {
  const newState = cloneDeep(state)
  newState.list = [...payload.payment_methods]
  newState.actions.fetch = { status: ActionType.SUCCESS }

  return newState
}

const updatePaymentMethodLoading = state => {
  const newState = cloneDeep(state)
  newState.actions.updatePaymentMethod = {
    status: ActionType.LOADING,
  }

  return newState
}
const updatePaymentMethodSuccess = (state, { payload }) => {
  const newState = cloneDeep(state)
  newState.list[0].card_last4 = payload.data.payment_details.last4
  newState.list[0].card_brand = payload.data.payment_details.brand
  newState.list[0].card_exp_month = payload.data.payment_details.exp_month
  newState.list[0].card_exp_year = payload.data.payment_details.exp_year
  newState.actions.updatePaymentMethod = {
    status: ActionType.SUCCESS,
  }

  return newState
}
const updatePaymentMethodFailed = (state, action) => {
  const newState = cloneDeep(state)
  newState.actions.updatePaymentMethod = {
    status: ActionType.FAILED,
    error: action.error,
  }

  return newState
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Constant.FETCH_PAYMENT_METHODS_FAILED:
      return fetchFailed(state, action)
    case Constant.FETCH_PAYMENT_METHODS_LOADING:
      return fetchLoading(state)
    case Constant.FETCH_PAYMENT_METHODS_SUCCESS:
      return fetchSuccess(state, action)
    case Constant.UPDATE_PAYMENT_METHODS_FAILED:
      return updatePaymentMethodFailed(state, action)
    case Constant.UPDATE_PAYMENT_METHODS_LOADING:
      return updatePaymentMethodLoading(state)
    case Constant.UPDATE_PAYMENT_METHODS_SUCCESS:
      return updatePaymentMethodSuccess(state, action)

    default:
      return state
  }
}
