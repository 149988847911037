import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLazyQuery } from '@apollo/client'
import { toast } from 'react-toastify'

import { ActionType } from '../utils/constants'
import {
  fetchLoading,
  fetchSuccess,
  fetchFailed,
  getFetchDiagnosticStatus,
  fetchLastDiagnosticLoading,
  fetchLastDiagnosticSuccess,
  fetchLastDiagnosticFailed,
  getFetchLastDiagnosticStatus,
} from '../store/diagnostics'
import { getAuthUser } from '../store/auth-user'
import { USER_DIAGNOSTIC, USER_LAST_DIAGNOSTIC } from '../service/queries'
// import { getUserToken } from '../store/auth-user'

export const useDiagnostics = () => {
  const dispatch = useDispatch()
  const auth = useSelector(getAuthUser)
  const fetchDiagnosticsStatus = useSelector(getFetchDiagnosticStatus)
  //   const authToken = useSelector(getUserToken)
  //   const context = authToken
  //     ? { headers: { Authorization: `Bearer ${authToken}` } }
  //     : undefined
  const [
    fetchDiagnostics,
    {
      data: diagnosticsData,
      loading: diagnosticsLoading,
      error: diagnosticsError,
    },
  ] = useLazyQuery(
    USER_DIAGNOSTIC
    //     {
    //      headers: { Authorization: `Bearer URwR7SqiHkwscqDaBlYugZS2uRSUQZNXlI52KilXsrhdRuRa1rpXYIfPOQltNgqJ` },
    //    }
  )

  useEffect(() => {
    fetchDiagnosticsStatus !== ActionType.LOADING &&
      fetchDiagnostics({ variables: { email: auth?.email } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * Sync fetchAuthPrescriber with store
   */
  useEffect(() => {
    diagnosticsLoading &&
      fetchDiagnosticsStatus !== ActionType.LOADING &&
      dispatch(fetchLoading())
  }, [diagnosticsLoading, dispatch, fetchDiagnosticsStatus])
  useEffect(() => {
    diagnosticsData &&
      fetchDiagnosticsStatus !== ActionType.SUCCESS &&
      dispatch(fetchSuccess(diagnosticsData))
  }, [diagnosticsData, dispatch, fetchDiagnosticsStatus])
  useEffect(() => {
    if (diagnosticsError && fetchDiagnosticsStatus !== ActionType.FAILED) {
      dispatch(fetchFailed(diagnosticsError))
      toast.error(
        "Une erreur s'est produite lors de la récupération des diagnostics, réessayez plus tard"
      )
    }
  }, [diagnosticsError, dispatch, fetchDiagnosticsStatus])
  return {
    fetchDiagnostics: fetchDiagnostics,
    data: diagnosticsData,
    failed: fetchDiagnosticsStatus === ActionType.FAILED,
    loading: fetchDiagnosticsStatus === ActionType.LOADING,
    success: fetchDiagnosticsStatus === ActionType.SUCCESS,
  }
}

export const useFetchLastDiagnostic = () => {
  const dispatch = useDispatch()
  const auth = useSelector(getAuthUser)
  const fetchLastDiagnosticsStatus = useSelector(getFetchLastDiagnosticStatus)
  //   const authToken = useSelector(getUserToken)
  //   const context = authToken
  //     ? { headers: { Authorization: `Bearer ${authToken}` } }
  //     : undefined
  const [
    fetchLastDiagnostics,
    {
      data: lastDiagnosticData,
      loading: lastDiagnosticsLoading,
      error: lastDiagnosticsError,
    },
  ] = useLazyQuery(
    USER_LAST_DIAGNOSTIC
    //     {
    //      headers: { Authorization: `Bearer URwR7SqiHkwscqDaBlYugZS2uRSUQZNXlI52KilXsrhdRuRa1rpXYIfPOQltNgqJ` },
    //    }
  )

  useEffect(() => {
    fetchLastDiagnosticsStatus !== ActionType.LOADING &&
      fetchLastDiagnostics({ variables: { email: auth?.email } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * Sync fetchAuthPrescriber with store
   */
  useEffect(() => {
    lastDiagnosticsLoading &&
      fetchLastDiagnosticsStatus !== ActionType.LOADING &&
      dispatch(fetchLastDiagnosticLoading())
  }, [lastDiagnosticsLoading, dispatch, fetchLastDiagnosticsStatus])
  useEffect(() => {
    lastDiagnosticData &&
      fetchLastDiagnosticsStatus !== ActionType.SUCCESS &&
      dispatch(fetchLastDiagnosticSuccess(lastDiagnosticData))
  }, [lastDiagnosticData, dispatch, fetchLastDiagnosticsStatus])
  useEffect(() => {
    if (
      lastDiagnosticsError &&
      fetchLastDiagnosticsStatus !== ActionType.FAILED
    ) {
      dispatch(fetchLastDiagnosticFailed(lastDiagnosticsError))
      toast.error(
        "Une erreur s'est produite lors de la récupération des diagnostics, réessayez plus tard"
      )
    }
  }, [lastDiagnosticsError, dispatch, fetchLastDiagnosticsStatus])
  return {
    fetchLastDiagnostics: fetchLastDiagnostics,
    data: lastDiagnosticData ? lastDiagnosticData.diagnostic[0] : [],
    failed: fetchLastDiagnosticsStatus === ActionType.FAILED,
    loading: fetchLastDiagnosticsStatus === ActionType.LOADING,
    success: fetchLastDiagnosticsStatus === ActionType.SUCCESS,
  }
}
