import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

import {
  Wrapper,
  FooterText,
  Title,
  SuccessRegistrationContainer,
  SwitchButton,
  Button,
} from './style'
import Layout from '../../components/layout/default'
import RegisterForm from '../../components/auth/register-form'
import { getRegisterStatus } from '../../store/auth-user'
import { ActionType } from '../../utils/constants'
import Faq from '../../components/auth/faq'

const RegisterPage = ({ location }) => {
  const registerStatus = useSelector(getRegisterStatus)

  return (
    <Layout>
      <Title>Créer un nouveau compte</Title>
      <SwitchButton>
        <Link
          to={{
            pathname: '/login',
            search: location.search,
          }}
        >
          <Button>J'ai déja un compte</Button>
        </Link>

        <Button actif>Céer un compte </Button>
      </SwitchButton>
      <Wrapper>
        {registerStatus !== ActionType.SUCCESS ? (
          <>
            <RegisterForm />
            <FooterText>
              <Link
                to={{
                  pathname: '/initialize',
                  search: location.search,
                }}
              >
                Mot de passe oublié ?
              </Link>
            </FooterText>
          </>
        ) : (
          <SuccessRegistrationContainer>
            🙌
            <br />
            Votre compte à été créé avec succès <br />
            <br />
            Vérifiez vos emails afin de confirmer votre email et connectez vous
            ensuite.
            <br />
            <br />
            <Link
              to={{
                pathname: '/login',
                search: location.search,
              }}
            >
              Se connecter
            </Link>
          </SuccessRegistrationContainer>
        )}
      </Wrapper>
      <Faq />
    </Layout>
  )
}

export default RegisterPage
