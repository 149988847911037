import React, { useEffect, useState } from 'react'
import {
  ContentFlex,
  Informations,
  //  PersonalizedName
} from './style'
import {
  Header,
  HeaderTitle,
  EditIcon,
  HeaderRight,
  Wrapper,
  Hr,
} from '../style'
import DeliveryAdressList from './delivery-adress-list'
import EditPersonalInfoModal from '../edit-personal-info-modal'
import { Button, Spinner } from '../../ui'
import { AiOutlineEdit } from 'react-icons/ai'
import {
  useSubscriptions,
  useFetchSubscriptions,
  useCustomer,
} from '../../../hooks'
import { useSelector } from 'react-redux'
import { getCustomerShopify } from '../../../store/auth-user'

const MyPersonalInformation = () => {
  const { list } = useSubscriptions()
  const { fetch, isLoading, isSuccess, isError } = useFetchSubscriptions()
  const { loading: isCustomerLoading } = useCustomer()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const customer = useSelector(getCustomerShopify)

  useEffect(() => {
    !list.length && !isLoading && !isSuccess && !isError && fetch()
  }, [fetch, isError, isLoading, isSuccess, list.length])

  return (
    <>
      <Wrapper>
        <Header>
          <HeaderTitle>
            <h3>Mes informations personnelles</h3>
          </HeaderTitle>
          <HeaderRight>
            {/* <p>*informations obligatoires</p>
            <Button
              style={{
                padding: '0',
                opacity: '.6',
                textTransform: 'uppercase',
              }}
              appearance='underline'
              size='13px'
              onClick={() => setIsModalOpen(true)}
            >
              Modifier les informations
            </Button> */}
            {/* {customer && (
              <EditPersonalInfoModal
                firstName={customer?.firstName}
                lastName={customer?.lastName}
                email={customer?.email}
                onClose={() => setIsModalOpen(false)}
                isOpen={isModalOpen}
              />
            )}
            <EditIcon onClick={() => setIsModalOpen(true)}>
              <AiOutlineEdit />
            </EditIcon> */}
          </HeaderRight>
        </Header>
        <Hr></Hr>

        {isCustomerLoading ? (
          <Spinner />
        ) : (
          <ContentFlex>
            <Informations>
              <div>
                <label>Nom*</label>
                <p>{customer?.firstName}</p>
              </div>
              <div>
                <label>Prénom*</label>
                <p>{customer?.lastName}</p>
              </div>
              <div>
                <label>Email*</label>
                <p>{customer?.email}</p>
              </div>
              {/* <div>
              <label>Mot de passe*</label>
              <p>*********</p>
            </div> */}
            </Informations>
            {/* <PersonalizedName>
            <h4>Nouveau! Personnalisez votre nom sur votre cure</h4>
            <label>Prénom sur la box</label>
            <p>Tang</p>
          </PersonalizedName> */}
          </ContentFlex>
        )}
      </Wrapper>
      {isLoading ? (
        <Wrapper>
          <Header>
            <HeaderTitle>
              <h3>Mon adresse de livraison</h3>
            </HeaderTitle>
            <HeaderRight>
              <p>*informations obligatoires</p>
              <Button
                style={{
                  padding: '0',
                  opacity: '.6',
                  textTransform: 'uppercase',
                }}
                appearance='underline'
                size='13px'
              >
                Modifier les informations
              </Button>
              <EditIcon>
                <AiOutlineEdit />
              </EditIcon>
            </HeaderRight>
          </Header>
          <Hr></Hr>
          <Spinner />
        </Wrapper>
      ) : (
        <DeliveryAdressList list={list} />
      )}
    </>
  )
}

export default MyPersonalInformation
