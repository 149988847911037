import styled from 'styled-components/macro'
import { Color, MediaQuery } from '../../../utils/constants'
export const Container = styled.div``

export const Wrapper = styled.div`
  margin: 0px 60px 40px 60px;
  ${MediaQuery.L_AND_DOWN} {
    margin: 0px 15px 10px 15px;
  }
  background-color: white;
  padding: 30px 20px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);

  ${MediaQuery.M_AND_DOWN} {
    overflow-x: scroll;
  }
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 40px; */
  ${MediaQuery.S_AND_DOWN} {
    flex-direction: column;
    align-items: flex-start;
  }
`
export const Title = styled.h3`
  font-size: 19px;
  font-weight: 300;
  opacity: 0.6;
  ${MediaQuery.S_AND_DOWN} {
    margin-bottom: 10px;
  }
`
export const Hr = styled.hr`
  margin: 30px 0;
  border-top: 0.5px solid #eee;
`

export const Content = styled.div`
  /* padding: 80px 20px; */
`

export const EmptyData = styled.div`
  text-align: center;
  padding: 80px 20px;
`

export const EmptyDataTitle = styled.h3`
  margin-bottom: 20px;
  margin-bottom: 40px;
  font-weight: 300;
  font-size: 15px;
  opacity: 0.5;
  text-decoration: underline;
`

export const Table = styled.table`
  width: 100%;

  & > *:not(:first-child) {
    border-bottom: 2px dotted #999;
  }

  th {
    text-align: start;
    span {
      padding: 10px 20px;
      text-transform: uppercase;
      white-space: nowrap;
      background-color: ${Color.COLOR_SECONDARY};
      font-size: 12px;
      color: ${Color.DEEP_SEA};
      border-radius: 5px;
    }
  }
  th,
  td {
    padding: 20px 0px;
  }

  tr {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  tr > * {
    ${MediaQuery.M_AND_DOWN} {
      margin-right: 20px;
    }
  }
  button {
    a {
      color: inherit;
    }
  }
`

export const Goals = styled.td`
  display: flex;
  & > *:not(:last-child) {
    margin-right: 10px;
  }
  svg {
    width: 25px;
  }
`

export const Goal = styled.td`
  svg {
    width: 25px;
  }
`
