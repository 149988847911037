import LoginPage from '../pages/login'
import ProfilePage from '../pages/settings'
import RegisterPage from '../pages/register'
import InitializePage from '../pages/initialize'

const routes = [
  { c: LoginPage, path: '/login' },
  { c: ProfilePage, path: '/settings', auth: true, isExact: false },
  { c: RegisterPage, path: '/register' },
  { c: InitializePage, path: '/initialize' },
]

export default routes
