import styled from 'styled-components/macro'
import { MediaQuery } from '../../../utils/constants'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px dotted #999;
`
export const Img = styled.img`
  max-width: 70px;
  max-height: 70px;
  min-width: 70px;
  min-height: 70px;
  object-fit: contain;
  border-radius: 100%;
  margin-bottom: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const ImgWrapper = styled.div`
  margin-right: 10px;
  max-width: 80px;
  max-height: 80px;
  min-width: 80px;
  min-height: 80px;
  border-radius: 100px;
  background-color: #eee;
  position: relative;
  margin-bottom: 15px;
  ${MediaQuery.S_AND_DOWN} {
    max-width: 60px;
    max-height: 60px;
    min-width: 60px;
    min-height: 60px;
  }
`

export const Text = styled.div`
  width: ${props => (props.widthText ? props.widthText : '')};
  font-size: 15px;
  font-weight: 300;
  ${MediaQuery.S_AND_DOWN} {
    width: auto;
  }
`
export const Name = styled.h3`
  font-weight: 300;
  margin-bottom: 5px;
  line-height: 20px;
  ${MediaQuery.S_AND_DOWN} {
    font-size: 14px;
    margin-right: 5px;
  }
`
export const MoreInfo = styled.p`
  font-size: 14px;
  opacity: 0.6;
  margin-bottom: 5px;
  cursor: pointer;
  text-decoration: underline;
`
export const Detail = styled.p`
  font-size: 12px;
`
export const HandleQuantity = styled.div`
  select {
    border: none;
    outline: none;
  }
  span {
    margin-left: 5px;
    font-size: 12px;
    opacity: 0.5;
    ${MediaQuery.S_AND_DOWN} {
      display: none;
    }
  }
  ${MediaQuery.S_AND_DOWN} {
    margin-right: 5px;
  }
`
export const Price = styled.div`
  font-size: 14px;
  opacity: 0.7;
  font-weight: 300;
`
export const Remove = styled.div`
  span {
    cursor: ${props => (props.isLoading ? 'wait' : 'pointer')};
    font-size: 25px;
  }
`
