const Constants = {
  FETCH_DIAGNOSTICS_FAILED: `FETCH_DIAGNOSTICS_FAILED`,
  FETCH_DIAGNOSTICS_LOADING: `FETCH_DIAGNOSTICS_LOADING`,
  FETCH_DIAGNOSTICS_SUCCESS: `FETCH_DIAGNOSTICS_SUCCESS`,
  FETCH_LAST_DIAGNOSTICS_FAILED: `FETCH_LAST_DIAGNOSTICS_FAILED`,
  FETCH_LAST_DIAGNOSTICS_LOADING: `FETCH_LAST_DIAGNOSTICS_LOADING`,
  FETCH_LAST_DIAGNOSTICS_SUCCESS: `FETCH_LAST_DIAGNOSTICS_SUCCESS`,
}

export default Constants
