import React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
// import amex from 'payment-icons/min/flat/amex.svg'
// import maestro from 'payment-icons/min/flat/maestro.svg'
// import mastercard from 'payment-icons/min/flat/mastercard.svg'
// import visa from 'payment-icons/min/flat/visa.svg'
import { useAuth, useUpdatePaymentMethods } from '../../../hooks'

import { Dialog, Button, Field } from '../../ui'
import {
  Card,
  Form,
  DialogHeader,
  DialogTitle,
  FlexInput,
  SuccessMessage,
  ErrorMessage,
} from './style'

// const getCreditCardImage = creditCardName =>
//   ({
//     amex,
//     maestro,
//     mastercard,
//     visa,
//   }[creditCardName])

const PaymentMethodCard = ({ paymentMethod, isModalOpen, setIsModalOpen }) => {
  const { authUser } = useAuth()
  const {
    update: updatePaymentMethods,
    isError: isUpdatePaymentMethodsError,
    isLoading: isUpdatePaymentMethodsLoading,
    isSuccess: isUpdatePaymentMethodsSuccess,
  } = useUpdatePaymentMethods()

  const validationSchema = Yup.object().shape({
    numberCard: Yup.string()
      .max(19, 'Le numéro de carte doit comporter au maximum 16 caractères')
      .required('Champs obligatoire'),
    CVV: Yup.string()
      .min(3, 'CVV doit comporter au minimum 3 caractères')
      .max(4, 'CVV doit comporter au maximum 4 caractères')
      .required('Champs obligatoire'),
    nameCard: Yup.string().required('Champs obligatoire'),
    ExpiryDate: Yup.string()
      .min(5, "La date d'éxpiration doit comporter au minimum 5 caractères")
      .required('Champs obligatoire'),
  })

  const { handleSubmit, handleChange, handleBlur, values, errors, touched } =
    useFormik({
      initialValues: {
        nameCard: '',
        numberCard: '',
        ExpiryDate: '',
        CVV: '',
      },
      validationSchema: validationSchema,
      onSubmit: (values, actions) => {
        updatePaymentMethods({
          numberCard: values.numberCard,
          exp_month: `${values.ExpiryDate[0]}${values.ExpiryDate[1]}`,
          exp_year: `${values.ExpiryDate[3]}${values.ExpiryDate[4]}`,
          cvc: values.CVV,
          customer_id: `${authUser.id}`,
          email: authUser.email,
        })
      },
    })

  const formatCardNumber = value => {
    const regex = /^(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})$/g
    const onlyNumbers = value.replace(/[^\d]/g, '')
    return onlyNumbers.replace(regex, (regex, $1, $2, $3, $4) =>
      [$1, $2, $3, $4].filter(group => !!group).join(' ')
    )
  }

  const formatExpiryDate = string => {
    return string
      .replace(
        /[^0-9]/g,
        '' // To allow only numbers
      )
      .replace(
        /^([2-9])$/g,
        '0$1' // To handle 3 > 03
      )
      .replace(
        /^(1{1})([3-9]{1})$/g,
        '0$1/$2' // 13 > 01/3
      )
      .replace(
        /^0{1,}/g,
        '0' // To handle 00 > 0
      )
      .replace(
        /^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g,
        '$1/$2' // To handle 113 > 11/3
      )
  }

  const formatOnlyNumber = string => {
    return string.toString().replace(
      /[^0-9]/g,
      '' // To allow only numbers
    )
  }

  return (
    <Card>
      {/* <Img src={getCreditCardImage(paymentMethod.card_brand)} />
      <CardInfo>
        <CardNumber>{`*** ${paymentMethod.card_last4}`}</CardNumber>
        <CardExpiry>
          Expire le{' '}
          {`${paymentMethod.card_exp_month} / ${paymentMethod.card_exp_year}`}
        </CardExpiry>
      </CardInfo> */}
      {/* <Action
        onClick={e => {
          e.preventDefault()
          setIsModalOpen(true)
        }}
        as='button'
      >
        Modifier
      </Action> */}

      <Dialog isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <DialogHeader>
          <DialogTitle>Modifier la carte de crédit</DialogTitle>
        </DialogHeader>
        <Form onSubmit={handleSubmit}>
          <Field
            label='Nom sur la carte'
            name='nameCard'
            id='name-card'
            type='text'
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.nameCard}
            error={errors.nameCard}
            touched={touched.nameCard}
          />
          <Field
            label='Numéro de carte'
            name='numberCard'
            id='number-card'
            type='text'
            onChange={handleChange}
            onBlur={handleBlur}
            value={formatCardNumber(values.numberCard)}
            error={errors.numberCard}
            touched={touched.numberCard}
          />
          <FlexInput>
            <Field
              label="Date d'expiration"
              name='ExpiryDate'
              id='expiry-date'
              type='text'
              onChange={handleChange}
              onBlur={handleBlur}
              value={formatExpiryDate(values.ExpiryDate)}
              error={errors.ExpiryDate}
              touched={touched.ExpiryDate}
            />

            <Field
              label='CVV'
              name='CVV'
              id='CVV'
              type='text'
              onChange={handleChange}
              onBlur={handleBlur}
              value={formatOnlyNumber(values.CVV)}
              error={errors.CVV}
              touched={touched.CVV}
            />
          </FlexInput>

          <Button
            appearance='primary'
            isDisabled={isUpdatePaymentMethodsLoading}
            type='submit'
          >
            {isUpdatePaymentMethodsLoading ? 'Chargement...' : 'Confirmer'}
          </Button>
          <SuccessMessage>
            {isUpdatePaymentMethodsSuccess
              ? 'Votre moyen de paiement a été mis à jour'
              : ''}
          </SuccessMessage>
          <ErrorMessage>
            {isUpdatePaymentMethodsError ? 'Une erreur est survenue' : ''}
          </ErrorMessage>
        </Form>
      </Dialog>
    </Card>
  )
}

export default PaymentMethodCard
