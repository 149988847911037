import React, { useEffect, useState } from 'react'
import { Content } from './style'
import { Dialog } from '../../ui'
import axios from 'axios'

const SideModalProduct = ({ isOpen, onClose, handle }) => {
  const [code, setCode] = useState()

  useEffect(() => {
    axios
      .get(`https://about.compliment.me/products/${handle}?view=quick-view`)
      .then(function (response) {
        setCode(response.data)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Dialog isOpen={isOpen} onClose={onClose} type='aside'>
      <Content dangerouslySetInnerHTML={{ __html: code }}></Content>
    </Dialog>
  )
}

export default SideModalProduct
