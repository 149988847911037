import React from 'react'
import { Dialog } from '../../ui'
import { Title, Close, OrderList } from './style'
import { IoCloseOutline } from 'react-icons/io5'
import OrderItem from '../order-item'
// import { Color } from '../../../utils/constants'

const OrderDialog = ({
  setIsDialogOpen,
  isDialogOpen,
  lineItems,
  createdAt,
}) => {
  return (
    <Dialog isOpen={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
      <Close onClick={() => setIsDialogOpen(false)}>
        <IoCloseOutline />
      </Close>
      <Title>Mon programme du {createdAt}</Title>

      <OrderList>
        {lineItems?.edges.map(p => {
          return (
            <OrderItem
              widthText='250px'
              handle={p?.node?.variant?.product?.handle}
              quantity={p?.node?.quantity}
              description={p?.node?.variant?.product?.metafield?.value}
              title={p?.node?.variant?.product?.title}
              image={p.node?.variant?.image?.src}
            />
          )
        })}
      </OrderList>

      {/* <ButtonWrapper>
        <Button
          style={{ padding: '0' }}
          color={Color.DEEP_SEA}
          //   iconLeft='plus'
          appearance='underline'
        >
          Téléchargez mon livret de cure
        </Button>
      </ButtonWrapper> */}
    </Dialog>
  )
}

export default OrderDialog
