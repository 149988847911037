import styled from 'styled-components/macro'
import { MediaQuery } from '../../../../utils/constants'

export const OrderList = styled.div`
  width: 57%;
  ${MediaQuery.M_AND_DOWN} {
    width: 100%;
    margin-bottom: 40px;
  }
`

export const ButtonWrapper = styled.div`
  margin-top: 30px;
  text-align: center;
`
