import styled from 'styled-components/macro'
import { Color, MediaQuery } from '../../../utils/constants'

export const Layer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1;
`

export const SpinnerWrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

export const LayerContent = styled.div`
  display: flex;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  > * {
    margin: 0 10px;
  }
  ${MediaQuery.M_AND_DOWN} {
    flex-direction: column;
    > * {
      margin: 10px 0px;
    }
  }
`

export const Wrapper = styled.div`
  margin: 0px 60px 40px 60px;
  ${MediaQuery.L_AND_DOWN} {
    margin: 0px 15px 10px 15px;
  }
  background-color: white;
  padding: 30px 20px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  position: relative;
`

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  ${MediaQuery.M_AND_DOWN} {
    flex-direction: column;
  }
`

export const Header = styled.div`
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  ${MediaQuery.M_AND_DOWN} {
    flex-direction: column;
  }
`
export const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  ${MediaQuery.M_AND_DOWN} {
    flex-direction: column;
    align-items: flex-start;
  }
  h3 {
    margin-right: 15px;
    text-transform: uppercase;
    font-weight: 300;
    opacity: 0.7;
    font-size: 16px;
  }
  p {
    cursor: pointer;
    font-size: 13px;
    opacity: 0.7;
    display: flex;
    align-items: center;
    &:hover {
      text-decoration: underline;
    }
  }
`

export const EditIcon = styled.span`
  cursor: pointer;
  margin-left: 5px;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: ${Color.DEEP_SEA};
`

export const OrderSummary = styled.div`
  border-radius: 3px;
  background-color: ${Color.COLOR_SECONDARY};
  width: 38%;
  padding: 30px;

  ${MediaQuery.M_AND_DOWN} {
    width: 100%;
  }
`

export const OrderSummaryTitle = styled.h3`
  text-align: center;
  margin-bottom: 25px;
  font-weight: 300;
  font-size: 20px;
`

export const OrderSummaryLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 13px;
  margin-bottom: 20px;
  & > *:first-child {
    margin-right: 10px;
  }
  & > *:last-child {
    margin-left: 10px;
  }
  span {
    position: relative;
    white-space: nowrap;
  }

  > hr {
    width: 100%;
    border: 0;
    height: 1px;
    background: #999;
  }
`

export const Line = styled.hr`
  margin-top: 9px;
  width: 100%;
  border: 0;
  height: 1px;
  background: #999;
  margin-bottom: 25px;
`

export const Prices = styled.div`
  & > *:not(:last-child) {
    margin-right: 10px;
  }
`

export const TotalPrice = styled.span`
  text-decoration: ${props => (props.crossOut ? 'line-through' : '')};
  font-size: ${props => (props.crossOut ? '10px' : '')};
`
export const TotalPriceDiscount = styled.span``

export const FreeShipping = styled.span`
  position: absolute;
  top: 18px;
  left: -23%;
`

export const NoOrders = styled.div`
  text-align: center;
  opacity: 0.5;
`
