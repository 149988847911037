const Constants = {
  FETCH_PAYMENT_METHODS_FAILED: `FETCH_PAYMENT_METHODS_FAILED`,
  FETCH_PAYMENT_METHODS_LOADING: `FETCH_PAYMENT_METHODS_LOADING`,
  FETCH_PAYMENT_METHODS_SUCCESS: `FETCH_PAYMENT_METHODS_SUCCESS`,
  UPDATE_PAYMENT_METHODS_FAILED: `UPDATE_PAYMENT_METHODS_FAILED`,
  UPDATE_PAYMENT_METHODS_LOADING: `UPDATE_PAYMENT_METHODS_LOADING`,
  UPDATE_PAYMENT_METHODS_SUCCESS: `UPDATE_PAYMENT_METHODS_SUCCESS`,
}

export default Constants
