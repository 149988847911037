import Constants from './constants'
import axios from 'axios'
import toast from 'react-hot-toast'
import { removeDataFromLocalStorage } from '../../utils/helpers'

const fetchLoading = () => ({ type: Constants.FETCH_PAYMENT_METHODS_LOADING })
const fetchSuccess = payload => ({
  type: Constants.FETCH_PAYMENT_METHODS_SUCCESS,
  payload,
})
const fetchFailed = error => ({
  type: Constants.FETCH_PAYMENT_METHODS_FAILED,
  error,
})

export const fetchAll = session => dispatch => {
  dispatch(fetchLoading())

  axios
    .post('https://www.compliment.me/api/listPaymentMethods', {
      session,
    })
    .then(res => {
      dispatch(fetchSuccess(res.data))
    })
    .catch(e => {
      dispatch(fetchFailed(e.message))
      removeDataFromLocalStorage()
    })

  // axios
  //   .post(
  //     'https://europe-west1-compliment-backoffice.cloudfunctions.net/rechargeQuery',
  //     {
  //       token,
  //       customerToken: tempToken,
  //       schema: `{ "payment_sources": [] }`,
  //     }
  //   )
  //   .then(res => {
  //     dispatch(fetchSuccess(res.data))
  //   })
  //   .catch(e => {
  //     dispatch(fetchFailed(e.message))
  //     removeDataFromLocalStorage()
  //   })
}

const updatePaymentMethodLoading = () => ({
  type: Constants.UPDATE_PAYMENT_METHODS_LOADING,
})
const updatePaymentMethodSuccess = payload => ({
  type: Constants.UPDATE_PAYMENT_METHODS_SUCCESS,
  payload,
})
const updatePaymentMethodFailed = error => ({
  type: Constants.UPDATE_PAYMENT_METHODS_FAILED,
  error,
})

export const updatePaymentMethod = (data, tempToken) => dispatch => {
  dispatch(updatePaymentMethodLoading())

  toast
    .promise(
      axios.post('https://www.compliment.me/api/updatePaymentMethod', {
        ...data,
      }),
      {
        loading: 'en cours...',
        success: 'Votre moyen de paiement a été mis à jour',
        error: 'Oops! Une erreur est survenue.',
      },
      {
        duration: 3000,
      }
    )
    .then(res => {
      dispatch(updatePaymentMethodSuccess(res))
    })
    .catch(e => {
      dispatch(updatePaymentMethodFailed(e))
    })
}
