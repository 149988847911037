import React from 'react'
import {
  Header,
  HeaderTitle,
  EditIcon,
  HeaderRight,
  Grid,
  Wrapper,
  Hr,
} from '../style'
import { Button } from '../../ui'
import { AiOutlineEdit } from 'react-icons/ai'

const BillingInformation = () => {
  return (
    <Wrapper>
      <Header>
        <HeaderTitle>
          <h3>Mes informations de facturation</h3>
        </HeaderTitle>
        <HeaderRight>
          <p>*informations obligatoires</p>
          <Button
            style={{ padding: '0', opacity: '.6', textTransform: 'uppercase' }}
            appearance='underline'
            size='13px'
          >
            Modifier les informations
          </Button>
          <EditIcon>
            <AiOutlineEdit />
          </EditIcon>
        </HeaderRight>
      </Header>
      <Hr></Hr>

      <Grid>
        <div>
          <label>Nom d'entreprise*</label>
          <p></p>
        </div>
        <div>
          <label>Numéro de TVA*</label>
          <p></p>
        </div>
        <div>
          <label>Nom, prénom*</label>
          <p></p>
        </div>
        <div>
          <label>Adresse*</label>
          <p></p>
        </div>
      </Grid>
    </Wrapper>
  )
}

export default BillingInformation
