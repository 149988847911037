import React, { useState } from 'react'
import {
  Header,
  HeaderTitle,
  EditIcon,
  HeaderRight,
  Grid,
  Wrapper,
  Hr,
} from '../../style'
import { Button } from '../../../ui'
import { AiOutlineEdit } from 'react-icons/ai'
import moment from 'moment'
import SubscriptionAddressModal from '../../../subscription/subscription-address-modal'

const DeliveryAdressList = ({ list }) => {
  return (
    <>
      {list.map(i => {
        return <DeliveryAdressItem i={i} />
      })}
    </>
  )
}
export default DeliveryAdressList

const DeliveryAdressItem = ({ i }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Wrapper>
      <Header>
        <HeaderTitle>
          <h3>Mon adresse de livraison</h3>
          <span>
            Programme crée le {moment(i?.createdAt).format('DD/MM/YYYY')}
          </span>
        </HeaderTitle>
        <HeaderRight>
          <p>*informations obligatoires</p>
          <Button
            style={{
              padding: '0',
              opacity: '.6',
              textTransform: 'uppercase',
            }}
            appearance='underline'
            size='13px'
            onClick={() => setIsOpen(true)}
          >
            Modifier les informations
          </Button>
          <EditIcon onClick={() => setIsOpen(true)}>
            <AiOutlineEdit />
          </EditIcon>
        </HeaderRight>
      </Header>
      <Hr></Hr>

      <Grid>
        <div>
          <label>Nom sur la boite*</label>
          <p>
            {i?.address?.first_name} {i?.address?.last_name}
          </p>
        </div>
        <div>
          <label>Addresse (rue et numero)*</label>
          <p>{i?.address?.address1}</p>
        </div>
        <div>
          <label>Code postal*</label>
          <p>{i?.address?.zip}</p>
        </div>
        {/* <div>
        <label>Numéro de boite*</label>
        <p></p>
      </div> */}

        <div>
          <label>Ville*</label>
          <p>{i?.address?.city}</p>
        </div>
        <div>
          <label>Pays*</label>
          <p>{i?.address?.country}</p>
        </div>
      </Grid>
      <SubscriptionAddressModal
        subscription={i}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
    </Wrapper>
  )
}
