import { gql } from '@apollo/client'

export const COLLECTION_BY_HANDLE = gql`
  query getcollectionByHandle($perPage: Int!, $cursor: String) {
    collectionByHandle(handle: "marketplace") {
      products(first: $perPage, after: $cursor) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            id
            handle
            title
            description
            sellingPlanGroups(first: 10) {
              edges {
                node {
                  name
                  sellingPlans(first: 10) {
                    edges {
                      node {
                        id
                        name
                      }
                    }
                  }
                }
              }
            }
            images(first: 10) {
              edges {
                node {
                  src
                }
              }
            }
            metafields(identifiers: { key: "string", namespace: "" }) {
              key
              value
            }
            variants(first: 10) {
              edges {
                node {
                  id
                  priceV2 {
                    amount
                  }
                  title
                  image {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const PRODUCTS = gql`
  query getProducts($perPage: Int!, $cursor: String, $query: String) {
    products(first: $perPage, after: $cursor, query: $query) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          handle
          title
          description
          collections(first: 10) {
            edges {
              node {
                handle
              }
            }
          }
          sellingPlanGroups(first: 10) {
            edges {
              node {
                name
                sellingPlans(first: 10) {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
          images(first: 10) {
            edges {
              node {
                src
              }
            }
          }
          metafields(identifiers: { key: "string", namespace: "" }) {
            key
            value
          }
          variants(first: 10) {
            edges {
              node {
                id
                priceV2 {
                  amount
                }
                title
                image {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`
