import React from 'react'

import {
  Container,
  ArrorBackIcon,
  CardLink,
  CardImage,
  CardTitle,
  CardCta,
  CardLinkIconArrow,
} from './style'
import { NavWrapper, NavLinkArrow, IconArrow } from '../style'
import { navDisplay } from '../../../../utils/constants'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'
import img from '../../../../assets/images/collagene2.png'

const PopupShopNavMobileContent = ({ setNavBarContent }) => (
  <Container>
    <ArrorBackIcon onClick={() => setNavBarContent(navDisplay.MAINNAV)}>
      <IoIosArrowBack />
    </ArrorBackIcon>

    <NavWrapper>
      <NavLinkArrow href='https://www.compliment.me/products'>
        Vitamines
        <IconArrow>
          <IoIosArrowForward />
        </IconArrow>
      </NavLinkArrow>
      {/* <NavLinkArrow
      >
        poudres
        <IconArrow>
          <IoIosArrowForward />
        </IconArrow>
      </NavLinkArrow>
      <NavLinkArrow>
        Extras
        <IconArrow>
          <IoIosArrowForward />
        </IconArrow>
      </NavLinkArrow> */}
    </NavWrapper>

    <CardLink href='https://compliment.me/products/collagene'>
      <CardImage src={img} />
      <CardTitle>Nouveau! La poudre de collagène</CardTitle>
      <CardCta>
        Découvrir
        <CardLinkIconArrow>
          <IoIosArrowForward />
        </CardLinkIconArrow>
      </CardCta>
    </CardLink>
  </Container>
)

export default PopupShopNavMobileContent
