import styled from 'styled-components/macro'

import { BaseText } from '../../../style'

export const Label = styled(BaseText)`
  position: relative;
  margin-bottom: 8px;

  ${({ isRequired }) => {
    if (isRequired) {
      return `
        &:before {
          content: '*';
          position: absolute;
          top; -5px;
          left: -9px;
          color: #f56c6c;
        }
      `
    }
  }}
`
