import React, { useState } from 'react'
import { Dialog, Button } from '../../ui'
import { Title, Close, Reasons, Reason, ButtonWrapper, Input } from './style'
import { IoCloseOutline } from 'react-icons/io5'

const PauseDialog = ({
  isDialogOpen,
  setIsDialogOpen,
  onCancelSubscription,
  subscription,
  isCancelLoading,
}) => {
  const [reason, setReason] = useState('')
  const [input, setInput] = useState('')

  const selectReason = (reason, input) => {
    if (reason === 'Autre') {
      return `Autre: ${input}`
    } else if (
      reason === "J'ai du mal à avaler / je n'aime pas un complément"
    ) {
      return `J'ai du mal à avaler / je n'aime pas un complément: ${input}`
    } else {
      return reason
    }
  }

  return (
    <Dialog isOpen={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
      <Close onClick={() => setIsDialogOpen(false)}>
        <IoCloseOutline />
      </Close>
      <Title>Pourquoi souhaitez-vous mettre votre abonnement en pause ? </Title>
      <Reasons>
        <Reason
          selected={reason === 'Je trouve cela trop cher.'}
          onClick={() => setReason('Je trouve cela trop cher.')}
        >
          Je trouve cela trop cher.
        </Reason>
        <Reason
          selected={reason === "J'en ai déjà suffisamment."}
          onClick={() => setReason("J'en ai déjà suffisamment.")}
        >
          J'en ai déjà suffisamment.
        </Reason>
        <Reason
          selected={
            reason === "J'ai du mal à avaler / je n'aime pas un complément"
          }
          onClick={() => {
            setInput('')
            setReason("J'ai du mal à avaler / je n'aime pas un complément")
          }}
        >
          J'ai du mal à avaler/je n'aime pas un complément
        </Reason>
        {reason === "J'ai du mal à avaler / je n'aime pas un complément" && (
          <Input
            onChange={e => setInput(e.target.value)}
            placeholder='quel(s) produit(s) ?'
          ></Input>
        )}
        <Reason
          selected={reason === 'Autre'}
          onClick={() => {
            setInput('')
            setReason('Autre')
          }}
        >
          Autre
        </Reason>
        {reason === 'Autre' && (
          <Input
            onChange={e => setInput(e.target.value)}
            placeholder="Entrez la raison de l'annulation"
          ></Input>
        )}
      </Reasons>
      <ButtonWrapper>
        <Button
          isLoading={isCancelLoading}
          onClick={() => {
            onCancelSubscription(subscription, selectReason(reason, input))
          }}
          appearance='primary'
        >
          Mettre mon abonnement en pause
        </Button>
      </ButtonWrapper>
    </Dialog>
  )
}

export default PauseDialog
