import styled from 'styled-components/macro'

import { Color } from '../../utils/constants'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px;
  border-radius: 3px;
  box-shadow: 0 5px 10px #00000012;
`

export const Title = styled.h1`
  font-size: 30px;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 30px;
  font-weight: 300;
  width: 95%;
`

export const SwitchButton = styled.div`
  margin-bottom: 30px;
  background-color: #efefef;
  border-radius: 20px;
`

export const Button = styled.button`
  cursor: pointer;
  border: none;
  padding: 15px 30px;
  background-color: ${props => (props.actif ? Color.ORANGE : '')};
  color: ${props => (props.actif ? 'white' : '')};
  border-radius: 20px;
`

export const SubTitle = styled.h1`
  font-size: 22px;
  text-align: center;
`

export const FooterText = styled.p`
  font-size: 14px;
  margin-top: 20px;
  max-width: 280px;

  > a {
    color: ${Color.DEEP_SEA};
    text-decoration: none;
  }
`
