import styled from 'styled-components/macro'
import { Color, MediaQuery } from '../utils/constants'

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: ${Color.COLOR_SECONDARY};

  ${MediaQuery.L_AND_DOWN} {
    padding-bottom: 120px;
  }
`

export const Header = styled.div`
  width: 100%;
  padding: 100px 60px 60px 60px;
  margin-bottom: 40px;
  /* background-image: url('https://firebasestorage.googleapis.com/v0/b/compliment-me-prod.appspot.com/o/Les%20tips_1%20%E2%80%93%2049%402x.png?alt=media&token=2c9ec761-f388-4ea5-8f73-0cfc0cd0745e'); */
  background-color: #00875a;
  background-position: center;
  background-size: cover;
  ${MediaQuery.L_AND_DOWN} {
    padding: 60px 30px 60px 30px;
  }
`

export const Nav = styled.div`
  display: flex;
  & > * {
    margin-right: 45px;
  }
  ${MediaQuery.L_AND_DOWN} {
    overflow-x: scroll;
    padding: 10px 0;
  }
`

export const Title = styled.h3`
  margin-bottom: 30px;
  font-size: 30px;
  font-weight: 500;
  color: white;
`

export const SubTitle = styled.h3`
  font-size: 19px;
  font-weight: 300;
  opacity: 0.6;
`
export const Hr = styled.hr`
  margin: 30px 0;
  border-top: 0.5px solid #eee;
`

export const NavLink = styled.a`
  cursor: pointer;
  border-bottom: ${props => (props.active ? '1.5px solid white' : '')};
  /* color: ${Color.DEEP_SEA}; */
  color: white;
  position: relative;
  white-space: nowrap;
  span {
    ${MediaQuery.L_AND_DOWN} {
      display: none;
    }
    position: absolute;
    top: 68px;
    left: 50%;
    transform: translate(-50%);
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid ${Color.COLOR_SECONDARY};
  }
`
