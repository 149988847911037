import PropTypes from 'prop-types'

import { GlobalContainer, Main } from './style'
import Header from '../../section/main-header'
import HeaderMobile from '../../section/main-header-mobile'

const DefaultLayout = ({ children }) => (
  <GlobalContainer>
    <Header />
    <HeaderMobile />
    <Main>{children}</Main>
  </GlobalContainer>
)

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default DefaultLayout
