import styled from 'styled-components/macro'

import { Color } from '../../../utils/constants'

export const LogoContainer = styled.div`
  height: 100%;
  width: ${props => (props.mobile ? '140px' : '180px')};
  display: flex;
  align-items: center;

  * {
    fill: ${Color.DEEP_SEA};
  }
`
