import React, { useState, useEffect } from 'react'
import {
  Container,
  Img,
  Text,
  HandleQuantity,
  Price,
  Remove,
  Name,
  ImgWrapper,
  //   Detail,
} from './style'
import { IconSpinner } from '../../../components/ui'
import { IoCloseOutline } from 'react-icons/io5'
import { selectQuantityPerDay } from '../../../utils/helpers'
import SideModalProduct from '../../product/side-modal-product'
import { useAuth } from '../../../hooks'
import axios from 'axios'

const OrderItem = ({
  abilityToHandle,
  abilityToDelete,
  image,
  title,
  quantity,
  price,
  description,
  widthText,
  handle,
  onProductRemove,
  isRemoveProductLoading,
  isRemoveProductSuccess,
  subscription,
  id,
  change,
  isChangeQuantitySuccess,
  isChangeQuantityLoading,
  variant_title,
  charge_interval_frequency,
  order_interval_unit,
}) => {
  const { authUser } = useAuth()
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [
    ,
    //    selectedQuantity
    setSelectedQuantity,
  ] = useState(quantity)
  const [selectedQuantity2, setSelectedQuantity2] = useState(0)
  const [selectedId, setSelectedId] = useState('')

  useEffect(() => {
    isChangeQuantityLoading === false && setSelectedQuantity(selectedQuantity2)
    isChangeQuantityLoading === false && setSelectedId('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChangeQuantitySuccess])

  useEffect(() => {
    isRemoveProductLoading === false && setSelectedId('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRemoveProductSuccess])

  const selectVariantTitle = variant_title => {
    if (variant_title === '1month_subscription') {
      return 'mois'
    } else if (variant_title === '3month_subscription') {
      return '3mois'
    } else {
      return 'mois'
    }
  }

  const getOrderInterval = (charge_interval_frequency, order_interval_unit) => {
    if (order_interval_unit === 'day') {
      return `Rechargé tous les ${charge_interval_frequency} jours`
    } else if (variant_title === 'month') {
      return `Rechargé tous les ${charge_interval_frequency} mois`
    }
  }

  return (
    <Container>
      <SideModalProduct handle={handle} isOpen={isOpenModal} />
      <ImgWrapper>
        <Img src={image} />
      </ImgWrapper>

      <Text widthText={widthText}>
        <Name>{title}</Name>
      </Text>
      {isChangeQuantityLoading && id === selectedId ? (
        <IconSpinner />
      ) : (
        <HandleQuantity>
          {abilityToHandle && (
            <select
              onChange={event => {
                setSelectedId(id)

                // axios.post('http://localhost:3000/api/updateSubscription', {
                //   session: authUser.data.session,
                //   subscriptionId: id,
                //   quantity: event.target.value,
                // })

                change(id, event.target.value)
                setSelectedQuantity2(event.target.value)
              }}
              value={quantity}
              name='quantity'
              id='quantity'
            >
              <option value='1'>1</option>
              <option value='2'>2</option>
              <option value='3'>3</option>
              <option value='4'>4</option>
              <option value='5'>5</option>
              <option value='6'>6</option>
              <option value='7'>7</option>
              <option value='8'>8</option>
              <option value='9'>9</option>
              <option value='10'>10</option>
            </select>
          )}
          {charge_interval_frequency && (
            <span>
              {getOrderInterval(charge_interval_frequency, order_interval_unit)}
            </span>
          )}
        </HandleQuantity>
      )}
      {price && <Price>{quantity * price}€</Price>}
      {abilityToDelete && (
        <Remove
          isLoading={isRemoveProductLoading}
          onClick={() => {
            setSelectedId(id)
            onProductRemove()
          }}
        >
          {isRemoveProductLoading && id === selectedId ? (
            <IconSpinner />
          ) : (
            <span>
              <IoCloseOutline />
            </span>
          )}
        </Remove>
      )}
    </Container>
  )
}

export default OrderItem
