import amplitude from 'amplitude-js'
import ReactPixel from 'react-facebook-pixel'
import { ErrorCodes } from '../constants'

export const isProd = process.env.REACT_APP_ENV === 'production'

export const selectQuantityPerDay = (handle, qty = 0) => {
  switch (handle) {
    case 'collagene':
      return `1 stick par jour`
    case 'proteines-vegetales':
      return `${qty} sac${qty > 1 ? 's' : ''}`
    case 'spray-de-melatonine':
      return '2 à 5 pulvérisations'
    default:
      return `1 capsule par jour`
  }
}

export const dateFormat = date => {
  const d = new Date(date)

  const day = d.getDay().toString().padStart(2, '0')
  const month = d.getMonth().toString().padStart(2, '0')
  const year = d.getFullYear()

  return `${day}/${month}/${year}`
}

export const getErrorMessage = errorCode => {
  switch (errorCode) {
    case ErrorCodes.PRODUCT_ALREADY_IN_TAKE:
      return 'Ce produit ne peut pas être ajouté deux fois pour une même prise, augmentez la quantité du produit à la place.'
    case ErrorCodes.PRODUCT_NOT_FOUND:
      return 'Le produit est introuvable'
    case ErrorCodes.TAKE_NOT_FOUND:
      return 'La prise est introuvable'
    default:
      return "Une erreur s'est produite, réessayez plus tard"
  }
}

/**
 *
 * @param {Object} data
 * @param {String} data.token Auth user token
 * @returns
 */
export const addDataToLocalStorage = ({ tokens, user }) => {
  if (!localStorage) return

  tokens && localStorage.setItem('tokens', tokens)
  user && localStorage.setItem('auth_user', user)
  // if (!expireIn) return
  // const expirationDate = new Date(new Date().getTime() + expireIn * 1000)
  // localStorage.setItem('token_expiration', expirationDate)
}

export const removeDataFromLocalStorage = () => {
  if (!localStorage) return

  localStorage.removeItem('auth_user')
  localStorage.removeItem('tokens')
  window.location = '/login'
}

/**
 * @returns {Object} authUser, token, tokenExpiration
 */
export const getDataFromLocalStorage = () => {
  if (!localStorage) return

  const userData = localStorage.getItem('auth_user')
  const tokens = localStorage.getItem('tokens')

  return {
    authUser: userData && JSON.parse(userData),
    tokens: tokens && JSON.parse(tokens),
  }
}

// Amplitude

export const initAmplitude = () => {
  amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_API_KEY)
}

export const setAmplitudeUserDevice = installationToken => {
  amplitude.getInstance().setDeviceId(installationToken)
}

export const setAmplitudeUserId = userId => {
  amplitude.getInstance().setUserId(userId)
}

export const setAmplitudeUserProperties = properties => {
  amplitude.getInstance().setUserProperties(properties)
}

export const sendAmplitudeData = (eventType, eventProperties) => {
  amplitude.getInstance().logEvent(eventType, eventProperties)
}

// react-facebook-pixel

export const initFacebookPixel = () => {
  const advancedMatching = {}
  const options = {
    autoConfig: true,
    debug: false,
  }
  ReactPixel.init('2301155686872166', advancedMatching, options)
}

export const trackFacebookEvent = (event, data = {}) => {
  ReactPixel.track(event, data)
}
