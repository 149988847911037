import React from 'react'
import { Header, HeaderTitle, Wrapper, Hr } from '../style'
import { ContentFlex, Informations, BoxTitle } from './style'
import { Button } from '../../ui'
import { useAuth } from '../../../hooks'

const ReferralInformation = () => {
  const { authUser } = useAuth()
  return (
    <Wrapper>
      <Header>
        <HeaderTitle>
          <h3>Mes informations personnelles</h3>
        </HeaderTitle>
      </Header>
      <Hr></Hr>

      <ContentFlex>
        <BoxTitle>
          <h4>Offrez 20€, recevez 20€</h4>
          <p>
            Chaque ami que vous renseignez gagnera 20€ et vous recevrez 20€
            après l'achat de sa première commande.
          </p>
        </BoxTitle>
        <Informations>
          <div>
            <label>Nom*</label>
            <p>{authUser?.firstName}</p>
          </div>

          <div>
            <label>Prénom*</label>
            <p>{authUser?.lastName}</p>
          </div>

          <Button appearance='primary'>
            <a
              rel='noreferrer'
              target='_blank'
              href='https://about.compliment.me/pages/refer-a-friend'
            >
              Parrainer un ami
            </a>
          </Button>
        </Informations>
      </ContentFlex>
    </Wrapper>
  )
}

export default ReferralInformation
