const Constants = {
  ADD_PRODUCT_TO_SUBSCRIPTION_FAILED: 'ADD_PRODUCT_TO_SUBSCRIPTION_FAILED',
  ADD_PRODUCT_TO_SUBSCRIPTION_LOADING: 'ADD_PRODUCT_TO_SUBSCRIPTION_LOADING',
  ADD_PRODUCT_TO_SUBSCRIPTION_SUCCESS: 'ADD_PRODUCT_TO_SUBSCRIPTION_SUCCESS',
  REMOVE_PRODUCT_FROM_SUBSCRIPTION_FAILED:
    'REMOVE_PRODUCT_FROM_SUBSCRIPTION_FAILED',
  REMOVE_PRODUCT_FROM_SUBSCRIPTION_LOADING:
    'REMOVE_PRODUCT_FROM_SUBSCRIPTION_LOADING',
  REMOVE_PRODUCT_FROM_SUBSCRIPTION_SUCCESS:
    'REMOVE_PRODUCT_FROM_SUBSCRIPTION_SUCCESS',
  CANCEL_SUBSCRIPTION_FAILED: `CANCEL_SUBSCRIPTION_FAILED`,
  CANCEL_SUBSCRIPTION_LOADING: `CANCEL_SUBSCRIPTION_LOADING`,
  CANCEL_SUBSCRIPTION_SUCCESS: `CANCEL_SUBSCRIPTION_SUCCESS`,
  FETCH_SUBSCRIPTIONS_FAILED: `FETCH_SUBSCRIPTIONS_FAILED`,
  FETCH_SUBSCRIPTIONS_LOADING: `FETCH_SUBSCRIPTIONS_LOADING`,
  FETCH_SUBSCRIPTIONS_SUCCESS: `FETCH_SUBSCRIPTIONS_SUCCESS`,
  CHANGE_ADDRESS_SUBSCRIPTION_SUCCESS: 'CHANGE_ADDRESS_SUBSCRIPTION_SUCCESS',
  CHANGE_ADDRESS_SUBSCRIPTION_FAILED: 'CHANGE_ADDRESS_SUBSCRIPTION_FAILED',
  CHANGE_ADDRESS_SUBSCRIPTION_LOADING: 'CHANGE_ADDRESS_SUBSCRIPTION_LOADING',
  CHANGE_DELIVERY_DATE_SUBSCRIPTION_SUCCESS:
    'CHANGE_DELIVERY_DATE_SUBSCRIPTION_SUCCESS',
  CHANGE_DELIVERY_DATE_SUBSCRIPTION_FAILED:
    'CHANGE_DELIVERY_DATE_SUBSCRIPTION_FAILED',
  CHANGE_DELIVERY_DATE_SUBSCRIPTION_LOADING:
    'CHANGE_DELIVERY_DATE_SUBSCRIPTION_LOADING',
  ADD_DISCOUNT_TO_SUBSCRIPTION_SUCCESS: 'ADD_DISCOUNT_TO_SUBSCRIPTION_SUCCESS',
  ADD_DISCOUNT_TO_SUBSCRIPTION_FAILED: 'ADD_DISCOUNT_TO_SUBSCRIPTION_FAILED',
  ADD_DISCOUNT_TO_SUBSCRIPTION_LOADING: 'ADD_DISCOUNT_TO_SUBSCRIPTION_LOADING',
  REMOVE_DISCOUNT_TO_SUBSCRIPTION_SUCCESS:
    'REMOVE_DISCOUNT_TO_SUBSCRIPTION_SUCCESS',
  REMOVE_DISCOUNT_TO_SUBSCRIPTION_FAILED:
    'REMOVE_DISCOUNT_TO_SUBSCRIPTION_FAILED',
  REMOVE_DISCOUNT_TO_SUBSCRIPTION_LOADING:
    'REMOVE_DISCOUNT_TO_SUBSCRIPTION_LOADING',
  SWITCH_ORDER_INTERVAL_SUBSCRIPTION_SUCCESS:
    'SWITCH_ORDER_INTERVAL_SUBSCRIPTION_SUCCESS',
  SWITCH_ORDER_INTERVAL_SUBSCRIPTION_FAILED:
    'SWITCH_ORDER_INTERVAL_SUBSCRIPTION_FAILED',
  SWITCH_ORDER_INTERVAL_SUBSCRIPTION_LOADING:
    'SWITCH_ORDER_INTERVAL_SUBSCRIPTION_LOADING',
  CHANGE_QUANTITY_SUBSCRIPTION_SUCCESS: 'CHANGE_QUANTITY_SUBSCRIPTION_SUCCESS',
  CHANGE_QUANTITY_SUBSCRIPTION_FAILED: 'CHANGE_QUANTITY_SUBSCRIPTION_FAILED',
  CHANGE_QUANTITY_SUBSCRIPTION_LOADING: 'CHANGE_QUANTITY_SUBSCRIPTION_LOADING',
  CREATE_SUBSCRIPTION_SUCCESS: 'CREATE_SUBSCRIPTION_SUCCESS',
  CREATE_SUBSCRIPTION_FAILED: 'CREATE_SUBSCRIPTION_FAILED',
  CREATE_SUBSCRIPTION_LOADING: 'CREATE_SUBSCRIPTION_LOADING',
}

export default Constants
