import Constants from './constants'
// import axios from 'axios'

export const fetchLoading = () => ({ type: Constants.FETCH_ORDERS_LOADING })
export const fetchSuccess = payload => ({
  type: Constants.FETCH_ORDERS_SUCCESS,
  payload,
})
export const fetchFailed = error => ({
  type: Constants.FETCH_ORDERS_FAILED,
  error,
})

// export const fetchAll = token => dispatch => {
//   dispatch(fetchLoading())
//   axios
//     .post(
//       'https://europe-west1-compliment-backoffice.cloudfunctions.net/rechargeQuery',
//       {
//         token,
//         schema: `{
//           "orders": {
//             "status": "SUCCESS",
//             "sort_by": "shipped_date-desc"
//           }
//         }`,
//       }
//     )
//     .then(res => {
//       dispatch(fetchSuccess(res.data.orders))
//     })
//     .catch(e => {
//       dispatch(fetchFailed(e.message))
//     })
// }
