export const getSubscriptions = ({ subscriptions }) => subscriptions
export const getFetchSubscriptionsStatus = ({ subscriptions }) =>
  subscriptions.actions.fetch.status
export const getFetchSubscriptionsError = ({ subscriptions }) =>
  subscriptions.actions.fetch.error
export const getCancelStatus = ({ subscriptions }) =>
  subscriptions.actions.cancel.status
export const getRemoveProductStatus = ({ subscriptions }) =>
  subscriptions.actions.removeProduct.status
export const getSwitchOrderIntervalStatus = ({ subscriptions }) =>
  subscriptions.actions.switchOrderInterval.status
export const getCancelError = ({ subscriptions }) =>
  subscriptions.actions.cancel.error
export const getSwitchOrderIntervalError = ({ subscriptions }) =>
  subscriptions.actions.switchOrderInterval.error

export const changeAddressSubscriptionsStatus = ({ subscriptions }) =>
  subscriptions.actions.changeAddress.status

export const addProductSubscriptionsStatus = ({ subscriptions }) =>
  subscriptions.actions.addProduct.status

export const changeDeliveryDateSubscriptionsStatus = ({ subscriptions }) =>
  subscriptions.actions.changeDeliveryDate.status

export const addDiscountSubscriptionsStatus = ({ subscriptions }) =>
  subscriptions.actions.addDiscount.status

export const removeDiscountSubscriptionsStatus = ({ subscriptions }) =>
  subscriptions.actions.removeDiscount.status

export const changeQuantitySubscriptionsStatus = ({ subscriptions }) =>
  subscriptions.actions.changeQuantity.status

export const createSubscriptionsStatus = ({ subscriptions }) =>
  subscriptions.actions.create.status
