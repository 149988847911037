import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { Redirect } from 'react-router'

import AuthenticatedRoute from './AuthenticatedRoute'
import UnauthenticatedOnlyRoute from './UnauthenticatedOnlyRoute'
import routes from './routes'

const Router = () => (
  <BrowserRouter>
    <Switch>
      {routes.map(route => {
        if (route.isPublic) {
          return (
            <Route
              key={route.path}
              component={route.c}
              exact={route.isExact === undefined ? true : route.isExact}
              {...route}
            />
          )
        }

        const RouteComponent = route.auth
          ? AuthenticatedRoute
          : UnauthenticatedOnlyRoute

        return (
          <RouteComponent
            key={route.path}
            path={route.path}
            exact={route.isExact === undefined ? true : route.isExact}
            component={route.c}
          />
        )
      })}
      <Redirect from='*' to='/settings' />
    </Switch>
  </BrowserRouter>
)

export default Router
