import React from 'react'
import {
  Container,
  Title,
  TitleWrapper,
  Header,
  ButtonContainer,
  Hr,
  Content,
  EmptyData,
  EmptyDataTitle,
  RecommendedProductList,
  // Flex,
  // NutriImg,
  // Nutritionist,
  // NutritionistText,
  Wrapper,
  // NutritionalProfile,
} from './style'
import { Button, Spinner } from '../../ui'
import RecommendedProduct from '../recommended-product'
// import Nutri from '../../../assets/images/phonecall.png'
// import { Color } from '../../../utils/constants'
import { useFetchLastDiagnostic } from '../../../hooks'
import moment from 'moment'

const MyDiagnosis = ({ setIsDialogOpen }) => {
  const { data, loading } = useFetchLastDiagnostic()
  return (
    <Container>
      {/* <Header>
        <Title>Les produits recommandés</Title>
        <ButtonContainer>
          <Button iconLeft='plus' appearance='primary'>
            Ajouter tous les produits à mon programme
          </Button>
          <Button iconRight='arrow-right'>Refaire mon diagnostic</Button>
        </ButtonContainer>
      </Header> */}

      {/* <Hr></Hr> */}

      <Content>
        {false ? (
          <Wrapper>
            <Title>Mon profil nutritionnel</Title>
            <Hr></Hr>
            <EmptyData>
              <EmptyDataTitle>
                Faites votre diagnostic afin de découvrir vos produits
                recommandés
              </EmptyDataTitle>
              <Button
                onClick={() => setIsDialogOpen(true)}
                appearance='primary'
              >
                Faites votre diagnostic
              </Button>
            </EmptyData>
          </Wrapper>
        ) : (
          <>
            {/* <Flex>
              <div>
                <Title>Les conseils de nos spécialistes</Title>
                <Hr></Hr>
                <Content>
                  <Nutritionist>
                    <NutriImg src={Nutri} />
                    <p>Solenn, nutritionniste</p>
                  </Nutritionist>
                  <NutritionistText>
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Quam, rem dicta! Laudantium sed, consequuntur quae expedita
                    in, voluptate optio officia eos nulla assumenda accusantium
                    reiciendis deleniti ea corporis placeat animi.
                  </NutritionistText>
                  <NutritionistText bg='#DAECE7'>
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Quam, rem dicta! Laudantium sed, consequuntur quae expedita
                    in, voluptate optio officia eos nulla assumenda accusantium
                    reiciendis deleniti ea corporis placeat animi.
                  </NutritionistText>
                </Content>
              </div>
              <div>
                <Title>Mon profil nutritionnel</Title>
                <Hr></Hr>
                <Content>
                  <NutritionalProfile
                    style={{
                      border: '1px solid #eee',
                      borderRadius: '3px',
                      height: '300px',
                    }}
                  >
                    <Button
                      style={{ padding: '0' }}
                      color={Color.DEEP_SEA}
                      iconRight='arrow-right'
                      appearance='underline'
                    >
                      Etablir mon programme
                    </Button>
                  </NutritionalProfile>
                </Content>
              </div>
            </Flex> */}
            <Wrapper>
              <Header>
                <TitleWrapper>
                  <Title>Les produits recommandés</Title>
                  <p>du {moment(data?.created_at).format('DD/MM/YYYY')}</p>
                </TitleWrapper>
                <ButtonContainer>
                  <Button appearance='primary'>
                    <a href='https://about.compliment.me/pages/questionnaire-cure-personnalisee-vitamines-complements-alimentaires'>
                      Refaire mon diagnostic
                    </a>
                  </Button>
                </ButtonContainer>
              </Header>
              <Hr></Hr>
              <RecommendedProductList>
                {loading ? (
                  <div style={{ margin: '0 auto' }}>
                    <Spinner />
                  </div>
                ) : (
                  <>
                    {data?.diagnostic_take?.diagnostic_items.map(item => {
                      return <RecommendedProduct item={item} />
                    })}
                  </>
                )}
              </RecommendedProductList>
            </Wrapper>
          </>
        )}
      </Content>
    </Container>
  )
}

export default MyDiagnosis
