import cloneDeep from 'lodash.clonedeep'

import Constant from './constants'
import initialState from './state'
import { ActionType } from '../../utils/constants'

const fetchFailed = (state, action) => {
  const newState = cloneDeep(state)
  newState.actions.fetch = { status: ActionType.FAILED, error: action.error }

  return newState
}
const fetchLoading = state => {
  const newState = cloneDeep(state)
  newState.actions.fetch = { status: ActionType.LOADING }

  return newState
}
const fetchSuccess = (state, { payload }) => {
  const newState = cloneDeep(state)
  newState.list = payload?.customer?.orders?.edges
    ? [...payload?.customer?.orders?.edges]
    : []
  newState.actions.fetch = { status: ActionType.SUCCESS }

  return newState
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Constant.FETCH_ORDERS_FAILED:
      return fetchFailed(state, action)
    case Constant.FETCH_ORDERS_LOADING:
      return fetchLoading(state)
    case Constant.FETCH_ORDERS_SUCCESS:
      return fetchSuccess(state, action)

    default:
      return state
  }
}
