import styled from 'styled-components/macro'
import { MediaQuery } from '../../../utils/constants'

export const Container = styled.div``
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 40px; */
  ${MediaQuery.S_AND_DOWN} {
    flex-direction: column;
    align-items: stretch;
  }
`
export const ButtonContainer = styled.div`
  & > *:first-child {
    margin-right: 20px;
  }
`
export const Title = styled.h3`
  font-size: 19px;
  font-weight: 300;
  opacity: 0.6;
  ${MediaQuery.S_AND_DOWN} {
    margin-bottom: 10px;
  }
`

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;

  p {
    margin-top: 5px;
    margin-left: 10px;
    opacity: 0.5;
    font-size: 12px;
  }
`

export const Hr = styled.hr`
  margin: 30px 0;
  border-top: 0.5px solid #eee;
`

export const Content = styled.div``

export const EmptyData = styled.div`
  text-align: center;
`

export const EmptyDataTitle = styled.h3`
  margin-bottom: 20px;
  margin-top: 60px;
  font-weight: 300;
  font-size: 15px;
  opacity: 0.5;
  text-decoration: underline;
`

export const RecommendedProductList = styled.div`
  padding: 0px 0 20px 0;
  display: flex;
  overflow-x: scroll;
`

export const Wrapper = styled.div`
  margin: ${props => (props.margin ? props.margin : '0px 60px 40px 60px')};
  ${MediaQuery.L_AND_DOWN} {
    margin: 0px 15px 10px 15px;
  }
  background-color: white;
  padding: 30px 20px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
`

export const Flex = styled.div`
  margin: 0px 60px 40px 60px;
  ${MediaQuery.L_AND_DOWN} {
    margin: 0px 15px 10px 15px;
  }
  background-color: white;
  padding: 30px 20px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  & > * {
    width: 48%;
  }
  ${MediaQuery.M_AND_DOWN} {
    display: flex;
    flex-direction: column;
    & > * {
      width: 100%;
    }
  }
`

export const NutritionalProfile = styled.div`
  position: relative;

  button {
    position: absolute;
    bottom: 20px;
    right: 30px;
  }
`

export const NutriImg = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 100px;
  object-fit: cover;
  margin-right: 20px;
`

export const Nutritionist = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  p {
    text-transform: uppercase;
    opacity: 0.7;
  }
`

export const NutritionistText = styled.p`
  padding: 10px;
  margin-bottom: 15px;
  opacity: 0.9;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  background-color: ${props => (props.bg ? props.bg : '')};
`
