import styled from 'styled-components/macro'
import { Color, MediaQuery } from '../../utils/constants'

export const Header = styled.div`
  /* margin-bottom: 40px; */
  display: flex;
  justify-content: space-between;
  ${MediaQuery.M_AND_DOWN} {
    flex-direction: column;
  }
`
export const HeaderTitle = styled.div`
  display: flex;
  align-items: center;

  h3 {
    margin-right: 15px;
    text-transform: uppercase;
    font-weight: 300;
    opacity: 0.7;
    font-size: 16px;
    ${MediaQuery.M_AND_DOWN} {
      margin-bottom: 10px;
    }
  }
  span {
    font-size: 10px;
    opacity: 0.5;
  }
`

export const HeaderRight = styled.div`
  display: flex;
  align-items: center;

  p {
    font-size: 10px;
    color: ${Color.DEEP_SEA};
    margin-right: 40px;
  }
  button {
    margin-right: 10px;
  }
  /* ${MediaQuery.M_AND_DOWN} {
    flex-direction: column;
    align-items: flex-start;
  } */
`

export const EditIcon = styled.span`
  cursor: pointer;
  margin-left: 5px;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 20px;
  min-height: 20px;
  border-radius: 100%;
  background-color: #333;
  color: white;
`

export const Hr = styled.hr`
  margin: 15px 0;
  width: 100%;
  border: 0 dotted;
  height: 1px;
  background: #eee;
`

export const Wrapper = styled.div`
  margin: 0px 60px 40px 60px;
  background-color: white;
  padding: 30px 20px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  ${MediaQuery.L_AND_DOWN} {
    margin: 0px 15px 10px 15px;
  }
`

export const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 250px);
  grid-gap: 15px;
  margin-bottom: -15px;

  div {
    border-bottom: 1px dotted #999;
  }
  label {
    display: block;
    margin-bottom: 5px;
    font-size: 11px;
    opacity: 0.5;
  }
  p {
    font-size: 14px;
    margin-bottom: 10px;
  }
`
