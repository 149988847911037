import styled from 'styled-components/macro'
import { Color } from '../../../utils/constants'

export const Close = styled.div`
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 30px;
`

export const Container = styled.div``

export const Recommended = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  background-color: ${Color.DEEP_SEA};
  color: white;
  padding: 4px 10px;
  font-weight: 300;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
`

export const Title = styled.h3`
  font-size: 20px;
  text-align: center;
  margin-bottom: 40px;
`
export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Content = styled.div`
  border: ${props => (props.border ? props.border : '')};
  background-color: ${props => (props.bg ? props.bg : '#F7F7F7')};
  padding: 40px 20px 40px 20px;
  border-radius: 1px;
  width: 46%;
  position: relative;
`

export const Img = styled.img`
  width: 60px;
  height: 60px;
  margin-bottom: 10px;
  border-radius: 100%;
`
export const ContentTitle = styled.h3`
  color: ${props => (props.color ? props.color : 'black')};
  font-size: 20px;
`
export const ContentText = styled.p`
  font-size: 13px;
  margin-bottom: 5px;
  color: ${props => (props.color ? props.color : 'black')};
`

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`

export const ContentSubTitle = styled.div`
  display: flex;
  margin-top: 8px;
  font-size: 12px;
  color: ${props => (props.color ? props.color : 'black')};
  span {
    margin-top: 1px;
    margin-right: 3px;
  }
`

export const ButtonWrapper = styled.div`
  margin-top: 20px;
  text-align: center;
  button {
    white-space: nowrap;
    font-size: 13px;
  }
  span {
    color: ${Color.DEEP_SEA};
    font-size: 9px;
  }
`
