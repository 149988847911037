import { INITIAL_ACTION_STATE } from '../../utils/constants'

const state = {
  list: [],
  actions: {
    fetch: { ...INITIAL_ACTION_STATE },
    cancel: { ...INITIAL_ACTION_STATE },
    addProduct: { ...INITIAL_ACTION_STATE },
    removeProduct: { ...INITIAL_ACTION_STATE },
    changeAddress: { ...INITIAL_ACTION_STATE },
    changeDeliveryDate: { ...INITIAL_ACTION_STATE },
    addDiscount: { ...INITIAL_ACTION_STATE },
    removeDiscount: { ...INITIAL_ACTION_STATE },
    switchOrderInterval: { ...INITIAL_ACTION_STATE },
    changeQuantity: { ...INITIAL_ACTION_STATE },
    create: { ...INITIAL_ACTION_STATE },
  },
}

export default state
