const IconNutritionist = () => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 84.301 104.474'>
    <g
      id='Groupe_1043'
      data-name='Groupe 1043'
      transform='translate(0.181 0.159)'
    >
      <path
        id='Tracé_686'
        data-name='Tracé 686'
        d='M79.672,36.212c-4.125-4.776-9.335-7.6-14.98-8.032a22.1,22.1,0,0,0-12.374,3.256,13.44,13.44,0,0,1-4.125,1.52c.217-3.256,1.085-10.421,7.164-15.414a2.335,2.335,0,0,0,.434-3.039,2.335,2.335,0,0,0-3.039-.434,23.121,23.121,0,0,0-4.342,4.993A27.82,27.82,0,0,0,19.1.175a2.2,2.2,0,0,0-1.52.868,2.067,2.067,0,0,0-.434,1.52A27.633,27.633,0,0,0,44.72,27.745a24.205,24.205,0,0,0-.651,4.993,7.612,7.612,0,0,1-3.039-1.3A21.242,21.242,0,0,0,28.655,28.18a21.2,21.2,0,0,0-14.98,8.032c-6.513,7.6-9.769,19.538-8.9,31.7,1.737,21.058,14.98,37.123,29.742,36.255a21.88,21.88,0,0,0,7.381-1.954,10.125,10.125,0,0,1,9.335,0,17.8,17.8,0,0,0,7.381,1.954h1.3c14.328,0,26.92-15.848,28.439-36.255C89.442,55.751,86.185,43.81,79.672,36.212ZM21.708,4.3h0A23.537,23.537,0,0,1,44.937,23.4h0A23.537,23.537,0,0,1,21.708,4.3ZM84.231,67.691c-1.52,18.453-12.592,33-25.183,32.13a19.5,19.5,0,0,1-5.862-1.52,14.546,14.546,0,0,0-6.73-1.52,14.546,14.546,0,0,0-6.73,1.52,13.042,13.042,0,0,1-5.862,1.52c-12.374.868-23.663-13.677-25.183-32.13C8.248,56.4,11.07,45.764,16.932,39.034a18.4,18.4,0,0,1,11.94-6.513,16.611,16.611,0,0,1,9.769,2.605,16.3,16.3,0,0,0,16.065,0,16.216,16.216,0,0,1,9.769-2.605c4.342.217,8.467,2.605,11.94,6.513C82.278,45.764,85.1,56.4,84.231,67.691Z'
        transform='translate(-4.636 -0.036)'
        fill='#02865b'
        stroke='rgba(2,134,91,0.25)'
        stroke-width='0.3'
      />
      <path
        id='Tracé_687'
        data-name='Tracé 687'
        d='M35.215,19.489A2.11,2.11,0,0,0,35,22.528c4.342,4.993,6.513,13.46,5.862,22.361a2.362,2.362,0,0,0,1.954,2.388h.217A2.142,2.142,0,0,0,45.2,45.323c.868-9.986-1.737-19.756-6.73-25.4C37.386,18.838,36.084,18.621,35.215,19.489Z'
        transform='translate(30.269 22.114)'
        fill='#02865b'
        stroke='rgba(2,134,91,0.25)'
        stroke-width='0.3'
      />
    </g>
  </svg>
)

export default IconNutritionist
