import React, { useState, useEffect } from 'react'
import {
  Wrapper,
  Content,
  Header,
  OrderSummary,
  OrderSummaryTitle,
  OrderSummaryLine,
  Line,
  HeaderTitle,
  EditIcon,
  Prices,
  TotalPriceDiscount,
  TotalPrice,
  Layer,
  LayerContent,
  SpinnerWrapper,
  NoOrders,
  // FreeShipping,
} from './style'
import { AiOutlineEdit } from 'react-icons/ai'
import {
  useChangeDeliveryDateSubscription,
  useSubscription,
  UseCreateSubscription,
} from '../../../hooks'
import RenewalDateDialog from '../renewal-date-dialog'
import ProgramOrderList from './program-order-list'
import PromoCodeLine from './promo-code-line'
import { Tooltip, Spinner, Button } from '../../ui'
import { useSubscriptions, useFetchSubscriptions } from '../../../hooks'
import moment from 'moment'
import { useQuery } from 'react-query'
import { getDiscount } from '../../../service/ReCharge/discount'

const MyProgram = () => {
  const { list } = useSubscriptions()
  const { fetch, isLoading, isSuccess, isError } = useFetchSubscriptions()

  useEffect(() => {
    !list.length && !isLoading && !isSuccess && !isError && fetch()
  }, [fetch, isError, isLoading, isSuccess, list.length])

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {list.map(subscription => {
            if (subscription?.nextChargedScheduledAt)
              return (
                <ProgramItem
                  subscription={subscription}
                  active={subscription.products.length === 0 ? true : false}
                />
              )
          })}
        </>
      )}
      {!isLoading && !list.length && (
        <NoOrders>Vous n'avez pas de programme</NoOrders>
      )}
    </>
  )
}

export default MyProgram

const ProgramItem = ({ subscription, active }) => {
  const { data: discount } = useQuery(
    ['DISCOUNT', subscription?.address?.discount_id],
    () => getDiscount(subscription?.address?.discount_id),
    {
      enabled: !!subscription?.address?.discount_id,
    }
  )
  const [isProgramUpdateDialogOpen, setIsProgramUpdateDialogOpen] =
    useState(false)
  const totalPrice = subscription.products
    ?.reduce((a, b) => +a + +b.price * b.quantity, 0)
    .toFixed(2)
  const discounValue = discount?.data?.discount?.value || 0

  let totalPriceDiscount = 0
  if (discount?.data?.discount?.discount_type === 'fixed_amount') {
    totalPriceDiscount = (totalPrice - discounValue).toFixed(2)
  } else {
    totalPriceDiscount = (
      totalPrice -
      totalPrice * (discounValue / 100)
    ).toFixed(2)
  }

  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const { change } = useChangeDeliveryDateSubscription()
  const { cancel } = useSubscription()
  const { create, isLoading } = UseCreateSubscription()

  const cancelled_at = subscription?.inactiveProducts[0]?.cancelled_at

  return (
    <Wrapper>
      {active && (
        <Layer>
          {isLoading ? (
            <SpinnerWrapper>
              <Spinner />
            </SpinnerWrapper>
          ) : (
            <LayerContent>
              <Button
                appearance='primary'
                onClick={() => setIsProgramUpdateDialogOpen(true)}
                style={{ whiteSpace: 'nowrap' }}
              >
                Mettre à jour mon programme
              </Button>
              <Button
                style={{ whiteSpace: 'nowrap', backgroundColor: 'white' }}
                isLoading={isLoading}
                onClick={() => {
                  const lastOrder = subscription.inactiveProducts.filter(p => {
                    return p.cancelled_at === cancelled_at
                  })
                  const createSubscriptionsToSend = lastOrder.map(product => {
                    console.log('product', product)
                    return {
                      address_id: product?.address_id,
                      charge_interval_frequency:
                        product.charge_interval_frequency,
                      next_charge_scheduled_at: moment(),
                      order_interval_frequency:
                        product.order_interval_frequency,
                      order_interval_unit: product.order_interval_unit,
                      quantity: product.quantity,
                      shopify_variant_id: product.shopify_variant_id,
                      shopify_product_id: product.shopify_product_id,
                    }
                  })

                  create(subscription, createSubscriptionsToSend)
                }}
              >
                Réactiver ma dernière cure
              </Button>
            </LayerContent>
          )}
        </Layer>
      )}
      {/* <PauseSubscription /> */}
      <RenewalDateDialog
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        subscription={subscription}
        changeDate={(subscriptionId, subscriptions) => {
          change(subscriptionId, subscriptions)
        }}
        cancelSubscription={subscription => {
          cancel(subscription)
        }}
      />
      <Header>
        <HeaderTitle>
          <h3>Mon programme</h3>
          <p onClick={() => setIsDialogOpen(true)}>
            Votre commande se renouvelle automatiquement le{' '}
            {subscription?.products[0]?.next_charge_scheduled_at
              ? `${
                  subscription?.products[0]?.next_charge_scheduled_at
                    .split('T')[0]
                    .split('-')[2]
                }/${
                  subscription?.products[0]?.next_charge_scheduled_at
                    .split('T')[0]
                    .split('-')[1]
                }/${
                  subscription?.products[0]?.next_charge_scheduled_at
                    .split('T')[0]
                    .split('-')[0]
                }`
              : '.. /.. / ..'}
            {/* <Tooltip label='Changer le date de renouvellement de votre programme'>
              <EditIcon>
                <AiOutlineEdit />
              </EditIcon>
            </Tooltip> */}
          </p>
        </HeaderTitle>
      </Header>
      <Content>
        <ProgramOrderList
          subscription={subscription}
          isProgramUpdateDialogOpen={isProgramUpdateDialogOpen}
          setIsProgramUpdateDialogOpen={setIsProgramUpdateDialogOpen}
          cancelled_at={cancelled_at}
        />
        <OrderSummary>
          <OrderSummaryTitle>Résumé de la commande</OrderSummaryTitle>
          <OrderSummaryLine>
            <span>Vitamines & compléments</span>
            <hr></hr>
            <span>{totalPrice}€</span>
          </OrderSummaryLine>
          {/* <OrderSummaryLine>
            <span>
              Livraison
            </span>
            <hr></hr>
            <span>Offerte - Délai de 3 à 5 jours</span>
          </OrderSummaryLine> */}
          <OrderSummaryLine>
            <span>Sous-total</span>
            <hr></hr>
            <span>{totalPrice}€</span>
          </OrderSummaryLine>
          <OrderSummaryLine>
            <PromoCodeLine
              subscription={subscription}
              discountCodeName={subscription.address?.discount?.code}
            />
          </OrderSummaryLine>
          <Line></Line>
          <OrderSummaryLine
            style={{
              fontWeight: 'bold',
              fontSize: '16px',
            }}
          >
            <span>Total</span>
            <Prices>
              <TotalPriceDiscount>
                {totalPriceDiscount >= 0 ? `${totalPriceDiscount}€` : '0.00€'}
              </TotalPriceDiscount>
              {totalPriceDiscount !== totalPrice && (
                <TotalPrice crossOut>{totalPrice}€ </TotalPrice>
              )}
            </Prices>
          </OrderSummaryLine>
          {/* <Button>Commander maintenant</Button> */}
        </OrderSummary>
      </Content>
    </Wrapper>
  )
}
