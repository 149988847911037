import { INITIAL_ACTION_STATE } from '../../utils/constants'

const state = {
  current: null,
  customerShopify: null,
  tokens: {
    shopify: null,
    shopifyExpiry: null,
    recharge: null,
  },
  actions: {
    login: { ...INITIAL_ACTION_STATE },
    logout: { ...INITIAL_ACTION_STATE },
    register: { ...INITIAL_ACTION_STATE },
    getCustomer: { ...INITIAL_ACTION_STATE },
    updateCustomer: { ...INITIAL_ACTION_STATE },
    cutomerRecover: { ...INITIAL_ACTION_STATE },
  },
}

export default state
