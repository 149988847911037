import { AuthUser } from '../../utils/classes'

export const getAuthUser = ({ auth }) =>
  auth.current ? new AuthUser(auth.current) : null
export const getCustomerShopify = ({ auth }) => auth.current

export const getLoginStatus = ({ auth }) => auth.actions.login.status
export const getRegisterStatus = ({ auth }) => auth.actions.register.status
export const getCustomerStatus = ({ auth }) => auth.actions.getCustomer.status
export const getCustomerRecoverStatus = ({ auth }) =>
  auth.actions.cutomerRecover.status
export const updateCustomerStatus = ({ auth }) =>
  auth.actions.updateCustomer.status
export const getRegisterError = ({ auth }) => auth.actions.register.error
export const getRechargeToken = ({ auth }) => auth.tokens.recharge
export const getShopifyToken = ({ auth }) => auth.tokens.shopify
export const getTempToken = ({ auth }) => auth.tokens.temp_token
export const getTempTokenExpiresAt = ({ auth }) =>
  auth.tokens.temp_token_expires_at
