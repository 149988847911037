const IconCart = () => (
  <svg viewBox='0 0 491 491'>
    <g
      // id='Groupe_777'
      // data-name='Groupe 777'
      transform='translate(-1354.783 -55)'
    >
      <g
        // id='Groupe_725'
        // data-name='Groupe 725'
        transform='translate(1354.784 55)'
      >
        <g id='Groupe_722' data-name='Groupe 722' transform='translate(0)'>
          <g id='Groupe_721' data-name='Groupe 721' transform='translate(0)'>
            <g id='Groupe_716' data-name='Groupe 716' transform='translate(0)'>
              <g
                id='Ellipse_51'
                data-name='Ellipse 51'
                transform='translate(0)'
                fill='none'
                stroke='#000'
                stroke-width='10'
              >
                <circle cx='245.5' cy='245.5' r='245.5' stroke='none' />
                <circle cx='245.5' cy='245.5' r='240.5' fill='none' />
              </g>
            </g>
          </g>
          <g
            // id='noun_basket_1487352'
            transform='translate(104.531 114.668)'
          >
            <g
              // id='Groupe_720'
              // data-name='Groupe 720'
              transform='translate(0 0)'
            >
              <path
                // id='Tracé_666'
                // data-name='Tracé 666'
                d='M24.375,111.27H262.13a.892.892,0,0,0,.611-.278,1.025,1.025,0,0,0,.278-.666V90.116a.92.92,0,0,0-.278-.666.892.892,0,0,0-.611-.278H19.323a.948.948,0,0,0-.944.944v20.211a.92.92,0,0,0,.278.666h0a.92.92,0,0,0,.666.278Zm174.9-40.477L170.515,13.27a9.163,9.163,0,1,1,16.38-8.218l32.87,65.741H262.13a19.336,19.336,0,0,1,19.322,19.322v20.211a19.275,19.275,0,0,1-16.6,19.1l-20.6,123.986a9.124,9.124,0,0,1-9.05,7.718v.055H46.2a9.271,9.271,0,0,1-9.162-8.384L16.546,129.427a19.24,19.24,0,0,1-10.883-5.441v-.056A19.23,19.23,0,0,1,0,110.326V90.116A19.336,19.336,0,0,1,19.322,70.793h42.31L94.5,5.052A9.263,9.263,0,0,1,106.829.944a9.193,9.193,0,0,1,4.053,12.326L82.12,70.793H199.277ZM140.7,129.649H35.2l18.767,113.1H227.428l18.823-113.1H140.7Z'
                transform='translate(0 0)'
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default IconCart
