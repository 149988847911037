import { gql } from '@apollo/client'

export const USER_ORDERS = gql`
  query getUserOrders($customerAccessToken: String!) {
    customer(customerAccessToken: $customerAccessToken) {
      orders(first: 25, reverse: true) {
        edges {
          node {
            totalPriceV2 {
              amount
            }
            processedAt
            statusUrl
            financialStatus
            lineItems(first: 10) {
              edges {
                node {
                  title
                  quantity
                  variant {
                    title
                    image {
                      src
                    }
                    priceV2 {
                      amount
                    }
                    product {
                      handle
                      title
                      metafield(
                        key: "recoproductwhy"
                        namespace: "cy-product"
                      ) {
                        value
                      }
                    }
                  }
                }
              }
            }
            successfulFulfillments {
              trackingCompany
              trackingInfo {
                url
                number
              }
            }
          }
        }
      }
    }
  }
`
