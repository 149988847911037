import styled from 'styled-components/macro'
import { Color, MediaQuery } from '../../../utils/constants'

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0px 60px 40px 60px;
  ${MediaQuery.L_AND_DOWN} {
    margin: 0px 15px 10px 15px;
    flex-direction: column;
  }
`

export const ChatWrapper = styled.div`
  width: 55%;
  background-color: white;
  padding: 30px 20px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  ${MediaQuery.L_AND_DOWN} {
    width: 100%;
  }
`

export const NutritionistWrapper = styled.div`
  width: 42%;

  background-color: white;
  padding: 30px 20px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);

  ${MediaQuery.L_AND_DOWN} {
    width: 100%;
    margin-bottom: 40px;
  }
`

export const Title = styled.h3`
  font-size: 19px;
  font-weight: 300;
  opacity: 0.6;
`
export const Hr = styled.hr`
  margin: 30px 0;
  border-top: 0.5px solid #eee;
`
export const NutritionistIntro = styled.div`
  display: flex;
  align-items: center;
`
export const NutritionistImg = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 100%;
  margin-right: 20px;
`
export const NutritionistName = styled.p`
  font-size: 18px;
  margin-bottom: 5px;
`
export const NutritionistProfession = styled.p`
  font-size: 12px;
  text-transform: uppercase;
  opacity: 0.5;
`
export const NutritionistMessage = styled.div`
  margin: 15px 0 30px 0;
  background-color: ${Color.COLOR_SECONDARY};
  padding: 40px;
  font-size: 15px;
  font-weight: 300;
  p {
    margin-bottom: 10px;
  }
  ul {
    margin-left: 23px;
  }
`
