// import {useEffect} from 'react'
import { useSelector } from 'react-redux'

import { useDispatch } from './index'
import { ActionType } from '../utils/constants'
// // import { toast } from 'react-toastify'
// import 'react-toastify/dist/ReactToastify.css';
import {
  switchOrderIntervalSubscription,
  getSubscriptions,
  getRemoveProductStatus,
  getFetchSubscriptionsStatus,
  addProductSubscriptionsStatus,
  changeDeliveryDateSubscriptionsStatus,
  getFetchSubscriptionsError,
  fetchAll,
  addProductToSubscription,
  removeProductFromSubscription,
  changeAddressSubscription,
  changeAddressSubscriptionsStatus,
  changeDeliveryDateSubscription,
  getSwitchOrderIntervalError,
  cancel,
  getCancelStatus,
  getSwitchOrderIntervalStatus,
  getCancelError,
  removeDiscountToSubscription,
  addDiscountToSubscription,
  removeDiscountSubscriptionsStatus,
  addDiscountSubscriptionsStatus,
  changeQuantitySubscriptionsStatus,
  changeQuantitySubscription,
  createSubscriptionsStatus,
  createSubscription,
} from '../store/subscriptions'
import { getRechargeToken, getTempToken, getAuthUser } from '../store/auth-user'

export const useSubscriptions = () => {
  const { list, allProducts } = useSelector(getSubscriptions)

  return { list, allProducts }
}

export const useSubscription = () => {
  const dispatch = useDispatch()
  const cancelStatus = useSelector(getCancelStatus)
  const cancelError = useSelector(getCancelError)
  const token = useSelector(getRechargeToken)
  const tempToken = useSelector(getTempToken)
  const authUser = useSelector(getAuthUser)

  return {
    cancel: (subscription, cancellationReason) =>
      dispatch(cancel(authUser.data.session, subscription, cancellationReason)),
    error: cancelError,
    isError: cancelStatus === ActionType.FAILED,
    isLoading: cancelStatus === ActionType.LOADING,
    isSuccess: cancelStatus === ActionType.SUCCESS,
  }
}

export const useSwitchOrderIntervalSubscription = () => {
  const dispatch = useDispatch()
  const switchOrderIntervalStatus = useSelector(getSwitchOrderIntervalStatus)
  const switchOrderIntervalError = useSelector(getSwitchOrderIntervalError)
  const token = useSelector(getRechargeToken)
  const tempToken = useSelector(getTempToken)
  const authUser = useSelector(getAuthUser)

  return {
    switchOrderInterval: (subscription, createSubscriptionsToSend, interval) =>
      dispatch(
        switchOrderIntervalSubscription(
          authUser.data.session,
          subscription,
          createSubscriptionsToSend,
          interval
        )
      ),
    error: switchOrderIntervalError,
    isError: switchOrderIntervalStatus === ActionType.FAILED,
    isLoading: switchOrderIntervalStatus === ActionType.LOADING,
    isSuccess: switchOrderIntervalStatus === ActionType.SUCCESS,
  }
}

export const useFetchSubscriptions = () => {
  const dispatch = useDispatch()
  const fetchStatus = useSelector(getFetchSubscriptionsStatus)
  const fetchError = useSelector(getFetchSubscriptionsError)
  const token = useSelector(getRechargeToken)
  const tempToken = useSelector(getTempToken)
  const authUser = useSelector(getAuthUser)

  return {
    error: fetchError,
    fetch: () =>
      dispatch(fetchAll(token, tempToken, authUser?.data?.session?.customerId)),
    isError: fetchStatus === ActionType.FAILED,
    isLoading: fetchStatus === ActionType.LOADING,
    isSuccess: fetchStatus === ActionType.SUCCESS,
  }
}

export const useChangeAddressSubscription = () => {
  const dispatch = useDispatch()
  const fetchStatus = useSelector(changeAddressSubscriptionsStatus)
  // const fetchError = useSelector()
  const token = useSelector(getRechargeToken)
  const tempToken = useSelector(getTempToken)
  const authUser = useSelector(getAuthUser)

  return {
    // error: fetchError,
    change: data =>
      dispatch(changeAddressSubscription(authUser.data.session, data)),
    isError: fetchStatus === ActionType.FAILED,
    isLoading: fetchStatus === ActionType.LOADING,
    isSuccess: fetchStatus === ActionType.SUCCESS,
  }
}

export const useChangeDeliveryDateSubscription = () => {
  const dispatch = useDispatch()
  const fetchStatus = useSelector(changeDeliveryDateSubscriptionsStatus)
  // const fetchError = useSelector()
  const token = useSelector(getRechargeToken)
  const tempToken = useSelector(getTempToken)
  const authUser = useSelector(getAuthUser)

  return {
    // error: fetchError,
    change: (subscriptionId, subscriptions) =>
      dispatch(
        changeDeliveryDateSubscription(
          authUser.data.session,
          subscriptionId,
          subscriptions
        )
      ),
    isError: fetchStatus === ActionType.FAILED,
    isLoading: fetchStatus === ActionType.LOADING,
    isSuccess: fetchStatus === ActionType.SUCCESS,
  }
}

export const useAddProductToSubscription = () => {
  const dispatch = useDispatch()
  const fetchStatus = useSelector(addProductSubscriptionsStatus)
  // const fetchError = useSelector()
  const token = useSelector(getRechargeToken)
  const tempToken = useSelector(getTempToken)
  const authUser = useSelector(getAuthUser)

  return {
    // error: fetchError,
    add: (data, interval) =>
      dispatch(addProductToSubscription(authUser.data.session, data, interval)),
    isError: fetchStatus === ActionType.FAILED,
    isLoading: fetchStatus === ActionType.LOADING,
    isSuccess: fetchStatus === ActionType.SUCCESS,
  }
}

export const useRemoveProductToSubscription = () => {
  const dispatch = useDispatch()
  const fetchStatus = useSelector(getRemoveProductStatus)
  // const fetchError = useSelector()
  const token = useSelector(getRechargeToken)
  const tempToken = useSelector(getTempToken)

  return {
    // error: fetchError,
    remove: rechargeSubscriptionId =>
      dispatch(
        removeProductFromSubscription(token, tempToken, rechargeSubscriptionId)
      ),
    isError: fetchStatus === ActionType.FAILED,
    isLoading: fetchStatus === ActionType.LOADING,
    isSuccess: fetchStatus === ActionType.SUCCESS,
  }
}

export const useAddDiscountToSubscription = () => {
  const dispatch = useDispatch()
  const fetchStatus = useSelector(addDiscountSubscriptionsStatus)
  // const fetchError = useSelector()
  const token = useSelector(getRechargeToken)
  const tempToken = useSelector(getTempToken)

  return {
    // error: fetchError,
    add: (discountCode, subscriptionId) =>
      dispatch(
        addDiscountToSubscription(
          token,
          tempToken,
          discountCode,
          subscriptionId
        )
      ),
    isError: fetchStatus === ActionType.FAILED,
    isLoading: fetchStatus === ActionType.LOADING,
    isSuccess: fetchStatus === ActionType.SUCCESS,
  }
}

export const useRemoveDiscountToSubscription = () => {
  const dispatch = useDispatch()
  const fetchStatus = useSelector(removeDiscountSubscriptionsStatus)
  // const fetchError = useSelector()
  const token = useSelector(getRechargeToken)
  const tempToken = useSelector(getTempToken)

  return {
    // error: fetchError,
    remove: subscriptionId =>
      dispatch(removeDiscountToSubscription(token, tempToken, subscriptionId)),
    isError: fetchStatus === ActionType.FAILED,
    isLoading: fetchStatus === ActionType.LOADING,
    isSuccess: fetchStatus === ActionType.SUCCESS,
  }
}

export const UseChangeQuantitySubscription = () => {
  const dispatch = useDispatch()
  const fetchStatus = useSelector(changeQuantitySubscriptionsStatus)
  // const fetchError = useSelector()
  const token = useSelector(getRechargeToken)
  const tempToken = useSelector(getTempToken)
  const authUser = useSelector(getAuthUser)

  return {
    // error: fetchError,
    change: (id, qty) =>
      dispatch(changeQuantitySubscription(authUser.data.session, id, qty)),
    isError: fetchStatus === ActionType.FAILED,
    isLoading: fetchStatus === ActionType.LOADING,
    isSuccess: fetchStatus === ActionType.SUCCESS,
  }
}

export const UseCreateSubscription = () => {
  const dispatch = useDispatch()
  const fetchStatus = useSelector(createSubscriptionsStatus)
  // const fetchError = useSelector()
  const token = useSelector(getRechargeToken)
  const tempToken = useSelector(getTempToken)
  const authUser = useSelector(getAuthUser)

  return {
    // error: fetchError,
    create: (subscription, createSubscriptionsToSend) =>
      dispatch(
        createSubscription(
          authUser.data.session,
          subscription,
          createSubscriptionsToSend
        )
      ),
    isError: fetchStatus === ActionType.FAILED,
    isLoading: fetchStatus === ActionType.LOADING,
    isSuccess: fetchStatus === ActionType.SUCCESS,
  }
}
