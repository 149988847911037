import styled from 'styled-components/macro'
import { Color } from '../../../utils/constants'

export const Close = styled.div`
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 30px;
`

export const Title = styled.h3`
  font-size: 25px;
  color: ${Color.DEEP_SEA};
  text-align: center;
  margin-bottom: 30px;
  font-weight: 300;
`

export const Reasons = styled.div`
  cursor: pointer;
  margin: 0px auto 20px auto;
  width: 100%;
`

export const Reason = styled.div`
  margin: 0 auto;
  text-align: center;
  padding: 10px;
  background-color: #eee;
  margin-bottom: 20px;
  width: 80%;
  background-color: ${props => (props.selected ? '#cecece' : '')};
`

export const Input = styled.input`
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 10px;
  background-color: #eee;
  width: 80%;
  margin: 0 auto 20px auto;
  border: none;
  outline: none;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`
