import beauty from '../../assets/images/Objectifs/Beauté_vert.svg'
import Articulation from '../../assets/images/Objectifs/Articulation_vert.svg'
import Bio from '../../assets/images/Objectifs/Bio_vert.svg'
import Cerveau from '../../assets/images/Objectifs/Cerveau_vert.svg'
import Cheveux from '../../assets/images/Objectifs/Cheveux_vert.svg'
import Energie from '../../assets/images/Objectifs/Energie_vert.svg'
import Gluten from '../../assets/images/Objectifs/Gluten_vert.svg'
import Grossesse from '../../assets/images/Objectifs/Grossesse_vert.svg'
import Immunité from '../../assets/images/Objectifs/Immunité_vert.svg'
import naturel from '../../assets/images/Objectifs/naturel_vert.svg'
import Objectif from '../../assets/images/Objectifs/Objectif_vert.svg'
import Os from '../../assets/images/Objectifs/Os_vert.svg'
import Pasbio from '../../assets/images/Objectifs/Pasbio_vert.svg'
import Pasgluten from '../../assets/images/Objectifs/Pasgluten_vert.svg'
import Paspoisson from '../../assets/images/Objectifs/Paspoisson_vert.svg'
import Poids from '../../assets/images/Objectifs/Poids_vert.svg'
import Poisson from '../../assets/images/Objectifs/Poisson_vert.svg'
import sansogm from '../../assets/images/Objectifs/sansogm_vert.svg'
import Sansveggie from '../../assets/images/Objectifs/Sansveggie_vert.svg'
import Soleil from '../../assets/images/Objectifs/Soleil_vert.svg'
import Sommeil from '../../assets/images/Objectifs/Sommeil_vert.svg'
import Sport from '../../assets/images/Objectifs/Sport_vert.svg'
import Stress from '../../assets/images/Objectifs/Stress_vert.svg'
import Vegan from '../../assets/images/Objectifs/Vegan_vert.svg'
import Veggie from '../../assets/images/Objectifs/Veggie_vert.svg'

export const goalIcons = [
  {
    title: 'Beauté',
    icon: beauty,
    handles: [
      'collagene',
      'complexe-cheveux-ongles',
      'bourrache-onagre-bio',
      'capital-peau',
    ],
  },
  {
    title: 'Articulation',
    icon: Articulation,
    handles: [
      'complexe-de-magnesium',
      'curcuma',
      'proteines-vegetales',
      'pidolate-de-zinc',
      'vitamine-c-liposomale',
      'vitamine-b12',
    ],
  },
  {
    title: 'Bio',
    icon: Bio,
    handles: [],
  },
  {
    title: 'Cerveau',
    icon: Cerveau,
    handles: [],
  },
  {
    title: 'Cheveux',
    icon: Cheveux,
    handles: ['complexe-cheveux-ongles', 'pidolate-de-zinc'],
  },
  {
    title: 'Energie',
    icon: Energie,
    handles: ['complexe-energy'],
  },
  {
    title: 'Gluten',
    icon: Gluten,
    handles: [],
  },
  {
    title: 'Grossesse',
    icon: Grossesse,
    handles: [
      'complexe-de-magnesium',
      'vitamine-d',
      'vitamine-c-liposomale',
      'omega-3-epa-dha',
    ],
  },
  {
    title: 'Immunité',
    icon: Immunité,
    handles: [
      'probiotique',
      'pidolate-de-zinc',
      'vitamine-c-liposomale',
      'omega-3-epa-dha',
      'omega-3-veggie',
      'vitamine-d',
      'pidolate-de-fer',
    ],
  },
  {
    title: 'naturel',
    icon: naturel,
    handles: [],
  },
  {
    title: 'Objectif',
    icon: Objectif,
    handles: [],
  },
  {
    title: 'Os',
    icon: Os,
    handles: [],
  },
  {
    title: 'Pasbio',
    icon: Pasbio,
    handles: [],
  },
  {
    title: 'Pasgluten',
    icon: Pasgluten,
    handles: [],
  },
  {
    title: 'Poids',
    icon: Poids,
    handles: ['complexe-drainage'],
  },
  {
    title: 'Poisson',
    icon: Poisson,
    handles: [],
  },
  {
    title: 'sansogm',
    icon: sansogm,
    handles: [],
  },
  {
    title: 'Sansveggie',
    icon: Sansveggie,
    handles: [],
  },
  {
    title: 'Soleil',
    icon: Soleil,
    handles: [],
  },
  {
    title: 'Sommeil',
    icon: Sommeil,
    handles: ['spray-de-melatonine', 'ashwagandha-extra-bio'],
  },
  {
    title: 'Sport',
    icon: Sport,
    handles: [
      'complexe-de-magnesium',
      'curcuma',
      'proteines-vegetales',
      'pidolate-de-zinc',
      'vitamine-c-liposomale',
      'vitamine-b12',
    ],
  },
  {
    title: 'Stress',
    icon: Stress,
    handles: ['complexe-relax', 'ashwagandha-extra-bio'],
  },
  {
    title: 'Vegan',
    icon: Vegan,
    handles: [],
  },
  {
    title: 'Veggie',
    icon: Veggie,
    handles: [],
  },
  {
    title: 'Paspoisson',
    icon: Paspoisson,
    handles: [],
  },
]

export const focusGoals = [
  {
    name: 'sommeil',
    icon: Sommeil,
  },
  {
    name: 'immunite',
    icon: Immunité,
  },
  { name: 'cheveux', icon: Cheveux },
  { name: 'digestion', icon: '' },
  { name: 'energie', icon: Energie },
  { name: 'stress', icon: Stress },
  { name: 'cerveau', icon: Cerveau },
  { name: 'poids', icon: Poids },
  { name: 'articulations', icon: Articulation },
  { name: 'peau', icon: '' },
  { name: 'detox', icon: Energie },
]
