import axios from 'axios'

export const applydiscountToAnAddress = async (discountCode, addresseId) => {
  await axios.post('https://www.compliment.me/api/applydiscountToAnAddress', {
    discountCode: discountCode,
    addresseId: addresseId,
  })
}

export const getDiscount = async discountCodeId => {
  return await axios.post('https://www.compliment.me/api/getDiscount', {
    discountCodeId: discountCodeId,
  })
}

export const removeDiscount = async addresseId => {
  await axios.post('https://www.compliment.me/api/removeDiscount', {
    addresseId: addresseId,
  })
}
