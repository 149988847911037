import React from 'react'
import { Button } from '../../../ui'
import { ButtonWrapper, OrderList } from './style'
import OrderItem from '../../order-item'
import ProgramUpdateDialog from '../../program-update-dialog'
import {
  useRemoveProductToSubscription,
  UseChangeQuantitySubscription,
  useSubscriptions,
} from '../../../../hooks'

const ProgramOrderList = ({
  subscription,
  isProgramUpdateDialogOpen,
  setIsProgramUpdateDialogOpen,
  cancelled_at,
}) => {
  const { allProducts } = useSubscriptions()
  const { remove, isLoading: isRemoveProductLoading } =
    useRemoveProductToSubscription()
  const {
    change,
    isSuccess: isChangeQuantitySuccess,
    isLoading: isChangeQuantityLoading,
  } = UseChangeQuantitySubscription()

  const whichSubscription = subscription => {
    if (subscription?.products[0]?.variant_title === '1month_subscription') {
      return '1month_subscription'
    } else if (
      subscription?.products[0]?.variant_title === '3month_subscription'
    ) {
      return '3month_subscription'
    } else {
      return ''
    }
  }

  return (
    <OrderList>
      {subscription?.products?.map(product => {
        const p = allProducts?.find(
          i => i?.shopify_product_id === product?.shopify_product_id
        )

        return (
          <OrderItem
            change={change}
            charge_interval_frequency={product?.charge_interval_frequency}
            order_interval_unit={product?.order_interval_unit}
            isChangeQuantitySuccess={isChangeQuantitySuccess}
            isChangeQuantityLoading={isChangeQuantityLoading}
            isRemoveProductLoading={isRemoveProductLoading}
            abilityToHandle={true}
            abilityToDelete={true}
            image={p?.images?.small}
            title={product?.product_title}
            quantity={product?.quantity}
            price={product?.price}
            widthText='200px'
            handle={p?.handle}
            onProductRemove={() => {
              remove(product.id)
            }}
            subscription={subscription}
            id={product?.id}
            variant_title={product?.variant_title}
          />
        )
      })}
      {subscription?.products?.length === 0
        ? subscription?.inactiveProducts
            .filter(product => product.cancelled_at === cancelled_at)
            .map(product => {
              const p = allProducts?.find(
                i => i?.shopify_product_id === product?.shopify_product_id
              )

              return (
                <OrderItem
                  change={change}
                  charge_interval_frequency={product.charge_interval_frequency}
                  isChangeQuantitySuccess={isChangeQuantitySuccess}
                  isChangeQuantityLoading={isChangeQuantityLoading}
                  isRemoveProductLoading={isRemoveProductLoading}
                  abilityToHandle={true}
                  abilityToDelete={true}
                  image={p?.images?.small}
                  title={product?.product_title}
                  quantity={product?.quantity}
                  price={product?.price}
                  widthText='200px'
                  handle={p?.handle}
                  onProductRemove={() => {
                    remove(product?.id)
                  }}
                  subscription={subscription}
                  id={product?.id}
                  variant_title={product?.variant_title}
                />
              )
            })
        : ''}
      <ButtonWrapper>
        <Button
          appearance='primary'
          onClick={() => setIsProgramUpdateDialogOpen(true)}
        >
          Mettre à jour mon programme
        </Button>
      </ButtonWrapper>
      <ProgramUpdateDialog
        setIsDialogOpen={setIsProgramUpdateDialogOpen}
        isDialogOpen={isProgramUpdateDialogOpen}
        subscription={subscription}
        subscriptionTitle={whichSubscription(subscription)}
      />
    </OrderList>
  )
}

export default ProgramOrderList
