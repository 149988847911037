import { Link } from 'react-router-dom'
import Layout from '../../components/layout/default'
import LoginForm from '../../components/auth/login-form'
import { Wrapper, FooterText, Title, SwitchButton, Button } from './style'
import Faq from '../../components/auth/faq'
import RedirectionPro from '../../components/auth/redirection-pro'

const LoginPage = ({ location }) => (
  <Layout>
    <Title>Se connecter</Title>

    <SwitchButton>
      <Button actif>J'ai déja un compte</Button>

      <Link
        to={{
          pathname: '/register',
          search: location.search,
        }}
      >
        <Button>Céer un compte</Button>
      </Link>
    </SwitchButton>
    <RedirectionPro />
    <Wrapper>
      <LoginForm />
      <FooterText>
        <Link
          to={{
            pathname: '/initialize',
            search: location.search,
          }}
        >
          Mot de passe oublié ?
        </Link>
      </FooterText>
    </Wrapper>

    <Faq />
  </Layout>
)

export default LoginPage
