import cloneDeep from 'lodash.clonedeep'

import Constant from './constants'
import initialState from './state'
import { ActionType } from '../../utils/constants'

const fetchFailed = (state, action) => {
  const newState = cloneDeep(state)
  newState.actions.fetch = { status: ActionType.FAILED, error: action.error }

  return newState
}
const fetchLoading = state => {
  const newState = cloneDeep(state)
  newState.actions.fetch = { status: ActionType.LOADING }

  return newState
}
const fetchSuccess = (state, { payload }) => {
  const newState = cloneDeep(state)
  newState.list = [...payload.diagnostic]
  newState.actions.fetch = { status: ActionType.SUCCESS }

  return newState
}

const fetchLastDiagnosticFailed = (state, action) => {
  const newState = cloneDeep(state)
  newState.actions.fetchLastOne = {
    status: ActionType.FAILED,
    error: action.error,
  }

  return newState
}
const fetchLastDiagnosticLoading = state => {
  const newState = cloneDeep(state)
  newState.actions.fetchLastOne = { status: ActionType.LOADING }

  return newState
}
const fetchLastDiagnosticSuccess = (state, { payload }) => {
  const newState = cloneDeep(state)
  newState.lastOne = payload.diagnostic[0]
  newState.actions.fetchLastOne = { status: ActionType.SUCCESS }

  return newState
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Constant.FETCH_DIAGNOSTICS_FAILED:
      return fetchFailed(state, action)
    case Constant.FETCH_DIAGNOSTICS_LOADING:
      return fetchLoading(state)
    case Constant.FETCH_DIAGNOSTICS_SUCCESS:
      return fetchSuccess(state, action)

    case Constant.FETCH_LAST_DIAGNOSTICS_FAILED:
      return fetchLastDiagnosticFailed(state, action)
    case Constant.FETCH_LAST_DIAGNOSTICS_LOADING:
      return fetchLastDiagnosticLoading(state)
    case Constant.FETCH_LAST_DIAGNOSTICS_SUCCESS:
      return fetchLastDiagnosticSuccess(state, action)

    default:
      return state
  }
}
