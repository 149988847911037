import React, { useState } from 'react'
import {
  Wrapper,
  OrdersHistory,
  LastOrder,
  Title,
  Table,
  Hr,
  Flex,
  Buttons,
} from './style'
import { Button } from '../../ui'
// import { Color } from '../../../utils/constants'
import OrderDialog from '../order-dialog'
import { Spinner } from '../../ui'
import { useOrders } from '../../../hooks'
import { dateFormat } from '../../../utils/helpers'

const OrderHistory = () => {
  const [isOrderDialogOpen, setIsOrderDialogOpen] = useState(false)
  const { data } = useOrders()

  const selectProgramDuration = variantTitle => {
    switch (variantTitle) {
      case variantTitle?.includes('1month'):
        return '1 mois'
      case variantTitle?.includes('2month'):
        return '2 mois'
      case variantTitle?.includes('3month'):
        return '3 mois'
      default:
        return '1 mois'
    }
  }

  return (
    <Wrapper>
      <LastOrder>
        {!data?.length ? (
          <Spinner />
        ) : (
          <>
            <Title>Ma dernière commande</Title>
            <Hr></Hr>
            <Flex>
              <div>
                <h5>Statut</h5>
                <p>
                  {data[0]?.node?.financialStatus === 'PAID'
                    ? 'Payée'
                    : 'pas payée'}
                </p>
                <Hr></Hr>
              </div>
              {/* <div>
                <h5>Statut de livraison</h5>
                <p>En cours</p>
                <Hr></Hr>
              </div> */}
              <div>
                <h5>Type d'abonnement</h5>
                <p>
                  {selectProgramDuration(
                    data[0]?.node?.lineItems?.edges[0]?.node?.variant?.title
                  )}{' '}
                  de programme
                </p>
                <Hr></Hr>
              </div>
            </Flex>
            <Flex>
              <div>
                <h5>Total TTC</h5>
                <p>{data[0]?.node?.totalPriceV2.amount}€</p>
                <Hr></Hr>
              </div>
              {/* <div>
                <h5>Facture</h5>
                <Button
                  style={{ padding: '0' }}
                  // iconRight='arrow-right'
                  appearance='underline'
                  size='13px'
                >
                  Voir ma facture
                </Button>
                <Hr></Hr>
              </div> */}
            </Flex>

            <Buttons>
              {data[0]?.node?.successfulFulfillments[0]?.trackingInfo[0]
                ?.url ? (
                <Button size='13px' style={{ whiteSpace: 'nowrap' }}>
                  <a
                    href={
                      data[0]?.node?.successfulFulfillments[0]?.trackingInfo[0]
                        ?.url
                    }
                    rel='noreferrer'
                    target='_blank'
                  >
                    Traquer mon colis
                  </a>
                </Button>
              ) : (
                ''
              )}
              <Button
                size='13px'
                style={{ whiteSpace: 'nowrap' }}
                appearance='primary'
                onClick={() => setIsOrderDialogOpen(true)}
              >
                Revoir mon programme
              </Button>
              <OrderDialog
                isDialogOpen={isOrderDialogOpen}
                setIsDialogOpen={setIsOrderDialogOpen}
                lineItems={data[0]?.node?.lineItems}
              />
            </Buttons>
          </>
        )}
      </LastOrder>

      <OrdersHistory>
        {!data?.length ? (
          <Spinner />
        ) : (
          <>
            <Title>Mon historique commande</Title>
            <Hr></Hr>
            <Table>
              <tr>
                <th style={{ width: '20%' }}>
                  <span>Date</span>
                </th>
                <th style={{ width: '25%' }}>
                  <span>Statut de livraison</span>
                </th>
                <th style={{ width: '30%' }}>
                  <span>Programme</span>
                </th>
                <th style={{ width: '15%' }}>
                  <span>Prix</span>
                </th>
                <th style={{ width: '20%' }}></th>
              </tr>

              {data.map(order => {
                return <OrderLine order={order} />
              })}
            </Table>
          </>
        )}
      </OrdersHistory>
    </Wrapper>
  )
}

export default OrderHistory

const OrderLine = ({ order }) => {
  const [isOrderDialogOpen, setIsOrderDialogOpen] = useState(false)

  return (
    <tr>
      <OrderDialog
        isDialogOpen={isOrderDialogOpen}
        setIsDialogOpen={setIsOrderDialogOpen}
        lineItems={order?.node?.lineItems}
        createdAt={dateFormat(order?.node?.processedAt)}
      />
      <td style={{ width: '20%' }}>{dateFormat(order?.node?.processedAt)}</td>
      <td style={{ width: '25%' }}>
        {order?.node?.successfulFulfillments[0]?.trackingInfo[0]?.url ? (
          <Button style={{ padding: '0' }} appearance='underline' size='13px'>
            <a
              rel='noreferrer'
              href={
                order?.node?.successfulFulfillments[0]?.trackingInfo[0]?.url
              }
              target='_blank'
            >
              Traquer mon colis
            </a>
          </Button>
        ) : (
          '/'
        )}
      </td>
      <td style={{ width: '30%' }}>
        <Button
          onClick={() => setIsOrderDialogOpen(true)}
          style={{ padding: '0' }}
          appearance='underline'
          size='13px'
        >
          Voir programme
        </Button>
      </td>
      <td style={{ width: '15%' }}>{order?.node?.totalPriceV2?.amount}€</td>
      <td style={{ width: '20%' }}>
        {/* <Button
          style={{ padding: '0' }}
          color={Color.DEEP_SEA}
          iconRight='arrow-right'
          appearance='underline'
          size='13px'
        >
          Voir le facture
        </Button> */}
      </td>
    </tr>
  )
}
