export const getPaymentMethods = ({ paymentMethods }) => paymentMethods.list
export const getFetchPaymentMethodsStatus = ({ paymentMethods }) =>
  paymentMethods.actions.fetch.status
export const getFetchPaymentMethodsError = ({ paymentMethods }) =>
  paymentMethods.actions.fetch.error

export const getUpdatePaymentMethodsStatus = ({ paymentMethods }) =>
  paymentMethods.actions.updatePaymentMethod.status
export const getUpdatePaymentMethodsError = ({ paymentMethods }) =>
  paymentMethods.actions.updatePaymentMethod.error
