import React from 'react'

import {
  Container,
  ArrorBackIcon,
  CardLink,
  CardTitle,
  CardCta,
  CardLinkIconArrow,
} from './style'
import { NavWrapper, NavLinkArrow, IconArrow } from '../style'
import { navDisplay } from '../../../../utils/constants'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'

const PopupFaqNavMobileContent = ({ setNavBarContent }) => (
  <Container>
    <ArrorBackIcon onClick={() => setNavBarContent(navDisplay.MAINNAV)}>
      <IoIosArrowBack />
    </ArrorBackIcon>

    <NavWrapper>
      <NavLinkArrow href='tel:+3228087465'>
        (+32)2 808 74 65
        <IconArrow visibility='hidden'>
          <IoIosArrowForward />
        </IconArrow>
      </NavLinkArrow>
      <NavLinkArrow href='mailto:hello@compliment.me'>
        hello@compliment.me
        <IconArrow visibility='hidden'>
          <IoIosArrowForward />
        </IconArrow>
      </NavLinkArrow>
      {/* <NavLinkArrow>
        Discuter en ligne
        <IconArrow>
          <IoIosArrowForward />
        </IconArrow>
      </NavLinkArrow> */}
      <NavLinkArrow href='https://about.compliment.me/pages/faq'>
        Consulter notre faq
        <IconArrow>
          <IoIosArrowForward />
        </IconArrow>
      </NavLinkArrow>
    </NavWrapper>

    <CardLink href='https://calendly.com/compliment-me/consultation-compliment'>
      <CardTitle>
        Discuter gratuitement <br /> avec nos experts
      </CardTitle>
      <CardCta>
        Prendre rendez-vous
        <CardLinkIconArrow>
          <IoIosArrowForward />
        </CardLinkIconArrow>
      </CardCta>
    </CardLink>
  </Container>
)

export default PopupFaqNavMobileContent
