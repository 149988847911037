import { INITIAL_ACTION_STATE } from '../../utils/constants'

const state = {
  list: [],
  actions: {
    fetch: { ...INITIAL_ACTION_STATE },
    updatePaymentMethod: { ...INITIAL_ACTION_STATE },
  },
}

export default state
