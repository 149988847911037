import React, { useState } from 'react'

import { Dropdown, Drawer } from './style'

const DropdownEl = ({ children, content, fullPageDrawer, right }) => {
  const [isOpen, setIsOpen] = useState(false)

  const onKeyPress = ({ key }) => {
    if (key !== 'Enter') return
    setIsOpen(!isOpen)
  }

  return (
    <Dropdown
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
      tabIndex={0}
      onKeyPress={onKeyPress}
      fullPageDrawer={fullPageDrawer}
    >
      {children}
      <Drawer right={right} fullPageDrawer={fullPageDrawer} isOpen={isOpen}>
        {content}
      </Drawer>
    </Dropdown>
  )
}

export default DropdownEl
