/**
 * Color contain all colors for the app,
 * names are generated with "Name that color" tool
 * https://chir.ag/projects/name-that-color/
 */
export const Color = {
  BLACK_SQUEEZE: '#f7fafc',
  COLOR_SECONDARY: '#F1F5F4',
  DEEP_SEA: '#00865c',
  MINE_SHAFT: '#333333',
  MONTE_CARLO: '#7ED3BA',
  MYSTIC: '#e3e8ee',
  ORANGE: '#FD5B25',
  TRANSPARENT: 'rgba(255, 255, 255, 0)',
}

export const Font = {
  BASIS_GROTESQUE_PRO: 'Basis Grotesque Pro',
}

const Breakpoint = {
  XS: '320px',
  S: '498px',
  M: '768px',
  L: '1024px',
  XL: '1280px',
}

export const MediaQuery = {
  XS_AND_DOWN: `@media screen and (max-width: ${Breakpoint.XS})`,
  S_AND_DOWN: `@media screen and (max-width: ${Breakpoint.S})`,
  M_AND_DOWN: `@media screen and (max-width: ${Breakpoint.M})`,
  L_AND_DOWN: `@media screen and (max-width: ${Breakpoint.L})`,
  XL_AND_DOWN: `@media screen and (max-width: ${Breakpoint.XL})`,

  XS_AND_UP: `@media screen and (min-width: ${Breakpoint.XS})`,
  S_AND_UP: `@media screen and (min-width: ${Breakpoint.S})`,
  M_AND_UP: `@media screen and (min-width: ${Breakpoint.M})`,
  L_AND_UP: `@media screen and (min-width: ${Breakpoint.L})`,
  XL_AND_UP: `@media screen and (min-width: ${Breakpoint.XL})`,
}
