import Constants from './constants'
import { removeDataFromLocalStorage } from '../../utils/helpers'
import axios from 'axios'
import { toast } from 'react-toastify'

const loginLoading = () => ({ type: Constants.LOGIN_LOADING })
const loginSuccess = payload => ({ type: Constants.LOGIN_SUCCESS, payload })
const loginFailed = error => ({ type: Constants.LOGIN_FAILED, error })

/**
 * Login user
 * @return {void}
 */
export const login = data => dispatch => {
  dispatch(loginLoading())

  axios
    .post('https://www.compliment.me/api/login', data)
    .then(res => {
      dispatch(
        loginSuccess({
          user: { ...res.data.customer, session: { ...res.data.session } },
          ...res.data,
        })
      )
    })
    .catch(error => {
      toast.error("L'email ou le mot de passe est incorrect")
      dispatch(loginFailed())
    })

  // axios
  //   .post(
  //     'https://europe-west1-compliment-backoffice.cloudfunctions.net/loginComplimentMe',
  //     data
  //   )
  //   .then(res => {
  //     tokens = { ...res.data }
  //     return axios.post(
  //       'https://europe-west1-compliment-backoffice.cloudfunctions.net/rechargeQuery',
  //       {
  //         token: res.data.rechargeToken,
  //         schema: '{"customer":{}}',
  //         customerToken: res.data.temp_token,
  //       }
  //     )
  //   })
  //   .then(res => {
  //     dispatch(loginSuccess({ user: { ...res.data.customer }, ...tokens }))
  //   })
  //   .catch(error => {
  //     if (error.response.data.errorCode === 'UNIDENTIFIED_CUSTOMER')
  //       toast.error(
  //         "Vous n'avez pas encore de compte. Veuillez d'abord passer une commande"
  //       )
  //     else toast.error("L'email ou le mot de passe est incorrect")
  //     dispatch(loginFailed())
  //   })
}

const registerLoading = () => ({ type: Constants.REGISTER_LOADING })
const registerSuccess = payload => ({
  type: Constants.REGISTER_SUCCESS,
  payload,
})
const registerFailed = error => ({ type: Constants.REGISTER_FAILED, error })

/**
 * Register user
 * @return {void}
 */
export const register = data => dispatch => {
  // TODO: register account
  dispatch(registerLoading())
  axios
    .post(
      'https://europe-west1-compliment-backoffice.cloudfunctions.net/registerComplimentMe',
      data
    )
    .then(res => {
      dispatch(registerSuccess())
    })
    .catch(() => {
      dispatch(registerFailed())
    })
}

const logoutLoading = () => ({ type: Constants.LOGOUT_LOADING })
const logoutSuccess = payload => ({
  type: Constants.LOGOUT_SUCCESS,
  payload,
})
const logoutFailed = error => ({ type: Constants.LOGOUT_FAILED, error })

export const setAuthUser = payload => ({
  type: Constants.SET_AUTH_USER,
  payload,
})

export const setAuthTokens = payload => ({
  type: Constants.SET_AUTH_TOKENS,
  payload,
})

/**
 * Logout user
 * @return {void}
 */
export const logout = () => dispatch => {
  dispatch(logoutLoading())
  // TODO: make logout
  new Promise(resolve => {
    resolve()
  })
    .then(() => {
      removeDataFromLocalStorage()
      dispatch(logoutSuccess())
    })
    .catch(() => {
      dispatch(logoutFailed())
    })
}

export const fetchCustomerLoading = () => ({
  type: Constants.FETCH_CUSTOMER_LOADING,
})
export const fetchCustomerSuccess = payload => ({
  type: Constants.FETCH_CUSTOMER_SUCCESS,
  payload,
})
export const fetchCustomerFailed = error => ({
  type: Constants.FETCH_CUSTOMER_FAILED,
  error,
})

export const updateCustomerLoading = () => ({
  type: Constants.UPDATE_CUSTOMER_LOADING,
})
export const updateCustomerSuccess = payload => ({
  type: Constants.UPDATE_CUSTOMER_SUCCESS,
  payload,
})
export const updateCustomerFailed = error => ({
  type: Constants.UPDATE_CUSTOMER_FAILED,
  error,
})

export const customerRecoverLoading = () => ({
  type: Constants.CUSTOMER_RECOVER_LOADING,
})
export const customerRecoverSuccess = payload => ({
  type: Constants.CUSTOMER_RECOVER_SUCCESS,
  payload,
})
export const customerRecoverFailed = error => ({
  type: Constants.CUSTOMER_RECOVER_FAILED,
  error,
})
