import { useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { Form } from './style'
import { Button, Field } from '../../ui'
import {
  register,
  getRegisterStatus,
  getRegisterError,
} from '../../../store/auth-user'
import { ActionType } from '../../../utils/constants'
import { Color } from '../../../utils/constants'

const RegisterSchema = Yup.object({
  password: Yup.string()
    .min(8, 'Minimum 8 charactère')
    .required('Champs requis'),
  email: Yup.string().email('Email invalide').required('Champs requis'),
})

const RegisterForm = () => {
  const dispatch = useDispatch()
  const registerStatus = useSelector(getRegisterStatus)
  const registerError = useSelector(getRegisterError)
  const { handleSubmit, handleChange, handleBlur, values, errors, touched } =
    useFormik({
      initialValues: {
        email: '',
        password: '',
      },
      validationSchema: RegisterSchema,
      onSubmit: values => dispatch(register(values)),
    })

  useEffect(() => {
    if (
      registerStatus !== ActionType.LOADING &&
      registerStatus === ActionType.FAILED &&
      !!registerError
    ) {
      toast.error(registerError)
    }
  }, [registerStatus, registerError])

  return (
    <Form onSubmit={handleSubmit}>
      <Field
        label='Email'
        name='email'
        id='register-email'
        type='email'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.email}
        error={errors.email}
        touched={touched.email}
        isDisabled={registerStatus === ActionType.LOADING}
        isRequired
        style={{
          backgroundColor: '#EFEFEF',
        }}
      />
      <Field
        label='Mot de passe'
        id='register-password'
        name='password'
        type='password'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.password}
        error={errors.password}
        touched={touched.password}
        isDisabled={registerStatus === ActionType.LOADING}
        isRequired
        style={{
          backgroundColor: '#EFEFEF',
        }}
      />
      <Button
        style={{
          backgroundColor: Color.ORANGE,
          color: 'white',
          border: 'none',
        }}
        type='submit'
        isLoading={registerStatus === ActionType.LOADING}
      >
        Créer un compte
      </Button>
    </Form>
  )
}

export default RegisterForm
