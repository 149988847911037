import styled from 'styled-components/macro'
import { Color, MediaQuery } from '../../../utils/constants'

export const Close = styled.div`
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 30px;
`

export const Title = styled.h3`
  font-size: 25px;
  color: ${Color.DEEP_SEA};
  text-align: center;
  margin-bottom: 30px;
  font-weight: 300;
`

export const CalendarWrapper = styled.div`
  margin: 0 auto 30px auto;

  .react-calendar {
    border: none !important;
  }
`

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${MediaQuery.S_AND_DOWN} {
    flex-direction: column;
  }
  & > *:not(:last-child) {
    margin-bottom: 20px;
  }
`

export const PickTime = styled.div`
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  span {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 300;
    color: #777;
    cursor: pointer;
    margin: 0 10px;
    background-color: #eee;
    padding: 5px 20px;
  }
`
export const Text = styled.p`
  font-size: 11px;
  text-align: center;
  margin: 0 auto 20px auto;
  width: 80%;
`

export const SubTitle = styled.p`
  font-size: 14px;
  text-align: center;
  margin: 0 auto 15px auto;
`
