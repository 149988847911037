import React, { useEffect, useState } from 'react'
import {
  Header,
  HeaderTitle,
  EditIcon,
  HeaderRight,
  Grid,
  Wrapper,
  Hr,
} from '../style'
import { Button, Spinner } from '../../ui'
import { AiOutlineEdit } from 'react-icons/ai'
import { usePaymentMethods, useFetchPaymentMethods } from '../../../hooks'
import PaymentMethodCard from '../../payment/payment-method-card'

const PaymentMethod = () => {
  const { list } = usePaymentMethods()
  const { fetch, isLoading, isSuccess, isError } = useFetchPaymentMethods()
  const [isEditPaymentMethodModalOpen, setIsEditPaymentMethodModalOpen] =
    useState(false)

  useEffect(() => {
    !list.length && !isLoading && !isSuccess && !isError && fetch()
  }, [fetch, isError, isLoading, isSuccess, list.length])

  if (!list.length) {
    return <Spinner />
  }

  return (
    <>
      {list.map(i => {
        return (
          <Wrapper>
            <Header>
              <HeaderTitle>
                <h3>Mon moyen de paiement</h3>
              </HeaderTitle>
              <HeaderRight>
                <p>*informations obligatoires</p>
                <Button
                  style={{
                    padding: '0',
                    opacity: '.6',
                    textTransform: 'uppercase',
                  }}
                  appearance='underline'
                  size='13px'
                  onClick={() => setIsEditPaymentMethodModalOpen(i.id)}
                >
                  Modifier les informations
                </Button>
                <EditIcon onClick={() => setIsEditPaymentMethodModalOpen(i.id)}>
                  <AiOutlineEdit />
                </EditIcon>
              </HeaderRight>
            </Header>
            <Hr></Hr>
            <Grid>
              <div>
                <label>Mode de paiment*</label>
                <p>{i?.payment_details?.brand}</p>
              </div>
              <div>
                <label>Numéro de carte*</label>
                <p>{`*** ${i?.payment_details?.last4}`}</p>
              </div>
              {/* <div>
                    <label>Cryptogramme*</label>
                    <p></p>
                  </div> */}
              <div>
                <label>Titulaire de la carte*</label>
                <p>{i?.billing_address?.first_name}</p>
              </div>
              <div>
                <label>Date d'expiration*</label>
                <p>
                  {i?.payment_details?.exp_month}/{i?.payment_details?.exp_year}
                </p>
              </div>
            </Grid>

            {i.id === isEditPaymentMethodModalOpen && (
              <PaymentMethodCard
                paymentMethod={i}
                setIsModalOpen={setIsEditPaymentMethodModalOpen}
                isModalOpen={isEditPaymentMethodModalOpen}
              />
            )}
          </Wrapper>
        )
      })}
    </>
  )
}

export default PaymentMethod
