import { gql } from '@apollo/client'

// export const USER_DIAGNOSTIC = gql`
//   query getUserDiagnostic($email: String!) {
//     patient(where: { email: { _eq: $email } }) {
//     }
//   }
// `

export const USER_DIAGNOSTIC = gql`
  query getUserDiagnostic($email: String!) {
    diagnostic(where: { email: { _eq: $email } }) {
      diagnostic_url
      created_at
      diagnostic_take {
        diagnostic_items {
          shopify_product {
            shopify_product {
              handle
            }
          }
        }
      }
    }
  }
`

export const USER_LAST_DIAGNOSTIC = gql`
  query getUserLastDiagnostic($email: String!) {
    diagnostic(
      where: { email: { _eq: $email } }
      order_by: { created_at: desc }
      limit: 1
    ) {
      created_at
      diagnostic_take {
        diagnostic_items {
          shopify_product {
            shopify_product {
              handle
              title
              metafields(first: 200) {
                edges {
                  node {
                    value
                    key
                  }
                }
              }
              variants(first: 10) {
                edges {
                  node {
                    price
                    title
                  }
                }
              }
              images(first: 1) {
                edges {
                  node {
                    src
                  }
                }
              }
            }
          }
        }
      }
      diagnostic_url
    }
  }
`
