import styled from 'styled-components/macro'
import { Color, MediaQuery } from '../../../utils/constants'

export const ContentFlex = styled.div`
  display: flex;
  justify-content: space-between;
  ${MediaQuery.S_AND_DOWN} {
    flex-direction: column;
  }
`

export const Informations = styled.div`
  width: 47%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: -15px;
  ${MediaQuery.S_AND_DOWN} {
    width: 100%;
    margin-bottom: 15px;
  }

  div {
    border-bottom: 1px dotted #999;
    width: 48%;
    margin-bottom: 15px;
  }
  label {
    display: block;
    margin-bottom: 5px;
    font-size: 11px;
    opacity: 0.5;
  }
  p {
    font-size: 14px;
    margin-bottom: 10px;
  }
`
export const PersonalizedName = styled.div`
  width: 47%;
  background-color: #d9ede7;
  padding: 20px;
  ${MediaQuery.S_AND_DOWN} {
    width: 100%;
  }
  h4 {
    color: ${Color.DEEP_SEA};
    font-weight: 300;
    font-size: 18px;
    margin-bottom: 10px;
  }
  label {
    display: block;
    font-size: 11px;
    opacity: 0.5;
    margin-bottom: 6px;
  }
  p {
    font-size: 13px;
    font-weight: 300;
    padding-bottom: 6px;
    border-bottom: 1px dotted #999;
    width: 60%;
  }
`
