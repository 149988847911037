import { useFormik } from 'formik'
import * as Yup from 'yup'

import { Form } from './style'
import { Button, Field } from '../../ui'
import { Color } from '../../../utils/constants'
import { useCustomerRecover } from '../../../hooks'

const LoginSchema = Yup.object().shape({
  email: Yup.string().email('Email invalide').required('Champs obligatoire'),
})

const InitializeForm = () => {
  // const loginStatus = useSelector(getLoginStatus)
  const { recover, isLoading } = useCustomerRecover()
  const { handleSubmit, handleChange, handleBlur, values, errors, touched } =
    useFormik({
      initialValues: {
        email: '',
      },
      validationSchema: LoginSchema,
      onSubmit: values => recover({ variables: { email: values.email } }),
    })

  // useEffect(() => {
  //   loginStatus !== ActionType.LOADING &&
  //     loginStatus === ActionType.FAILED &&
  //     toast.error("L'email ou le mot de passe est inccorect")
  // }, [loginStatus])

  // const isLoading = loginStatus === ActionType.LOADING

  return (
    <Form onSubmit={handleSubmit}>
      <Field
        label='Email'
        name='email'
        id='login-email'
        type='email'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.email}
        error={errors.email}
        touched={touched.email}
        isDisabled={isLoading}
        style={{
          backgroundColor: '#EFEFEF',
        }}
      />
      <Button
        style={{
          backgroundColor: Color.ORANGE,
          color: 'white',
          border: 'none',
        }}
        type='submit'
        isLoading={isLoading}
      >
        Envoyer un mail de réinitialisation
      </Button>
    </Form>
  )
}

export default InitializeForm
