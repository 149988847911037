import styled from 'styled-components/macro'
import { Color, MediaQuery } from '../../utils/constants'
import { BaseText } from '../../style'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0px 0px;
  position: relative;
`

export const Aside = styled.aside`
  /* position: fixed;
  position: 0;
  left: 0; */
  display: block;
  width: 10%;

  ${MediaQuery.L_AND_DOWN} {
    z-index: 100;
    background-color: white;
    width: 100%;
    overflow-x: scroll;
    height: auto;
    position: fixed;
    position: 0;
    left: 0;
    bottom: 0;
  }
`

export const Content = styled.div`
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  ${MediaQuery.L_AND_DOWN} {
    width: 100%;
  }
`

export const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  /* border: 1px solid #ddd; */
  border-radius: 3px;
  padding: 0;
  ${MediaQuery.L_AND_DOWN} {
    flex-direction: row;
  }
`

export const NavLinkWrapper = styled.div`
  text-align: center;
  position: relative;
  padding: 6px;
  a {
    cursor: pointer;
    color: ${Color.DEEP_SEA};
  }
`

export const NavLinkContent = styled.div`
  padding: 30px 5px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${MediaQuery.L_AND_DOWN} {
    padding: 10px 5px;
  }
`

export const NavIcon = styled.p`
  white-space: nowrap;
  font-size: 25px;
  margin-bottom: 25px;
  margin-right: 10px;
  svg {
    display: block;
    width: 29px;
    height: auto;
  }
  ${MediaQuery.L_AND_DOWN} {
    margin-bottom: 15px;
    svg {
      display: block;
      width: 22px;
      height: auto;
    }
  }
`

export const NavText = styled.div`
  text-transform: uppercase;
  font-size: 10px;
  text-align: center;
  white-space: nowrap;
  ${MediaQuery.L_AND_DOWN} {
    font-size: 10px;
  }
`

export const AuthCard = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`
export const FullName = styled(BaseText)``
export const Email = styled(BaseText)``
