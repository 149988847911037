// import { useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
// import { toast } from 'react-toastify'

import { Form } from './style'
import { Button, Field } from '../../ui'
import { login, getLoginStatus } from '../../../store/auth-user'
import { ActionType } from '../../../utils/constants'
import { Color } from '../../../utils/constants'

const LoginSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Minimum 8 charactères requis')
    .required('Champs obligatoire'),
  email: Yup.string().email('Email invalide').required('Champs obligatoire'),
})

const LoginForm = () => {
  const dispatch = useDispatch()
  const loginStatus = useSelector(getLoginStatus)
  const { handleSubmit, handleChange, handleBlur, values, errors, touched } =
    useFormik({
      initialValues: {
        email: '',
        password: '',
      },
      validationSchema: LoginSchema,
      onSubmit: values => dispatch(login(values)),
    })

  // useEffect(() => {
  //   loginStatus !== ActionType.LOADING &&
  //     loginStatus === ActionType.FAILED &&
  //     toast.error("L'email ou le mot de passe est inccorect")
  // }, [loginStatus])

  const isLoading = loginStatus === ActionType.LOADING

  return (
    <Form onSubmit={handleSubmit}>
      <Field
        label='Email'
        name='email'
        id='login-email'
        type='email'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.email}
        error={errors.email}
        touched={touched.email}
        isDisabled={isLoading}
        style={{
          backgroundColor: '#EFEFEF',
        }}
      />
      <Field
        label='Password'
        id='password'
        name='password'
        type='password'
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.password}
        error={errors.password}
        touched={touched.password}
        isDisabled={isLoading}
        style={{
          backgroundColor: '#EFEFEF',
        }}
      />

      <Button
        style={{
          backgroundColor: Color.ORANGE,
          color: 'white',
          border: 'none',
        }}
        type='submit'
        isLoading={isLoading}
      >
        Se connecter
      </Button>
    </Form>
  )
}

export default LoginForm
