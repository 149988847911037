import { gql } from '@apollo/client'

export const USER_CALLS = gql`
  query getUserCall($email: String!) {
    call(
      where: { patient_email: { _eq: $email } }
      order_by: { created_at: desc }
    ) {
      created_at
      patient_email
      recommendation_url
      status
    }
  }
`

export const LAST_USER_CALL = gql`
  query getLastUserCall($email: String!) {
    call(
      where: { patient_email: { _eq: $email } }
      order_by: { created_at: desc }
      limit: 1
    ) {
      created_at
      patient_email
      recommendation_url
      status
      call_items {
        shopify_product {
          shopify_product {
            handle
            title
            metafields(first: 200) {
              edges {
                node {
                  value
                  key
                }
              }
            }
            variants(first: 10) {
              edges {
                node {
                  price
                  title
                }
              }
            }
            images(first: 1) {
              edges {
                node {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`
