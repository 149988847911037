import styled from 'styled-components/macro'
import { Color, MediaQuery } from '../../../utils/constants'

export const Wrapper = styled.div`
  margin: 0px 60px 40px 60px;
  ${MediaQuery.L_AND_DOWN} {
    margin: 0px 15px 10px 15px;
  }
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  ${MediaQuery.L_AND_DOWN} {
    flex-direction: column;
  }
`
export const OrdersHistory = styled.div`
  flex: 0 0 60%;
  background-color: white;
  padding: 30px 20px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  ${MediaQuery.L_AND_DOWN} {
    width: 100%;
    overflow-x: scroll;
  }
`
export const LastOrder = styled.div`
  flex: 0 0 37%;
  background-color: white;
  padding: 30px 20px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  ${MediaQuery.L_AND_DOWN} {
    width: 100%;
    margin-bottom: 30px;
  }
`
export const Title = styled.h3`
  font-size: 18px;
  font-weight: 300;
  opacity: 0.6;
`

export const Table = styled.table`
  width: 100%;

  & > *:not(:first-child) {
    border-bottom: 2px dotted #999;
  }

  th {
    text-align: start;
    span {
      padding: 10px 20px;
      text-transform: uppercase;
      white-space: nowrap;
      background-color: ${Color.COLOR_SECONDARY};
      font-size: 10px;
      color: ${Color.DEEP_SEA};
      border-radius: 5px;
    }
  }
  th {
    padding: 0px 0px 20px 0;
    text-align: center;
  }
  td {
    padding: 20px 0px 20px 0;
    text-align: center;
  }

  td {
    font-size: 13px;
    white-space: nowrap;
    ${MediaQuery.M_AND_DOWN} {
      margin-right: 30px;
    }
  }

  tr {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
`

export const Hr = styled.hr`
  margin: 15px 0;
  width: 100%;
  border: 0 dotted;
  height: 1px;
  background: #eee;
`

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  & > * {
    width: 45%;
    h5 {
      font-size: 12px;
      opacity: 0.5;
      margin-bottom: 8px;
    }
    p {
      font-size: 14px;
    }
  }
  ${MediaQuery.S_AND_DOWN} {
    flex-direction: column;
    & > * {
      width: 100%;
    }
  }
`

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;

  ${MediaQuery.S_AND_DOWN} {
    flex-direction: column;
    & > * {
      width: 100%;
    }
    & > *:not(:last-child) {
      margin-bottom: 10px;
    }
  }
`
